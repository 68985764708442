import { Suspense } from 'react';
import { Container } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import routes from '../AdminRoutes';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

interface RouteComponentProps {
  [key: string]: any;
}

const TheContent = () => {
  return (
    <Container fluid>
      <Suspense fallback={loading}>
        <Routes>
          {routes.map((route, idx) => {
            const RouteComponent =
              route.component as React.ComponentType<RouteComponentProps>;
            return (
              <Route
                key={idx}
                path={route.path}
                Component={(props: RouteComponentProps) => (
                  <RouteComponent {...props} />
                )}
              />
            );
          })}
        </Routes>
      </Suspense>
    </Container>
  );
};
export default TheContent;
