import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { ValidateField } from '../../helpers/ValidatorHelper';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import FowButton from '../../components/common/FowButton';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import _forEach from 'lodash/forEach';

import {
  charityCategoryDetailsField,
  charityCategoryDetailsValidations,
  charityCategoryStatusOptions,
} from '../../constants/CharityCategory';
import {
  ICharityCategoryDetails,
  ICharityCategoryPayload,
} from '../../datatypes/CharityCategory';
import {
  fetchCharityCategoryDetails,
  resetCharityCategoryData,
  saveUpdateCharityCategoryDetails,
} from '../../redux/slices/charityCategorySlice';
import TextInput from '../../components/common/TextInput';
import RadioGroupInput from '../../components/common/RadioGroupInput';
import EditorInput from '../../components/common/EditorInput';

const CharityCategoriesDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const location = useLocation();

  const editorConfig = {
    readonly: false,
    height: 300,
    toolbarButtonSize: 'small' as const, // Change this to a valid value like 'small' or 'middle'
  };

  const { charityCategoryDetails, saveUpdateCharityCategory } =
    useAppSelector<ICharityCategoryPayload>((state) => state.charityCategories);

  const [charityCategoryType, setCharityCategoryType] = useState<string>('new');
  const [initialEditorContent, setInitialEditorContent] = useState('');

  const [formData, setFormData] = useState<ICharityCategoryDetails>(
    charityCategoryDetailsField
  );

  const [errors, setErrors] = useState<ICharityCategoryDetails>({
    ...charityCategoryDetailsField,
    status: '',
  });

  const validations = useMemo(() => charityCategoryDetailsValidations, []);

  const handleInputs = (e: any) => {
    const name = e.target.name;
    let value: string | boolean;
    let error: string | undefined | null;

    //get value from checkbox / others
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    let fieldName = '';
    switch (name) {
      case 'meta_title':
        fieldName = 'Meta Title';
        break;
      case 'meta_description':
        fieldName = 'Meta Description';
        break;
      default:
        fieldName = name;
    }

    //find and set errors
    error = ValidateField(fieldName, value, validations[name]);

    if (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }

    //set form data
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const hasFormErrors = () => {
    let hasErrors = false;
    _forEach(formData, (value, key) => {
      let fieldName = '';
      switch (key) {
        case 'meta_title':
          fieldName = 'Meta Title';
          break;
        case 'meta_description':
          fieldName = 'Meta Description';
          break;
        default:
          fieldName = key;
      }

      const error = ValidateField(fieldName, value, validations[key]);
      if (error) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
        hasErrors = true;
      }
    });
    return hasErrors;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!hasFormErrors()) {
      let payload = {
        ...formData,
      };
      dispatch(saveUpdateCharityCategoryDetails(payload));
    }
  };

  const getCharityCategoriesData = () => {
    if (id && id !== 'new') {
      dispatch(fetchCharityCategoryDetails(id));

      const containsView = location.pathname.includes('view');
      if (containsView) {
        setCharityCategoryType('view');
      } else {
        setCharityCategoryType('edit');
      }
    } else {
      setFormData(charityCategoryDetailsField);
    }
  };

  // Component to render Edit button in page title if currently in view state.
  const EditButtonComponent = () => {
    if (charityCategoryType === 'view') {
      return (
        <Button
          variant="primary"
          type="button"
          onClick={() => navigate(`/charity-categories/${id}/edit`)}
        >
          Edit
        </Button>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    getCharityCategoriesData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (charityCategoryDetails.success && charityCategoryDetails.data) {
      const categoryData: ICharityCategoryDetails = {
        ...charityCategoryDetails.data,
      };

      setFormData(categoryData);
      setInitialEditorContent(categoryData.content);
    } else if (charityCategoryDetails.error) {
      navigate('/charity-categories');
    }
    // eslint-disable-next-line
  }, [charityCategoryDetails]);

  useEffect(() => {
    if (saveUpdateCharityCategory.success) {
      toastSuccessMessage(
        `Charity Category ${
          charityCategoryType === 'new' ? 'created' : 'updated'
        } successfully.`
      );
      navigate('/charity-categories');
    }
    // eslint-disable-next-line
  }, [saveUpdateCharityCategory]);

  return (
    <div className="charity-details">
      <PageTitle
        heading={
          charityCategoryType === 'new'
            ? 'New Charity Category'
            : ` Charity Category : ${charityCategoryDetails?.data?.name ?? ''}`
        }
        buttonName="Listing"
        buttonClick={() => navigate('/charity-categories')}
        customButton={<EditButtonComponent />}
      />
      {charityCategoryDetails?.loading ? (
        <Loader isFull />
      ) : !!charityCategoryDetails ? (
        <Card>
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg={12}>
                  <TextInput
                    controlId="name"
                    label="Name"
                    className="form-field"
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.name}
                    isRequired={true}
                    isDisabled={charityCategoryType === 'view'}
                  />
                </Col>
                <Col lg={12}>
                  <EditorInput
                    initialValue={initialEditorContent}
                    value={formData.content}
                    config={editorConfig}
                    controlId="description"
                    label="Description"
                    errorMessage={errors?.content}
                    onChange={(e) => {
                      if (e) {
                        handleInputs({
                          target: {
                            name: 'content',
                            value: e,
                            type: 'string',
                          },
                        });
                      }
                    }}
                    isRequired={true}
                    isDisabled={charityCategoryType === 'view'}
                  />
                </Col>
                <Col lg={12}>
                  <TextInput
                    controlId="meta_title"
                    label="Meta Title"
                    className="form-field"
                    placeholder="Meta Title"
                    name="meta_title"
                    value={formData.meta_title}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.meta_title}
                    isRequired={true}
                    isDisabled={charityCategoryType === 'view'}
                  />
                </Col>
                <Col lg={12}>
                  <TextInput
                    controlId="meta_description"
                    label="Meta Description"
                    className="form-field"
                    placeholder="Meta Description"
                    name="meta_description"
                    value={formData.meta_description}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.meta_description}
                    isRequired={true}
                    isDisabled={charityCategoryType === 'view'}
                  />
                </Col>
                <Col lg={4}>
                  <RadioGroupInput
                    name="status"
                    value={formData.status}
                    label="Status"
                    options={charityCategoryStatusOptions}
                    onChange={handleInputs}
                    isRequired={true}
                    errorMessage={String(errors?.status)}
                    controlId="status"
                    isDisabled={charityCategoryType === 'view'}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              {charityCategoryType !== 'view' && (
                <FowButton
                  variant="primary"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  {charityCategoryType === 'new' ? 'Save' : 'Update'}
                </FowButton>
              )}
              <FowButton
                variant="secondary"
                onClick={() => {
                  dispatch(resetCharityCategoryData());
                  navigate('/charity-categories');
                }}
              >
                Cancel
              </FowButton>
            </Card.Footer>
          </Form>
        </Card>
      ) : (
        <NotFound
          heading="Charity Category Not Found"
          subHeading="Please try again after sometime"
        />
      )}
    </div>
  );
};

export default CharityCategoriesDetails;
