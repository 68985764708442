import TextInput from '../common/TextInput';
import FowButton from '../common/FowButton';
import React, { useEffect } from 'react';
import { ICharityPayload, ICharitySearch } from '../../datatypes/Charity';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchStates } from '../../redux/slices/locationSlice';
import SelectInput from '../common/SelectInput';
import { Col, Row } from 'react-bootstrap';
import { optionsCharityStatus } from '../../constants/Charity';
import { ILocationPayload } from '../../datatypes/Location';

interface IProps {
  charitySearch: ICharitySearch;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCharitySearch: () => void;
  resetCharitySearch: () => void;
  isFilterApplied: boolean;
  errors: ICharitySearch;
}

const CharitySearch = ({
  charitySearch,
  handleInputChange,
  onCharitySearch,
  resetCharitySearch,
  isFilterApplied,
  errors,
}: IProps) => {
  const dispatch = useAppDispatch();
  const stateOptions = useAppSelector<ILocationPayload['states']['data']>(
    (state) => state.location.states.data
  );
  const stateLoading = useAppSelector<
    ILocationPayload['states']['stateLoading']
  >((state) => state.location.states.stateLoading);
  const { charityCategoryList } = useAppSelector<ICharityPayload>(
    (state) => state.charities
  );
  useEffect(() => {
    dispatch(fetchStates());
    // eslint-disable-next-line
  }, []);
  return (
    <div className="charity-form">
      <Row>
        <Col lg={6} md={6} sm={12}>
          <TextInput
            name="name"
            placeholder="Charity Name"
            value={String(charitySearch.name)}
            onChange={(e) => handleInputChange(e)}
            label="Charity Name"
            errorMessage={String(errors.name)}
            isRequired={false}
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <SelectInput
            name="state_id"
            placeholder="Select State"
            value={charitySearch.state_id}
            optionLabel="state_name"
            optionValue="id"
            options={stateOptions}
            isLoading={stateLoading}
            handleSelectChange={(e) => handleInputChange(e)}
            label="Charity State"
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <SelectInput
            name="status"
            placeholder="Select Status"
            value={charitySearch.status}
            optionLabel="label"
            optionValue="value"
            options={optionsCharityStatus}
            handleSelectChange={(e) => handleInputChange(e)}
            label="Charity Status"
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <SelectInput
            name="category_id"
            placeholder="Select Status"
            value={charitySearch.category_id}
            optionLabel="name"
            optionValue="id"
            options={charityCategoryList?.data?.data ?? []}
            isLoading={charityCategoryList?.loading}
            handleSelectChange={(e) => handleInputChange(e)}
            label="Charity Category"
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col lg={12}>
          <div className="d-flex gap-3 justify-content-between w-100">
            <FowButton variant="success" onClick={onCharitySearch}>
              <i className="fa fa-search mr-2"></i> Search
            </FowButton>
            {isFilterApplied && (
              <FowButton
                variant="outline-secondary"
                onClick={resetCharitySearch}
              >
                <i className="fa fa-undo mr-2"></i> Reset
              </FowButton>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CharitySearch;
