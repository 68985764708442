import { Form, Col } from 'react-bootstrap';
import React from 'react';
import Cleave from 'cleave.js/react';
import ErrorMessage from './ErrorMessage';

interface IProps {
  name?: string;
  value: string;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
  placeholder?: string;
  options: any;
  className?: string;
  colSize?: number;
}

const CleaveInput = ({
  isRequired = false,
  label = '',
  value = '',
  name = '',
  errorMessage = '',
  options = {},
  onChange,
  className = '',
  placeholder = '',
  isDisabled = false,
  colSize = 12,
}: IProps) => {
  return (
    <Form.Group as={Col} md={colSize} className="mb-3 p-0">
      {label && (
        <div className="instruction-title">
          <Form.Label>
            {label}
            {isRequired && <span className="required">*</span>}
          </Form.Label>
        </div>
      )}
      <Cleave
        className={`form-control ${className}`}
        placeholder={placeholder}
        name={name}
        options={options}
        value={value}
        onChange={(e: any) => {
          onChange(e);
        }}
        disabled={isDisabled}
      />
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </Form.Group>
  );
};

export default CleaveInput;
