import React from 'react';
import InputMask from 'react-input-mask';
import { Form } from 'react-bootstrap';
import ErrorMessage from './ErrorMessage';

interface IProps {
  name: string;
  value: string;
  label?: string;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  errorMessage?: string;
  mask?: any;
}

const MaskInput = ({
  isRequired = true,
  label = '',
  value = '',
  errorMessage = '',
  name,
  onChange,
  mask,
  placeholder,
}: IProps) => {
  return (
    <Form.Group className="mb-3">
      {label && (
        <div className="instruction-title">
          <Form.Label>
            {label}
            {isRequired && <span className="required">*</span>}
          </Form.Label>
        </div>
      )}
      <InputMask
        mask={mask}
        className={`${
          errorMessage ? 'error-focus-input' : 'form-field'
        } form-control`}
        placeholder={placeholder || '___ ___ ____'}
        name={name}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
        }}
      />
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </Form.Group>
  );
};

export default MaskInput;
