import { WillLocationTypes, WillTypes } from '../constants/WillRegistration';
import { IWillRegistrationLocation } from '../datatypes/WillRegistration';

const formatLocationData = (
  data: any,
  presentData: IWillRegistrationLocation
) => {
  const locationData: IWillRegistrationLocation = { ...presentData };

  //set the will location and other common details

  locationData.will_location = data?.will_location;

  locationData.address1 = data?.address1 ?? '';
  locationData.address2 = data?.address2 ?? '';
  locationData.city = data?.city ?? '';
  locationData.state = data?.state_abbr;
  locationData.county = data?.county_name;
  locationData.zipcode = data?.zipcode ?? '';

  // mark optional data based on will location type
  switch (locationData.will_location) {
    case WillLocationTypes.HOME:
      locationData.home_location = data?.home_location ?? '';
      locationData.home_email = data?.home_email ?? '';
      locationData.home_phone = data?.home_phone ?? '';
      break;
    case WillLocationTypes.ATTORNEY:
      locationData.attorney_phone = data?.attorney_phone ?? '';
      locationData.attorney_email = data?.attorney_email ?? '';
      locationData.attorney_first_name = data?.attorney_first_name ?? '';
      locationData.attorney_last_name = data?.attorney_last_name ?? '';
      locationData.attorney_firm_name = data?.attorney_firm_name ?? '';
      break;
    case WillLocationTypes.FINANCIAL_INSTITUTION:
      locationData.business_phone = data?.business_phone ?? '';
      locationData.business_email = data?.business_email ?? '';
      locationData.business_name = data?.business_name ?? '';
      locationData.business_contact_name = data?.business_contact_name ?? '';
      break;
    case WillLocationTypes.ICLOUD:
      locationData.icloud_email = data?.icloud_email ?? '';
      locationData.icloud_website = data?.icloud_website ?? '';
      break;
    case WillLocationTypes.PRIVATE_PARTY:
      locationData.private_phone = data?.private_phone ?? '';
      locationData.private_email = data?.private_email ?? '';
      locationData.private_contact_first_name =
        data?.private_contact_first_name ?? '';
      locationData.private_contact_last_name =
        data?.private_contact_last_name ?? '';
      locationData.private_contact_relationship =
        data?.private_contact_relationship ?? '';
      break;
    default:
      break;
  }

  return locationData;
};

export const reformatWillRegistrationData = (
  data: any
): IWillRegistrationLocation => {
  let formattedData: IWillRegistrationLocation = {
    id: data?.id,
    will_type: data?.will_type,
  };

  //update data further based on will location type
  switch (data?.will_type) {
    case WillTypes.ORIGINAL:
      {
        const locationData = formatLocationData(data, formattedData);
        formattedData = {
          ...formattedData,
          ...locationData,
        };
      }
      break;
    case WillTypes.DUPLICATE:
      {
        const locationData = formatLocationData(data, formattedData);
        formattedData = {
          ...formattedData,
          ...locationData,
        };
      }
      break;
    case WillTypes.TRUST:
      formattedData.is_trust_stored_with_will = data?.is_trust_stored_with_will;
      if (formattedData.is_trust_stored_with_will === 1) {
        formattedData.trust_stored_with = data?.trust_stored_with;
      } else {
        formattedData.trust_stored_with = '';
        const locationData = formatLocationData(data, formattedData);
        formattedData = {
          ...formattedData,
          ...locationData,
        };
      }
      break;
  }

  return formattedData;
};
