import { IMetaDetails, IMetaValidations } from '../datatypes/Meta';

// initial / template for meta details - form and error
export const templateMetaDetails: IMetaDetails = {
  page_code: '',
  page_title: '',
  meta_title: '',
  meta_description: '',
  meta_keywords: '',
  page_image: '',
};

// validations for meta all form details
export const metaDetailsValidations: IMetaValidations = {
  page_code: 'required|min:2|max:200',
  page_title: 'required|min:2|max:200',
  meta_title: 'required|min:2|max:200',
  meta_description: 'max:200',
  page_image: '',
};
