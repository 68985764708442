import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useMissingWillSearchApi from '../../api/missingWillSearch.api';
import { IMissingWillSearchPayload } from '../../datatypes/MisssingWillSearch';
import { deleteEmptyKeys, formatDate } from '../../helpers/common';

const initialState: IMissingWillSearchPayload = {
  willList: {
    loading: false,
    data: {
      data: [],
      current_page: 0,
      first_page_url: '',
      from: 0,
      last_page: 0,
      path: '',
      per_page: 0,
      prev_page_url: '',
      to: 0,
      total: 0,
    },
    error: false,
  },
  willDetails: { loading: false, data: null, error: false },
  deleteWill: { loading: false, success: false, error: false },
};

export const missingWillSearchSlice = createSlice({
  name: 'missingWill',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetMissingWillSearchData: (state: IMissingWillSearchPayload) => {
      state.willDetails = initialState.willDetails;
      state.deleteWill = initialState.deleteWill;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchMissingWillSearches.pending, (state) => {
        state.willList.loading = true;
      })
      .addCase(fetchMissingWillSearches.fulfilled, (state, { payload }) => {
        state.willList.data = payload?.missingWillSearches;
        state.willList.loading = false;
      })
      .addCase(fetchMissingWillSearches.rejected, (state) => {
        state.willList.loading = false;
        state.willList.error = true;
      })
      .addCase(fetchMissingWillSearchDetails.pending, (state) => {
        state.willDetails.loading = true;
      })
      .addCase(
        fetchMissingWillSearchDetails.fulfilled,
        (state, { payload }) => {
          state.willDetails.data = payload?.missingWillSearch?.[0];
          state.willDetails.loading = false;
        }
      )
      .addCase(fetchMissingWillSearchDetails.rejected, (state) => {
        state.willDetails.loading = false;
        state.willDetails.error = true;
      })
      .addCase(deleteMissingWillSearchData.pending, (state) => {
        state.deleteWill.success = false;
        state.deleteWill.loading = true;
        state.deleteWill.error = false;
      })
      .addCase(deleteMissingWillSearchData.fulfilled, (state) => {
        state.deleteWill.success = true;
        state.deleteWill.loading = false;
        state.deleteWill.error = false;
      })
      .addCase(deleteMissingWillSearchData.rejected, (state) => {
        state.deleteWill.success = false;
        state.deleteWill.loading = false;
        state.deleteWill.error = true;
      });
  },
});

// Async API Hooks
/**
 * Fetch Will List from API and save in redux store
 */
export const fetchMissingWillSearches = createAsyncThunk(
  'missingWill/fetchWillList',
  async (data: any, { rejectWithValue }) => {
    let filters = JSON.parse(JSON.stringify(data.params));
    filters = deleteEmptyKeys(filters);

    // convert date string to api payload format.
    if (filters['created_from']) {
      filters['created_from'] = formatDate(
        filters['created_from'],
        'MM/DD/YYYY'
      );
    }
    if (filters['created_to']) {
      filters['created_to'] = formatDate(filters['created_to'], 'MM/DD/YYYY');
    }
    if (filters['d_birth_date']) {
      filters['d_birth_date'] = formatDate(
        filters['d_birth_date'],
        'MM/DD/YYYY'
      );
    }

    const response = await useMissingWillSearchApi().getMissingWillSearches(
      filters
    );

    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response?.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);
/**
 * Fetch Will Details for the from API and save in redux store
 */
export const fetchMissingWillSearchDetails = createAsyncThunk(
  'missingWill/fetchWillDetails',
  async (id: string, { rejectWithValue }) => {
    const response =
      await useMissingWillSearchApi().getMissingWillSearchDetails(id);

    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response?.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);
/**
 * Delete single Will API
 */
export const deleteMissingWillSearchData = createAsyncThunk(
  'missingWill/deleteWillData',
  async (id: string, { rejectWithValue }) => {
    const response = await useMissingWillSearchApi().deleteMissingWillSearch(
      id
    );

    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response?.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);
export const { resetMissingWillSearchData } = missingWillSearchSlice.actions;

export default missingWillSearchSlice.reducer;
