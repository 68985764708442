import { isMinor } from '../../helpers/common';
import { createSelector } from '@reduxjs/toolkit';
import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';

const answers = (state: any) => state.answers?.data;

/**
 * Get Minor or Disabled Children
 * @param state
 */
export const getMinorOrDisabledChildren = createSelector(
  [answers],
  (answers) => {
    if (!answers) return [];
    const children =
      answers[pageCodes.childrenInfoPage] &&
      answers[pageCodes.childrenInfoPage][questionCodes.haveChildren] &&
      answers[pageCodes.childrenInfoPage][questionCodes.haveChildren][
        questionInputKeys.HAS_CHILDREN
      ] === 'yes' &&
      answers[pageCodes.childrenInfoPage][questionCodes.childrenNames]
        ? answers[pageCodes.childrenInfoPage][questionCodes.childrenNames]
        : [];

    if (children.length > 0) {
      return children.filter(
        (child: any) => child.isDisabled || isMinor(child.birthdate)
      );
    } else return [];
  }
);
