import { startCase } from 'lodash';
import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';

const LastWillProvisionAnswers = ({ answers }: any) => {
  const lastWillProvision = answers[pageCodes.lastWillProvision];

  return (
    <div>
      <div>
        <table className="default-table table table-bordered">
          <tbody>
            <tr>
              <th colSpan={4} className="table-title">
                <div>
                  <h4>Last Will Provision</h4>
                </div>
              </th>
            </tr>
            <tr>
              <th className="text-left w-20">Contest Provision</th>
              <td>
                {startCase(
                  lastWillProvision[questionCodes.contentProvision][
                    questionInputKeys.CONTEST_PROVISION
                  ]
                )}
              </td>
              <th className="text-left w-20">Survivorship Requirement</th>
              <td>
                {startCase(
                  lastWillProvision[questionCodes.survivalRequirement][
                    questionInputKeys.SURVIVAL_REQUIREMENT
                  ]
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LastWillProvisionAnswers;
