import useAuthService from '../../services/auth.service';
import LoginData from '../../datatypes/Login';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

const AdminLogin: React.FC = () => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<LoginData>({
    email: '',
    password: '',
  });
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  const { authenticate } = useAuthService();
  const cookies = new Cookies();

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      navigate('/');
    }
  });

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsProcessing(true);
    await authenticate(loginData)
      .then((response: any) => {
        if (response.success) {
          const lastVisitedPage = cookies.get('last_visited_page');
          if (lastVisitedPage) {
            navigate(lastVisitedPage);
          } else {
            navigate('/');
          }
        } else {
          setError(response.message);
        }
      })
      .finally(() => setIsProcessing(false));
  };

  const handleInput = (e: any) => {
    if (e.target.name === 'email') {
      setLoginData({
        ...loginData,
        email: e.target.value,
      });
    } else if (e.target.name === 'password') {
      setLoginData({
        ...loginData,
        password: e.target.value,
      });
    }
  };

  return (
    <div className="flex flex-row align-items-center">
      <div className="container">
        <div className="row">
          <div className="offset-xl-3 col-xl-6 offset-lg-1 col-lg-10 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <h1>Administrator Login</h1>
                  <p className="text-muted">Sign In to your account</p>
                  {error ? (
                    <div className="alert alert-danger fade show" role="alert">
                      {error}
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fa fa-user"></i>
                      </div>
                    </div>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      className="form-control"
                      required
                      onChange={handleInput}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <div className="input-group-text">
                      <i className="fa fa-lock"></i>
                    </div>
                    <input
                      id="password"
                      type="password"
                      name="password"
                      placeholder="Password"
                      className="form-control"
                      required
                      onChange={handleInput}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <button
                        disabled={isProcessing}
                        type="submit"
                        name="singlebutton"
                        className="btn btn-primary mb10 px-4"
                      >
                        {isProcessing ? 'Logging You In...' : 'Log In'}
                      </button>
                    </div>
                    <div className="text-right col-6">
                      <button className="px-0 btn btn-link" type="button">
                        Forgot password?
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
