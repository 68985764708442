import React, { useEffect } from 'react';
import useAuthService from '../../services/auth.service';

const AdminLogout: React.FC = () => {
  const { logout } = useAuthService();
  useEffect(() => {
    handleLogout();
  });

  const handleLogout = async () => {
    await logout().then(() => {
      localStorage.clear();
      window.location.href = '/login';
    });
  };
  return null;
};

export default AdminLogout;
