import {
  ITestimonialDetails,
  ITestimonialValidations,
} from '../datatypes/Testimonial';

// initial state for form and error
export const testimonialDetailsField: ITestimonialDetails = {
  name: '',
  testimonial_text: '',
  profile_photo: '',
  date: '',
  category: '',
  location: '',
};

// validations for form
export const testimonialDetailsValidations: ITestimonialValidations = {
  name: 'required|min:2|max:50',
  testimonial_text: 'required|min:2|max:255',
  profile_photo: 'required',
  date: '',
  location: 'required',
  category: 'required',
};

// options for testimonial type
export const testimonialCategoryOptions = [
  {
    label: 'Register Will',
    value: 'Register Will',
  },
  {
    label: 'Create Will',
    value: 'Create Will',
  },
  {
    label: 'Missing Will Search',
    value: 'Missing Will Search',
  },
];
