import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../components/common/ToastMessage';
import PageTitle from '../../components/common/PageTitle';
import {
  IShowDeleteTestimonialModal,
  ITestimonialPayload,
} from '../../datatypes/Testimonial';
import {
  resetTestimonialData,
  fetchTestimonials,
} from '../../redux/slices/testimonialSlice';
import Listing from '../../components/testimonial/Listing';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

const TestimonialListing = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { testimonialList, deleteTestimonialStatus } =
    useAppSelector<ITestimonialPayload>((state) => state.testimonial);

  const [showDeleteModal, setShowDeleteModal] =
    useState<IShowDeleteTestimonialModal>({
      show: false,
      idDetails: [],
    });

  const getTestimonialListData = () => {
    dispatch(fetchTestimonials());
  };

  useEffect(() => {
    dispatch(fetchTestimonials());
    // eslint-disable-next-line
  }, []);

  //handle successful delete api call and modal closing
  useEffect(() => {
    if (deleteTestimonialStatus.success || deleteTestimonialStatus.error) {
      if (deleteTestimonialStatus.success) {
        toastSuccessMessage('Testimonial deleted successfully!');
      } else if (deleteTestimonialStatus.error) {
        toastErrorMessage(
          'Something went wrong deleting testimonial , please try again later.'
        );
      }

      setShowDeleteModal({
        show: false,
        idDetails: [],
      });
      dispatch(resetTestimonialData());
      getTestimonialListData();
    }
    // eslint-disable-next-line
  }, [deleteTestimonialStatus]);

  return (
    <div className="testimonial-listing">
      <PageTitle
        heading="Testimonials"
        buttonName="Create Testimonial"
        buttonClick={async () => {
          dispatch(resetTestimonialData());
          navigate('/testimonials/new');
        }}
      />
      <div className="testimonials-listing-content">
        <Listing
          testimonialList={testimonialList}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deleteTestimonialDetail={deleteTestimonialStatus}
        />
      </div>
    </div>
  );
};

export default TestimonialListing;
