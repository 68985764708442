import Modal from 'react-bootstrap/Modal';
import FowButton from '../common/FowButton';
import {
  IGeneratePinCode,
  ISearchPinCodeRecord,
} from '../../datatypes/Invoice';
import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';
import {
  generatePinCodeFieldValidations,
  initialGenerateSearchPinCodeField,
} from '../../constants/Invoice';
import useInvoiceApi from '../../api/invoice.api';
import { ValidateField } from '../../helpers/ValidatorHelper';
import CheckBoxInput from '../common/CheckBoxInput';
import NumberInput from '../common/NumberInput';

interface IProps {
  show: boolean;
  data: ISearchPinCodeRecord;
  handleCancelClose: () => void;
  handleSaveClose: () => void;
}

const DEFAULT_EMPTY_ERRORS = {
  update_search_count: '',
  transaction_count: '',
};

const SearchPinCodeDetails = ({
  show,
  data,
  handleCancelClose,
  handleSaveClose,
}: IProps) => {
  const [generateSearchPinCodeData, setGenerateSearchPinCodeData] =
    useState<IGeneratePinCode>(initialGenerateSearchPinCodeField);

  const [generateSeachPinCodeErrors, setGenerateSeachPinCodeErrors] =
    useState(DEFAULT_EMPTY_ERRORS);

  const [isGeneratingPinCodeLoading, setIsGeneratingPinCodeLoading] =
    useState<boolean>(false);

  const [generatePinCodeMessage, setGeneratePinCodeMessage] =
    useState<string>('');

  const { generateSearchPinCode } = useInvoiceApi();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    let value: string | boolean | number;
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    setGenerateSearchPinCodeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const error = ValidateField(
      name,
      value,
      // @ts-ignore
      generatePinCodeFieldValidations?.[name]
    );

    setGenerateSeachPinCodeErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error || '',
    }));
  };

  const validateGenerateSearchPinCode = () => {
    if (generateSearchPinCodeData.update_search_count) {
      return generateSeachPinCodeErrors.transaction_count !== '';
    } else {
      return false;
    }
  };

  const onGenerateSearchPinCode = async () => {
    const hasError = validateGenerateSearchPinCode();
    if (!hasError) {
      setIsGeneratingPinCodeLoading(true);
      const payload: {
        [key: string]: string | number;
      } = {
        id: data.mws_id,
      };

      if (generateSearchPinCodeData.update_search_count) {
        payload['transaction_count'] =
          generateSearchPinCodeData.transaction_count;
      }

      setIsGeneratingPinCodeLoading(true);

      await generateSearchPinCode(payload)
        .then((res) => {
          setGeneratePinCodeMessage(res?.message);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsGeneratingPinCodeLoading(false));
    }
  };

  const handleCancelButtonClick = () => {
    setGenerateSeachPinCodeErrors(DEFAULT_EMPTY_ERRORS);
    setGenerateSearchPinCodeData(initialGenerateSearchPinCodeField);
    handleCancelClose();
  };

  const handleUpdateSaveClose = () => {
    setGenerateSeachPinCodeErrors(DEFAULT_EMPTY_ERRORS);
    setGenerateSearchPinCodeData(initialGenerateSearchPinCodeField);
    handleSaveClose();
  };

  return (
    <>
      <Modal show={show} onHide={handleCancelButtonClick} centered>
        <Modal.Header closeButton>
          <Modal.Title>Pincode Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generatePinCodeMessage === '' ? (
            <>
              <Row>
                <Col lg={4} md={4} sm={12}>
                  <b>Invoice Number : </b>
                </Col>
                <Col lg={8} md={8} sm={12}>
                  {data?.invoice_number}
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={4} sm={12}>
                  <b>Customer Details :</b>
                </Col>
                <Col lg={8} md={8} sm={12}>
                  <span>
                    {data.customer_name} ({' '}
                    <a href={`mailto:${data.customer_email}`}>
                      {data.customer_email}
                    </a>{' '}
                    )
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={4} sm={12}>
                  <b>Pincode :</b>
                </Col>
                <Col lg={8} md={8} sm={12}>
                  {data?.search_pin}
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={4} sm={12}>
                  <b>Transaction Count :</b>
                </Col>
                <Col lg={8} md={8} sm={12}>
                  {data?.transaction_count}
                </Col>
              </Row>
              <hr />
              <div className="row" style={{ alignItems: 'center' }}>
                <div className="col-lg-12">
                  <CheckBoxInput
                    name="update_search_count"
                    value={
                      generateSearchPinCodeData.update_search_count
                        ? true
                        : false
                    }
                    label="Do you want to reset Total Search count?"
                    onChange={(e) => handleInputChange(e)}
                    errorMessage={
                      typeof generateSeachPinCodeErrors?.update_search_count ===
                      'string'
                        ? generateSeachPinCodeErrors?.update_search_count
                        : ''
                    }
                  />
                </div>
                {generateSearchPinCodeData.update_search_count && (
                  <div className="col-lg-12">
                    <NumberInput
                      controlId="transaction_count"
                      label="Transaction Count"
                      className="form-field"
                      placeholder="Transaction Count"
                      name="transaction_count"
                      value={Number(
                        generateSearchPinCodeData.transaction_count
                      )}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      errorMessage={String(
                        generateSeachPinCodeErrors?.transaction_count
                      )}
                      min={0}
                      max={3}
                      isRequired={false}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <p>{generatePinCodeMessage}</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <FowButton
            variant="secondary"
            onClick={() =>
              generatePinCodeMessage === ''
                ? handleCancelButtonClick()
                : handleUpdateSaveClose()
            }
            isDisabled={isGeneratingPinCodeLoading}
          >
            Close
          </FowButton>
          {generatePinCodeMessage === '' && (
            <FowButton
              variant="primary"
              onClick={onGenerateSearchPinCode}
              isLoading={isGeneratingPinCodeLoading}
              isDisabled={isGeneratingPinCodeLoading}
            >
              Generate
            </FowButton>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SearchPinCodeDetails;
