import { dtCustomStyle } from '../../helpers/DataTableHelper';
import { useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import {
  IShowDeletePricingModal,
  IPricingColumn,
  IPricingRow,
  IPricingPagination,
  IPricingPayload,
} from '../../datatypes/Pricing';
import { useAppDispatch } from '../../redux/hooks';
import DeleteModal from './DeleteModal';
import { deletePricingDetails } from '../../redux/slices/pricingSlice';
import { PageOptions } from '../../constants/Common';
import { Link } from 'react-router-dom';
import { ISortParams } from 'src/datatypes/CommonTypes';

interface IProps {
  pricingList: IPricingPayload['pricingList'];
  deletePricingDetail: IPricingPayload['deletePricing'];
  showDeleteModal: IShowDeletePricingModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeletePricingModal>
  >;
  setPagination: React.Dispatch<React.SetStateAction<IPricingPagination>>;
  setSort: React.Dispatch<React.SetStateAction<ISortParams>>;
}

const Listing = ({
  pricingList,
  deletePricingDetail,
  showDeleteModal,
  setShowDeleteModal,
  setPagination,
  setSort,
}: IProps) => {
  const dispatch = useAppDispatch();

  // switch function for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeletePricingModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  const selectedPricingId = (rows: IPricingRow[]) => {
    setShowDeleteModal((prev: IShowDeletePricingModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  // function to handle delete
  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((pricingDetail: IPricingRow) => pricingDetail.id)
      .join(',');
    dispatch(deletePricingDetails(selectedIds));
  };

  const openDeleteModal = (row: IPricingRow) => {
    selectedPricingId([row]);
    toggleDeleteModal();
  };

  useEffect(() => {
    if (deletePricingDetail?.success) {
      setShowDeleteModal((prev: IShowDeletePricingModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deletePricingDetail]);

  const columns: IPricingColumn[] = [
    {
      name: 'Name',
      selector: (row: IPricingRow) => row.name,
      sortField: 'name',
      sortable: true,
    },
    {
      name: 'Code',
      selector: (row: IPricingRow) => row.code,
      sortField: 'code',
      sortable: true,
    },
    {
      name: 'Price',
      selector: (row: IPricingRow) => row.price,
      sortField: 'price',
      sortable: true,
      cell: (row: IPricingRow) => `$ ${row.price ?? 0.0}`,
    },
    {
      name: 'Status',
      selector: (row: IPricingRow) => row.status,
      sortField: 'status',
      sortable: true,
      style: {
        paddingRight: '20px',
      },
      compact: true,
      center: true,
      cell: (row: IPricingRow) => {
        if (row.status === 'active') {
          return (
            <div className="pricing-status">
              <i className="fa fa-check"></i>
            </div>
          );
        } else {
          return (
            <div className="pricing-status">
              <i className="fa fa-times"></i>
            </div>
          );
        }
      },
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: IPricingRow) => row.actions,
      center: true,
      compact: true,
      cell: (row: IPricingRow) => (
        <div>
          <Link
            title="Edit"
            className="btn btn-primary btn-sm"
            to={`/pricing/${row.id}/edit`}
            key="edit"
          >
            <i className="fa fa-pencil"></i>
          </Link>
          <Link
            title="View"
            className="btn btn-secondary btn-sm ml-2"
            to={`/pricing/${row.id}/view`}
            key="view"
          >
            <i className="fa fa-eye"></i>
          </Link>
          <Button
            title="Delete"
            className="btn btn-danger btn-sm ml-2"
            key="delete"
            onClick={() => openDeleteModal(row)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Row className="mt-3">
      <Col lg={12}>
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={pricingList.loading}
              keyField="id"
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              paginationServer
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={pricingList.data?.total}
              onChangeRowsPerPage={(new_per_page: number) => {
                setPagination((prev: IPricingPagination) => ({
                  ...prev,
                  per_page: new_per_page,
                }));
              }}
              data={pricingList.data?.data}
              customStyles={dtCustomStyle}
              onChangePage={(page: number) => {
                setPagination((prev: IPricingPagination) => ({
                  ...prev,
                  page: page,
                }));
              }}
              onSort={(column, direction) => {
                setSort({ orderBy: column.sortField, order: direction });
              }}
              sortServer
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deletePricingDetail={deletePricingDetail}
        />
      </Col>
    </Row>
  );
};

export default Listing;
