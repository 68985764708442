import { OptionType } from '../datatypes/CommonTypes';
import {
  IPageSection,
  IPageSectionValidations,
  IPageDetails,
  IPageValidations,
} from '../datatypes/Page';

// initial state for form and error - page section
export const templatePageSectionDetails: IPageSection = {
  section_title: '',
  section_body: '',
  section_title_position: 'top',
  section_order: 0,
};

// initial state for form page details
export const templatePageDetails: IPageDetails = {
  page_code: '',
  title: '',
  content: [templatePageSectionDetails],
  status: 'active',
  meta_title: '',
  meta_description: '',
};

// initial state for error - page details
export const templateErrorPageSectionDetails: IPageSection = {
  section_title: '',
  section_body: '',
  section_title_position: '',
  section_order: '',
};

// initial state for form page details
export const templateErrorPageDetails: IPageDetails = {
  page_code: '',
  title: '',
  content: [templateErrorPageSectionDetails],
  status: '',
  meta_title: '',
  meta_description: '',
};

// validations for page section
export const pageSectionDetailsValidations: IPageSectionValidations = {
  section_title: 'max:200',
  section_body: 'required|min:20',
  section_title_position: 'required',
  section_order: 'required|integer|minNumber:0|maxNumber:1000',
};

// validations for page details
export const pageDetailsValidations: IPageValidations = {
  page_code: 'required|min:2|max:200',
  title: 'required|min:2|max:200',
  content: [pageSectionDetailsValidations],
  status: 'required',
  meta_title: 'required|min:2|max:200',
  meta_description: 'required|min:2|max:200',
};

export const pageStatusOptions: OptionType<string>[] = [
  {
    label: 'Active',
    value: 'active',
    disabled: false,
    color: 'btn-success',
  },
  {
    label: 'Inactive',
    value: 'inactive',
    disabled: false,
    color: 'btn-secondary',
  },
  {
    label: 'Archive',
    value: 'archive',
    disabled: false,
    color: 'btn-info',
  },
];

export const sectionTitlePositionOptions: OptionType<string>[] = [
  {
    label: 'Top',
    value: 'top',
  },
  {
    label: 'Left',
    value: 'left',
  },
  {
    label: 'Right',
    value: 'right',
  },
];
