import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';
import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { optionKeys } from 'src/constants/OptionKeys';

const useAnswerService = () => {
  /**
   * Get Spouse or Partner name based on marital status from answers
   * @param allAnswers
   * @returns
   */
  const getSpousePartner = (allAnswers: any) => {
    if (allAnswers && allAnswers[pageCodes.spouseInfoPage]) {
      const maritalStatus =
        allAnswers[pageCodes.spouseInfoPage][questionCodes.maritalStatus] &&
        allAnswers[pageCodes.spouseInfoPage][questionCodes.maritalStatus][
          questionInputKeys.MARITAL_STATUS
        ]
          ? allAnswers[pageCodes.spouseInfoPage][questionCodes.maritalStatus][
              questionInputKeys.MARITAL_STATUS
            ]
          : '';

      if (
        maritalStatus === optionKeys.MARRIED &&
        allAnswers[pageCodes.spouseInfoPage][questionCodes.spouseLegalName]
      ) {
        return {
          id: allAnswers[pageCodes.spouseInfoPage][
            questionCodes.spouseLegalName
          ]['id'],
          name: `${
            allAnswers[pageCodes.spouseInfoPage][questionCodes.spouseLegalName][
              questionInputKeys.FIRSTNAME
            ]
          } ${
            allAnswers[pageCodes.spouseInfoPage][questionCodes.spouseLegalName][
              questionInputKeys.LASTNAME
            ]
          }`,
        };
      } else if (
        maritalStatus === optionKeys.PARTNERSHIP &&
        allAnswers[pageCodes.spouseInfoPage][questionCodes.partnerLegalName]
      ) {
        return {
          id: allAnswers[pageCodes.spouseInfoPage][
            questionCodes.partnerLegalName
          ]['id'],
          name: `${
            allAnswers[pageCodes.spouseInfoPage][
              questionCodes.partnerLegalName
            ][questionInputKeys.FIRSTNAME]
          } ${
            allAnswers[pageCodes.spouseInfoPage][
              questionCodes.partnerLegalName
            ][questionInputKeys.LASTNAME]
          }`,
        };
      }
    }
    return null;
  };

  return {
    getSpousePartner,
  };
};

export default useAnswerService;
