import { toastSuccessMessage } from '../../components/common/ToastMessage';
import PageTitle from '../../components/common/PageTitle';
import { useEffect, useState } from 'react';
import {
  IShowDeleteCharityCategoryModal,
  ICharityCategoryPayload,
} from '../../datatypes/CharityCategory';
import {
  fetchCharityCategories,
  resetCharityCategoryData,
} from '../../redux/slices/charityCategorySlice';
import Listing from '../../components/charity-categories/Listing';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';

const CharityCategoriesListing = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { charityCategoryList, deleteCharityCategory } =
    useAppSelector<ICharityCategoryPayload>((state) => state.charityCategories);

  const [showDeleteModal, setShowDeleteModal] =
    useState<IShowDeleteCharityCategoryModal>({
      show: false,
      idDetails: [],
    });

  useEffect(() => {
    if (!charityCategoryList.loading) {
      dispatch(fetchCharityCategories());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (deleteCharityCategory.success) {
      toastSuccessMessage('Pricing Deleted Successfully');
      setShowDeleteModal({
        show: false,
        idDetails: [],
      });
      dispatch(resetCharityCategoryData());
      dispatch(fetchCharityCategories());
    }
    // eslint-disable-next-line
  }, [deleteCharityCategory]);

  return (
    <div className="charity-category-listing">
      <PageTitle
        heading="Charity Categories"
        buttonName="Add New"
        buttonClick={async () => {
          await dispatch(resetCharityCategoryData());
          navigate('/charity-categories/new');
        }}
      />
      <div className="charity-category-listing-content">
        <Listing
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          charityCategoryList={charityCategoryList}
          deleteCharityDetails={deleteCharityCategory}
        />
      </div>
    </div>
  );
};

export default CharityCategoriesListing;
