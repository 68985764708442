import { startCase } from 'lodash';
import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';

const FuneralPlanningAnswers = ({ answers }: any) => {
  const funeralInformation = answers[pageCodes.funeralInfoPage];
  const funeralArrangements =
    funeralInformation &&
    funeralInformation[questionCodes.funeralArrangementInformation];

  return (
    <div>
      <div>
        <table className="default-table table table-bordered">
          <tbody>
            <tr>
              <th colSpan={4} className="table-title">
                <div>
                  <h4>Funeral Planning</h4>
                </div>
              </th>
            </tr>
            <tr>
              <th className="text-left w-40">
                As of this date, have you made buried/funeral arrangements?
              </th>
              <td colSpan={3}>
                {startCase(
                  funeralInformation[questionCodes.hasFuneralArrangements][
                    questionInputKeys.HAS_FUNERAL_ARRANGEMENTS
                  ]
                )}
              </td>
            </tr>
            {funeralInformation[questionCodes.hasFuneralArrangements][
              questionInputKeys.HAS_FUNERAL_ARRANGEMENTS
            ] === 'yes' && (
              <>
                <tr>
                  <th>Funeral Home Name</th>
                  <td>
                    {funeralArrangements &&
                      funeralArrangements[questionInputKeys.FUNERAL_HOME_NAME]}
                  </td>
                  <th>Funeral Home Location</th>
                  <td>
                    {funeralArrangements &&
                      `${
                        funeralArrangements[questionInputKeys.FUNERAL_HOME_CITY]
                      }, ${
                        funeralArrangements[
                          questionInputKeys.FUNERAL_HOME_STATE
                        ]
                      }`}
                  </td>
                </tr>
                <tr>
                  <th>Funeral Details</th>
                  <td colSpan={3}>
                    {funeralArrangements &&
                      funeralArrangements[questionInputKeys.FUNDERAL_DETAIL]}
                  </td>
                </tr>
              </>
            )}
            {funeralInformation[questionCodes.hasFuneralArrangements][
              questionInputKeys.HAS_FUNERAL_ARRANGEMENTS
            ] === 'no' && (
              <tr>
                <th>
                  {' '}
                  Would like to receive more Information regarding funeral
                  planning?
                </th>
                <td>
                  {startCase(
                    funeralInformation[questionCodes.receiveFuneralPlanning][
                      questionInputKeys.RECEIVE_FUNERAL_PLANNING_INFORMATION
                    ]
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FuneralPlanningAnswers;
