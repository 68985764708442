import { dtCustomStyle } from '../../helpers/DataTableHelper';
import { Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import {
  IUsersColumns,
  IUsersRow,
  IUsersPayload,
  IUsersPagination,
} from '../../datatypes/User';
import { PageOptions } from '../../constants/Common';
import { Link } from 'react-router-dom';

interface IProps {
  usersList: IUsersPayload['members']['memberList'];
  setPagination: React.Dispatch<React.SetStateAction<IUsersPagination>>;
}

const Listing = ({ usersList, setPagination }: IProps) => {
  const columns: IUsersColumns[] = [
    {
      name: 'First Name',
      selector: (row: IUsersRow) => row.first_name,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: (row: IUsersRow) => row.last_name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row: IUsersRow) => row.email,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: (row: IUsersRow) => row.phone,
      sortable: true,
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: IUsersRow) => row.actions,
      center: true,
      compact: true,
      cell: (row: IUsersRow) => (
        <div>
          <Link
            title="View"
            className="btn btn-secondary btn-sm"
            to={`/member-users/${row.id}/view`}
            key="view"
          >
            <i className="fa fa-eye"></i>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="row mt-3">
      <div className="col-12">
        <Card>
          <Card.Body>
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={usersList.loading}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              paginationServer
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={usersList.data?.total}
              onChangeRowsPerPage={(new_per_page: number) => {
                setPagination((prev: IUsersPagination) => ({
                  ...prev,
                  per_page: new_per_page,
                }));
              }}
              data={usersList.data?.data}
              customStyles={dtCustomStyle}
              onChangePage={(page: number) => {
                setPagination((prev: IUsersPagination) => ({
                  ...prev,
                  page: page,
                }));
              }}
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Listing;
