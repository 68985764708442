import { ValidateField } from '../../helpers/ValidatorHelper';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  templateCharityDetailsField,
  charityDetailsValidations,
  optionsCharityStatus,
  // optionsCharityStatus,
} from '../../constants/Charity';
import { IRouteParams } from '../../datatypes/CommonTypes';
import { ICharityDetails, ICharityPayload } from '../../datatypes/Charity';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  fetchCharityDetails,
  fetchCharityCategories,
  saveUpdateCharityDetails,
} from '../../redux/slices/charitySlice';
import _forEach from 'lodash/forEach';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import TextInput from '../../components/common/TextInput';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import EditorInput from '../../components/common/EditorInput';
import { fetchCounties, fetchStates } from '../../redux/slices/locationSlice';
import SelectInput from '../../components/common/SelectInput';
import RadioGroupInput from '../../components/common/RadioGroupInput';
import FileInput from '../../components/common/FileInput';
import { ILocationPayload } from '../../datatypes/Location';
import CleaveInput from '../../components/common/CleaveInput';
import { Link } from 'react-router-dom';

const CharityDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id }: IRouteParams = useParams();
  const location = useLocation();

  const editorConfig = {
    readonly: false,
    height: 300,
    toolbarButtonSize: 'small' as const, // Change this to a valid value like 'small' or 'middle'
  };

  const { charityDetails, saveUpdateCharity, charityCategoryList } =
    useAppSelector<ICharityPayload>((state) => state.charities);

  const { data: stateOptions, stateLoading } = useAppSelector<
    ILocationPayload['states']
  >((state) => state.location.states);

  const [charityType, setCharityType] = useState('new');
  const [filePreview, setFilePreview] = useState<string>('');

  const [formData, setFormData] = useState<ICharityDetails>({
    ...templateCharityDetailsField,
  });

  const [errors, setErrors] = useState<ICharityDetails>({
    ...templateCharityDetailsField,
    status: '',
  });

  const [initialEditorContent, setInitialEditorContent] = useState('');

  const validations = useMemo(() => {
    return {
      ...charityDetailsValidations,
    };
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const allowedFileExtensions = ['.png', '.jpeg', '.jpg'];

      // Get the file extension from the file name
      const fileExtension = file.name.substring(file.name.lastIndexOf('.'));

      if (allowedFileExtensions.includes(fileExtension.toLowerCase())) {
        const reader = new FileReader();

        //create and set file preview path
        reader.onload = (event) => {
          if (event.target && typeof event.target.result === 'string') {
            setFilePreview(event.target.result);
          }
        };

        reader.readAsDataURL(file);

        handleInputs(e);
      } else {
        setFormData((prevData) => ({
          ...prevData,
          logo: '',
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          logo: 'Please upload a valid file type (jpeg, jpg, png).',
        }));
        setFilePreview('');
      }
    }
  };

  const handleInputs = (e: any) => {
    const name = e.target.name;
    let value: string | boolean;
    let error: string | undefined | null;

    //get value from checkbox / others
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    const fieldName =
      name !== 'state_id' && name !== 'county_id' && name !== 'category_id'
        ? name
        : name === 'state_id'
        ? 'State'
        : name === 'category_id'
        ? 'Category'
        : 'County';

    if (name !== 'logo') {
      //check for errors
      error = ValidateField(fieldName, value, validations[name]);

      //set errors
      if (error) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      }

      //update form Data
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    } else {
      //input handling for file
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      setFormData((prevData) => ({ ...prevData, [name]: e.target.files[0] }));
    }

    //handle status upate
    if (name === 'status') {
      //set status
      setFormData((prevData) => ({ ...prevData, category_id: '' }));

      // Empty category and validation
      error = ValidateField('Category', null, validations['category_id']);
      setErrors((prevErrors) => ({
        ...prevErrors,
        category_id: String(error),
      }));

      //trigger dispatch to fetch category list
      dispatch(fetchCharityCategories(String(value)));
    }

    //handle state id update
    if (name === 'state_id') {
      setFormData((prevData) => ({ ...prevData, county_id: null }));
      error = ValidateField('County', null, validations['county_id']);
      setErrors((prevErrors) => ({ ...prevErrors, county_id: String(error) }));
    }
  };

  const hasFormError = () => {
    let hasErrors = false;
    _forEach(formData, (value, key) => {
      const fieldName =
        key !== 'state_id' && key !== 'county_id' && key !== 'category_id'
          ? key
          : key === 'state_id'
          ? 'State'
          : key === 'category_id'
          ? 'Category'
          : 'County';
      const error = ValidateField(fieldName, value, validations[key]);
      if (error) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
        hasErrors = true;
      }
    });
    return hasErrors;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!hasFormError()) {
      let payload = {
        ...formData,
        status: formData.status === 'active' ? 'active' : 'inactive',
      };
      dispatch(saveUpdateCharityDetails(payload));
    }
  };

  const getCharityDetailsData = () => {
    if (id && id !== 'new') {
      dispatch(fetchCharityDetails(id));

      const containsView = location.pathname.includes('view');
      if (containsView) {
        setCharityType('view');
      } else {
        setCharityType('edit');
      }
    } else {
      setFormData(templateCharityDetailsField);
      dispatch(fetchCharityCategories('active'));
    }
  };

  // Component to render Edit button in page title if currently in view state.
  const EditButtonComponent = () => {
    if (charityType === 'view') {
      return (
        <Button
          variant="primary"
          type="button"
          onClick={() => navigate(`/charities/${id}/edit`)}
        >
          Edit
        </Button>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    getCharityDetailsData();
    dispatch(fetchStates());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (charityDetails.success) {
      const charityData: ICharityDetails = {
        id: charityDetails?.data?.id ?? '',
        name: charityDetails?.data?.name ?? '',
        description: charityDetails?.data?.description ?? '',
        category_id: charityDetails?.data?.category_id ?? '',
        city: charityDetails?.data?.city ?? '',
        zipcode: charityDetails?.data?.zipcode ?? '',
        state_id: charityDetails?.data?.state_id ?? '',
        status: charityDetails?.data?.status ?? '',
        logo: charityDetails?.data?.logo ?? '',
        address_1: charityDetails?.data?.address_1 ?? '',
      };
      setFilePreview(String(charityData?.logo));
      setFormData({ ...charityData });
      setInitialEditorContent(charityData.description);

      dispatch(fetchCharityCategories(charityData?.status));
      dispatch(fetchCounties(String(charityDetails?.data?.state_name)));
    } else if (charityDetails.error) {
      navigate('/charities');
    }
    // eslint-disable-next-line
  }, [charityDetails]);

  useEffect(() => {
    if (saveUpdateCharity.success) {
      toastSuccessMessage(
        `Charities ${
          charityType === 'new' ? 'created' : 'updated'
        } successfully`
      );
      navigate('/charities');
    }
    // eslint-disable-next-line
  }, [saveUpdateCharity]);

  return (
    <div className="charity-details profile-details">
      <PageTitle
        heading={
          charityType === 'new'
            ? 'New Charity'
            : ` Charity : ${charityDetails?.data?.name ?? ''}`
        }
        buttonName="Listing"
        buttonClick={() => navigate('/charities')}
        customButton={<EditButtonComponent />}
      />

      {charityDetails.loading ? (
        <Loader isFull />
      ) : !!charityDetails ? (
        <Card>
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg={4} md={12} className="profile-pic-logo-section">
                  {filePreview ? (
                    <div className="profile-pic-logo-wrapper">
                      <img
                        src={filePreview}
                        className="profile-pic-logo-image"
                        alt="Profile Pic"
                      />
                    </div>
                  ) : (
                    <div className="profile-pic-logo-image-NA">
                      Image Not Available
                    </div>
                  )}
                </Col>
                <Col lg={8} md={12}>
                  <Row>
                    <Col lg={12} md={12}>
                      <TextInput
                        controlId="name"
                        label="Name"
                        className="form-field"
                        placeholder="Name"
                        name="name"
                        value={formData.name}
                        onChange={(e) => handleInputs(e)}
                        errorMessage={errors?.name}
                        isRequired={true}
                        isDisabled={charityType === 'view'}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={12}>
                      <RadioGroupInput
                        name="status"
                        value={formData.status}
                        label="Status"
                        options={optionsCharityStatus}
                        onChange={handleInputs}
                        isRequired={true}
                        errorMessage={String(errors?.status)}
                        controlId="status"
                        isDisabled={charityType === 'view'}
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      {charityType !== 'view' && (
                        <FileInput
                          controlId="logo"
                          label="Logo"
                          className="form-field"
                          placeholder="Logo"
                          name="logo"
                          value={formData.logo}
                          onChange={(e) => handleFileChange(e)}
                          errorMessage={String(errors?.logo)}
                          isRequired={true}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={12}>
                      <CleaveInput
                        placeholder="e.g. 12-65698684"
                        name="ein_number"
                        isDisabled={charityType === 'view'}
                        options={{
                          blocks: [2, 7],
                          delimiter: '-',
                        }}
                        label="EIN Number"
                        value={formData?.ein_number ?? ''}
                        onChange={(e) => handleInputs(e)}
                        errorMessage={String(errors?.ein_number)}
                        isRequired
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      <TextInput
                        isRequired={false}
                        name="address_1"
                        label="Address"
                        placeholder="Address"
                        value={formData?.address_1}
                        onChange={(e) => handleInputs(e)}
                        // errorMessage={errors?.address ?? ''}
                        isDisabled={charityType === 'view'}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={12}>
                      <TextInput
                        isRequired={true}
                        name="city"
                        label="City"
                        placeholder="City"
                        value={formData?.city ?? ''}
                        onChange={(e) => handleInputs(e)}
                        errorMessage={String(errors?.city)}
                        isDisabled={charityType === 'view'}
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      <SelectInput
                        name="state_id"
                        placeholder="Select State"
                        value={formData.state_id}
                        optionLabel="state_name"
                        optionValue="id"
                        options={stateOptions}
                        isLoading={stateLoading}
                        handleSelectChange={(e) => handleInputs(e)}
                        label="State"
                        isRequired={true}
                        errorMessage={String(errors?.state_id)}
                        isDisabled={charityType === 'view'}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={12}>
                      <TextInput
                        isRequired={true}
                        name="zipcode"
                        label="Zipcode"
                        placeholder="Zipcode"
                        value={formData?.zipcode ?? ''}
                        onChange={(e) => handleInputs(e)}
                        errorMessage={String(errors?.zipcode)}
                        isDisabled={charityType === 'view'}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12}>
                      <SelectInput
                        name="category_id"
                        placeholder="Select Category"
                        value={formData.category_id}
                        optionLabel="name"
                        optionValue="id"
                        options={charityCategoryList?.data?.data ?? []}
                        isLoading={charityCategoryList?.loading}
                        handleSelectChange={(e) => handleInputs(e)}
                        label="Charity Category"
                        isRequired={true}
                        errorMessage={String(errors?.category_id)}
                        isDisabled={charityType === 'view'}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} md={12}>
                  <EditorInput
                    initialValue={initialEditorContent}
                    value={formData.description}
                    config={editorConfig}
                    controlId="description"
                    label="Description"
                    errorMessage={errors?.description}
                    onChange={(e) => {
                      if (e) {
                        handleInputs({
                          target: {
                            name: 'description',
                            value: e,
                            type: 'string',
                          },
                        });
                      }
                    }}
                    isRequired={true}
                    isDisabled={charityType === 'view'}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              {charityType !== 'view' && (
                <Button variant="primary" type="submit">
                  {charityType === 'new' ? 'Save' : 'Update'}
                </Button>
              )}
              <Link to="/charities" className="button-link">
                Cancel
              </Link>
            </Card.Footer>
          </Form>
        </Card>
      ) : (
        <NotFound
          heading="Charity Not Found"
          subHeading="Please try again after sometime"
        />
      )}
    </div>
  );
};

export default CharityDetails;
