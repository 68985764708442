import {
  ITerminologyDetails,
  ITerminologySearch,
  ITerminologyValidations,
} from '../datatypes/Terminology';

// initial state for form and error
export const templateTerminologyDetailsField: ITerminologyDetails = {
  term: '',
  description: '',
  alternate_term: '',
};

// validations for form
export const terminologyDetailsValidations: ITerminologyValidations = {
  term: 'required|min:2',
  description: 'required|min:2',
  alternate_term: 'max:50',
};

// template for terminlogy search
export const searchTerminologyField: ITerminologySearch = {
  term: '',
  description: '',
  alternate_term: '',
};

export const terminologySearchValidations: ITerminologyValidations = {
  term: 'max:50',
  alternate_term: 'max:50',
};
