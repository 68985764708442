import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ValidateField } from '../../helpers/ValidatorHelper';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import { IRouteParams } from '../../datatypes/CommonTypes';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import _forEach from 'lodash/forEach';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import SelectInput from '../../components/common/SelectInput';
import TextInput from '../../components/common/TextInput';
import EditorInput from '../../components/common/EditorInput';
import NumberInput from '../../components/common/NumberInput';
import {
  templatePageSectionDetails,
  templatePageDetails,
  templateErrorPageSectionDetails,
  templateErrorPageDetails,
  pageSectionDetailsValidations,
  pageDetailsValidations,
  sectionTitlePositionOptions,
  pageStatusOptions,
} from '../../constants/Page';
import {
  fetchPageDetails,
  saveUpdatePageDetails,
} from '../../redux/slices/pageSlice';
import { IPageSection, IPageDetails, IPagePayload } from '../../datatypes/Page';
import RadioGroupInput from '../../components/common/RadioGroupInput';
import { Link } from 'react-router-dom';

interface IPageSectionProps {
  section: IPageSection;
  index: number;
  errors: IPageDetails;
  handleInputs(
    e: { target: { name: string; value: string | number } },
    index: number
  ): void;
  initialEditorContent: string;
  pageType: 'new' | 'edit' | 'view';
  removeSection(index: number): void;
}

const PageSection = ({
  section,
  index,
  errors,
  handleInputs,
  initialEditorContent,
  pageType,
  removeSection,
}: IPageSectionProps) => {
  const editorConfig = {
    readonly: false,
    height: 300,
    toolbarButtonSize: 'small' as const,
  };

  return (
    <Card>
      <Card.Body>
        <Row className="align-items-center">
          <Col lg={12} md={12}>
            <TextInput
              controlId="section_title"
              label="Section Title"
              className="form-field"
              placeholder="Section Title"
              name="section_title"
              value={section.section_title}
              onChange={(e) => handleInputs(e, index)}
              errorMessage={errors?.content?.[index]?.section_title}
              isRequired={false}
              isDisabled={pageType === 'view'}
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg={12} md={12}>
            <EditorInput
              initialValue={initialEditorContent}
              value={section.section_body}
              config={editorConfig}
              controlId="section_body"
              label="Section Body"
              errorMessage={errors?.content?.[index]?.section_body}
              onChange={(e: string) => {
                if (e) {
                  handleInputs(
                    {
                      target: {
                        name: 'section_body',
                        value: e,
                      },
                    },
                    index
                  );
                }
              }}
              isRequired={true}
              isDisabled={pageType === 'view'}
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg={5} md={6}>
            <SelectInput
              name="section_title_position"
              placeholder="Select title position"
              value={section.section_title_position}
              optionLabel="label"
              optionValue="value"
              options={sectionTitlePositionOptions ?? []}
              handleSelectChange={(e) => handleInputs(e, index)}
              label="Title Position"
              isRequired={true}
              errorMessage={String(
                errors?.content?.[index]?.section_title_position
              )}
              isDisabled={pageType === 'view'}
            />
          </Col>
          <Col lg={5} md={6}>
            <NumberInput
              controlId="section_order"
              label="Section Order"
              className="form-field"
              placeholder="Section Order"
              name="section_order"
              value={Number(section.section_order)}
              onChange={(e) => {
                handleInputs(
                  {
                    target: {
                      name: 'section_order',
                      value: Number(e.target.value) ?? 0,
                    },
                  },
                  index
                );
              }}
              errorMessage={String(errors?.content?.[index]?.section_order)}
              min={0}
              max={1000}
              isRequired={true}
              isDisabled={pageType === 'view'}
            />
          </Col>
          {pageType !== 'view' && (
            <Col lg={2} md={6}>
              <Button
                variant="danger"
                onClick={() => removeSection(index)}
                style={{ marginTop: '13px', width: '100%' }}
              >
                DELETE
              </Button>
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

const PagesDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id }: IRouteParams = useParams();
  const location = useLocation();

  const { pageDetails, saveUpdatePage } = useAppSelector<IPagePayload>(
    (state) => state.pages
  );

  const [pageType, setPageType] = useState<'new' | 'edit' | 'view'>('new');

  const [formData, setFormData] = useState<IPageDetails>({
    ...templatePageDetails,
  });

  const [errors, setErrors] = useState<IPageDetails>({
    ...templateErrorPageDetails,
  });

  const [initialEditorContent, setInitialEditorContent] = useState<string[]>([
    '',
  ]);

  const validations = useMemo(() => {
    return {
      ...pageDetailsValidations,
      ...pageSectionDetailsValidations,
    };
  }, []);

  const getDisplayName = (key: string) => {
    switch (key) {
      case 'page_code':
        return 'Page Code';
      case 'title':
        return 'Title';
      case 'status':
        return 'Status';
      case 'meta_title':
        return 'Meta Title';
      case 'meta_description':
        return 'Meta Description';
      case 'section_title':
        return 'Section Title';
      case 'section_body':
        return 'Section Body';
      case 'section_title_position':
        return 'Section Title Position';
      case 'section_order':
        return 'Section Order';
      default:
        return key;
    }
  };

  //handle normal input
  const handleInputs = (e: any) => {
    const name = e.target.name;
    let value: string | number;
    let error: string | undefined | null;

    //get value from checkbox / others
    if (e.target.type === 'checkbox') {
      value = e.target.checked ? e.target.defaultValue : '';
    } else {
      value = e.target.value;
    }

    const fieldName = getDisplayName(name);

    error = ValidateField(fieldName, value, validations[name]);

    //set errors
    if (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error,
      }));
    } else {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          [name]: '',
        };
      });
    }

    //set form data
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //handle section input
  const handleSectionInputs = (
    e: { target: { name: string; value: string | number } },
    index: number
  ) => {
    const { name, value } = e.target;

    const fieldName = getDisplayName(name);

    const error = ValidateField(fieldName, value, validations[name]) ?? '';

    const newErrorData = JSON.parse(JSON.stringify(errors));
    const newFormData = JSON.parse(JSON.stringify(formData));
    newErrorData.content[index][name] = error;
    newFormData.content[index][name] = value;
    //set errors
    setErrors(newErrorData);
    setFormData(newFormData);
  };

  //check if sections has error
  const checkIfContentHasError = (data: IPageSection[]) => {
    let hasErrors = false;
    data?.forEach((section: IPageSection, index: number) => {
      _forEach(section, (value, key) => {
        const error = ValidateField(key, value, validations[key]);
        if (error) {
          setErrors((prevErrors) => {
            return {
              ...prevErrors,
              content: {
                ...prevErrors.content,
                [index]: {
                  ...prevErrors.content[index],
                  [key]: error,
                },
              },
            };
          });
          hasErrors = true;
        }
      });
    });
    return hasErrors;
  };

  //check if page - form has any error
  const hasFormError = () => {
    let hasErrors = false;
    _forEach(formData, (value, key) => {
      if (key !== 'content') {
        const error = ValidateField(key, value, validations[key]);
        if (error) {
          setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
          hasErrors = true;
        }
      } else {
        if (typeof value === 'object' && checkIfContentHasError(value)) {
          hasErrors = true;
        }
      }
    });

    return hasErrors;
  };

  //handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!hasFormError()) {
      let payload = { data: { ...formData }, id: id };
      dispatch(saveUpdatePageDetails(payload));
    }
  };

  // function to add a section to end of the form
  const addSection = () => {
    const newFormData = JSON.parse(JSON.stringify(formData));
    newFormData.content.push(templatePageSectionDetails);

    const newErrorData = JSON.parse(JSON.stringify(errors));
    newErrorData.content.push(templateErrorPageSectionDetails);

    setFormData(newFormData);
    setErrors(newErrorData);

    const content = JSON.parse(JSON.stringify(initialEditorContent));

    content.push('<p></p>');
    setInitialEditorContent(content);
  };

  // function to remove an given section from form
  const removeSection = (index: number) => {
    const newFormData = JSON.parse(JSON.stringify(formData));
    newFormData.content.splice(index, 1);
    if (newFormData.content.length === 0) {
      newFormData.content.push(templatePageSectionDetails);
    }

    const newErrorData = JSON.parse(JSON.stringify(errors));
    newErrorData.content.splice(index, 1);
    if (newErrorData.content.length === 0) {
      newErrorData.content.push(templateErrorPageSectionDetails);
    }

    setFormData(newFormData);
    setErrors(newErrorData);

    const content = newFormData.content.map((section: IPageSection) => {
      return section.section_body;
    });

    _forEach(content, (value, index) => {
      if (value === '<p></p>') {
        content[index] = '<p>Loading ...</p>';
      }
    });

    setInitialEditorContent(content);
  };

  // function to get page details
  const getPageDetails = async () => {
    if (id && id !== 'new') {
      dispatch(fetchPageDetails(id));
      const containsView = location.pathname.includes('view');
      if (containsView) {
        setPageType('view');
      } else {
        setPageType('edit');
      }
    }
  };

  //on page load
  useEffect(() => {
    setFormData({
      ...templatePageDetails,
    });
    setErrors({
      ...templateErrorPageDetails,
    });
    getPageDetails();
    // eslint-disable-next-line
  }, []);

  //on successfull update / save
  useEffect(() => {
    if (saveUpdatePage?.success) {
      toastSuccessMessage(
        `Page details ${
          pageType === 'edit' ? 'updated' : 'saved'
        } successfully!`
      );
      navigate('/pages');
    }
    // eslint-disable-next-line
  }, [saveUpdatePage]);

  //on successfull page details fetch
  useEffect(() => {
    if (pageDetails?.success && pageDetails?.data) {
      setFormData({
        ...pageDetails.data,
      });
      setErrors({
        ...templateErrorPageDetails,
      });

      const payload = pageDetails.data.content?.map((section: IPageSection) => {
        return section.section_body;
      });

      setInitialEditorContent(payload);
    }
  }, [pageDetails]);

  useEffect(() => {
    if (initialEditorContent.includes('<p>Loading ...</p>')) {
      const list = initialEditorContent.map((item) => {
        if (item !== '<p>Loading ...</p>') {
          return item;
        } else {
          return '';
        }
      });
      setInitialEditorContent(list);
    }
  }, [initialEditorContent]);

  // Component to render Edit button in page title if currently in view state.
  const EditButtonComponent = () => {
    if (pageType === 'view') {
      return (
        <Button
          variant="primary"
          type="button"
          onClick={() => navigate(`/pages/${id}/edit`)}
        >
          Edit
        </Button>
      );
    } else {
      return null;
    }
  };
  return (
    <div className="pages-details profile-details">
      <PageTitle
        heading={
          pageType === 'new'
            ? 'New Page'
            : ` Page : ${pageDetails?.data?.title ?? ''}`
        }
        buttonName="Listing"
        buttonClick={() => navigate('/pages')}
        customButton={<EditButtonComponent />}
      />
      {pageDetails.loading ? (
        <Loader isFull />
      ) : !!pageDetails ? (
        <Card>
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg={12} md={12}>
                  <TextInput
                    controlId="title"
                    label="Title"
                    className="form-field"
                    placeholder="Title"
                    name="title"
                    value={formData.title}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.title}
                    isRequired={true}
                    isDisabled={pageType === 'view'}
                  />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col lg={12} md={12}>
                  <TextInput
                    controlId="page_code"
                    label="Page Code"
                    className="form-field"
                    placeholder="Page Code"
                    name="page_code"
                    value={formData.page_code}
                    onChange={(e) => {
                      //to not take and special symbols and spaces
                      const cleanedValue = e.target.value
                        .replace(/[^\w\s]/gi, '')
                        .replace(/\s+/g, '');
                      e.target.value = cleanedValue;

                      //call the handle input function
                      handleInputs(e);
                    }}
                    errorMessage={errors?.page_code}
                    isRequired={true}
                    isDisabled={pageType === 'view'}
                  />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col lg={12} md={12}>
                  <TextInput
                    controlId="meta_title"
                    label="Meta Title"
                    className="form-field"
                    placeholder="Meta Title"
                    name="meta_title"
                    value={formData.meta_title}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.meta_title}
                    isRequired={true}
                    isDisabled={pageType === 'view'}
                  />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col lg={12} md={12}>
                  <TextInput
                    controlId="meta_description"
                    label="Meta Description"
                    className="form-field"
                    placeholder="Meta Description"
                    name="meta_description"
                    value={formData.meta_description}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.meta_description}
                    isRequired={true}
                    isDisabled={pageType === 'view'}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12}>
                  {formData.content &&
                    formData?.content?.map(
                      (section: IPageSection, index: number) => (
                        <div style={{ paddingBottom: '20px' }} key={index}>
                          <PageSection
                            section={section}
                            index={index}
                            handleInputs={handleSectionInputs}
                            errors={errors}
                            pageType={pageType}
                            removeSection={removeSection}
                            initialEditorContent={
                              initialEditorContent[index] ?? '<p></p>'
                            }
                          />
                        </div>
                      )
                    )}
                </Col>
              </Row>
              <Row className="align-items-center">
                {pageType !== 'view' && (
                  <Col lg={12} md={12} sm={12}>
                    <Button
                      variant="primary"
                      onClick={addSection}
                      type="button"
                    >
                      Add Section
                    </Button>
                  </Col>
                )}
              </Row>
              <Row>
                <Col lg={6} md={12}>
                  <RadioGroupInput
                    name="status"
                    value={formData.status}
                    label="Status"
                    options={pageStatusOptions}
                    onChange={(e) =>
                      pageType === 'view' ? {} : handleInputs(e)
                    }
                    isRequired={true}
                    errorMessage={String(errors?.status)}
                    controlId="status"
                    // isDisabled={pageType === 'view'}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Row className="align-items-center">
                <Col lg={12} md={12}>
                  {pageType !== 'view' && (
                    <Button
                      variant="primary"
                      type="submit"
                      style={{ marginRight: '10px' }}
                    >
                      {pageType === 'new' ? 'Save' : 'Update'}
                    </Button>
                  )}
                  <Link className="button-link secondary" to="/pages">
                    Cancel
                  </Link>
                </Col>
              </Row>
            </Card.Footer>
          </Form>
        </Card>
      ) : (
        <NotFound
          heading="Page Not Found"
          subHeading="Please try again after sometime"
        />
      )}
    </div>
  );
};

export default PagesDetails;
