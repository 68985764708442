import { Form } from 'react-bootstrap';
import ErrorMessage from './ErrorMessage';

interface IProps {
  name: string;
  value: boolean;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  errorMessage?: string;
  controlId?: string;
  className?: string;
  placeholder?: string;
}

const CheckBoxInput = ({
  name,
  value,
  label,
  onChange,
  errorMessage,
}: IProps) => {
  return (
    <Form.Group className="col-md-10">
      <Form.Check
        id={name}
        className="check-box d-flex align-items-center gap-2 check-box-input"
        name={name}
        label={`${label}`}
        checked={value ? value : false}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
        }}
      />
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </Form.Group>
  );
};

export default CheckBoxInput;
