import useAxiosService from './axios.service';

const useAuthService = () => {
  const { getGuestHttpClient, getUserHttpClient } = useAxiosService();
  const authenticate = async (loginData: any) => {
    return await getGuestHttpClient()
      .post('/login', { ...loginData, from: 'WILL_ADMIN' })
      .then((response) => {
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('expiration_time', response.data.expires_in);
        localStorage.setItem('admin_user', JSON.stringify(response.data.user));
        return { success: true };
      })
      .catch((error) => {
        if (error.response) {
          return {
            success: false,
            message:
              error.response?.data?.errors ?? error.response?.data?.message,
          };
        }
        return { success: false };
      });
  };

  const logout = async () => {
    return await getUserHttpClient()
      .post('/logout')
      .then(() => ({ success: true }))
      .catch(() => ({ succees: false }));
  };

  return { authenticate, logout };
};

export default useAuthService;
