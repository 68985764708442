import SelectInput from '../common/SelectInput';
import MaskInput from '../common/MaskInput';
import TextInput from '../common/TextInput';
import FowButton from '../common/FowButton';
import React from 'react';
import { IInvoiceField } from '../../datatypes/Invoice';
import { paymentStatusOptions } from '../../constants/Invoice';

interface IProps {
  invoiceSearch: IInvoiceField;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInvoiceSearch: () => void;
  resetInvoiceSearch: () => void;
  isFilterApplied: boolean;
  errors: IInvoiceField;
}

const InvoiceSearch = ({
  invoiceSearch,
  handleInputChange,
  onInvoiceSearch,
  resetInvoiceSearch,
  isFilterApplied,
  errors,
}: IProps) => {
  return (
    <div className="invoices-form">
      <div className="row">
        <div className="col-lg-6">
          <TextInput
            name="invoice_number"
            placeholder="Invoice ID"
            value={invoiceSearch.invoice_number}
            onChange={(e) => handleInputChange(e)}
            label="Invoice ID"
            errorMessage={errors.invoice_number}
            isRequired={false}
          />
        </div>
        <div className="col-lg-6">
          <TextInput
            name="transaction_id"
            placeholder="Transaction ID"
            value={invoiceSearch.transaction_id}
            onChange={(e) => handleInputChange(e)}
            label="Transaction ID"
            errorMessage={errors.transaction_id}
            isRequired={false}
          />
        </div>
        <div className="col-lg-6">
          <TextInput
            name="first_name"
            placeholder="First Name"
            value={invoiceSearch.first_name}
            onChange={(e) => handleInputChange(e)}
            label="First Name"
            errorMessage={errors.first_name}
            isRequired={false}
          />
        </div>
        <div className="col-lg-6">
          <TextInput
            name="last_name"
            placeholder="Last Name"
            value={invoiceSearch.last_name}
            onChange={(e) => handleInputChange(e)}
            label="Last Name"
            errorMessage={errors.last_name}
            isRequired={false}
          />
        </div>
        <div className="col-lg-6">
          <TextInput
            name="email"
            placeholder="Email"
            value={invoiceSearch.email}
            onChange={(e) => handleInputChange(e)}
            label="Email"
            errorMessage={errors.email}
            isRequired={false}
          />
        </div>
        <div className="col-lg-6">
          <SelectInput
            name="payment_status"
            placeholder="Payment Status"
            value={invoiceSearch.payment_status}
            options={paymentStatusOptions}
            handleSelectChange={(e) => handleInputChange(e)}
            label="Payment Status"
          />
        </div>
        <div className="col-lg-6">
          <MaskInput
            mask="99/99/9999"
            name="payment_date_from"
            placeholder="mm/dd/yyyy"
            value={invoiceSearch.payment_date_from}
            label="Payment Date From"
            onChange={(e) => handleInputChange(e)}
            errorMessage={errors.payment_date_from}
            isRequired={false}
          />
        </div>
        <div className="col-lg-6">
          <MaskInput
            mask="99/99/9999"
            name="payment_date_to"
            placeholder="mm/dd/yyyy"
            value={invoiceSearch.payment_date_to}
            label="Payment Date To"
            onChange={(e) => handleInputChange(e)}
            errorMessage={errors.payment_date_to}
            isRequired={false}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-lg-12">
          <div className="d-flex gap-3 justify-content-between w-100">
            <FowButton variant="success" onClick={onInvoiceSearch}>
              <i className="fa fa-search mr-2"></i> Search
            </FowButton>
            {isFilterApplied && (
              <FowButton
                variant="outline-secondary"
                onClick={resetInvoiceSearch}
              >
                <i className="fa fa-undo mr-2"></i> Reset
              </FowButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceSearch;
