import { toastSuccessMessage } from '../../components/common/ToastMessage';
import PageTitle from '../../components/common/PageTitle';
import { useEffect, useState } from 'react';
import {
  IShowDeleteStateModal,
  ILocationPayload,
} from '../../datatypes/Location';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchStates, resetStateData } from '../../redux/slices/locationSlice';
import Listing from '../../components/states/Listing';

const StateListing = () => {
  const dispatch = useAppDispatch();

  const states = useAppSelector<ILocationPayload['states']>(
    (state) => state.location.states
  );

  const { deleteStateStatus } = states;

  const [showDeleteStateModal, setShowDeleteStateModal] =
    useState<IShowDeleteStateModal>({
      show: false,
      idDetails: [],
    });

  const getStatesListData = () => {
    dispatch(fetchStates());
  };

  useEffect(() => {
    getStatesListData();
    // eslint-disable-next-line
  }, []);

  //handle successful delete api call and modal closing
  useEffect(() => {
    if (deleteStateStatus.success) {
      toastSuccessMessage('State deleted successfully');
      dispatch(resetStateData());
      setShowDeleteStateModal({ show: false, idDetails: [] });
    }
    // eslint-disable-next-line
  }, [deleteStateStatus]);

  return (
    <div className="states-listing">
      <PageTitle heading="States" />
      <div className="states-listing-content">
        <Listing
          stateList={states.data ?? []}
          statesLoading={states.stateLoading}
          showDeleteModal={showDeleteStateModal}
          setShowDeleteModal={setShowDeleteStateModal}
          deleteStateDetail={deleteStateStatus}
        />
      </div>
    </div>
  );
};

export default StateListing;
