import _startCase from 'lodash/startCase';
import moment from 'moment';
import { optionKeys } from 'src/constants/OptionKeys';
import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';

const FamilyInformationAnswers = ({ answers }: any) => {
  const spousalInformation = answers[pageCodes.spouseInfoPage];
  const childrenInformation = answers[pageCodes.childrenInfoPage];

  return (
    <div>
      <div>
        <table className="default-table table table-bordered">
          <tbody>
            <tr>
              <th colSpan={4} className="table-title">
                <div>
                  <h4>Family Information</h4>
                </div>
              </th>
            </tr>
            <tr>
              <th className="text-left w-20">Marital Status</th>
              <td
                colSpan={
                  spousalInformation[questionCodes.maritalStatus][
                    questionInputKeys.MARITAL_STATUS
                  ] === optionKeys.SINGLE
                    ? 3
                    : 1
                }
              >
                {_startCase(
                  spousalInformation[questionCodes.maritalStatus][
                    questionInputKeys.MARITAL_STATUS
                  ]
                )}
              </td>
              {spousalInformation[questionCodes.maritalStatus][
                questionInputKeys.MARITAL_STATUS
              ] === optionKeys.MARRIED && (
                <>
                  <th className="text-left w-20">Spouse Name</th>
                  <td width="30%">
                    {_startCase(
                      `${
                        spousalInformation[questionCodes.spouseLegalName][
                          questionInputKeys.FIRSTNAME
                        ]
                      } ${
                        spousalInformation[questionCodes.spouseLegalName][
                          questionInputKeys.MIDDLENAME
                        ] ?? ''
                      } ${
                        spousalInformation[questionCodes.spouseLegalName][
                          questionInputKeys.LASTNAME
                        ]
                      }`
                    )}
                  </td>
                </>
              )}
              {spousalInformation[questionCodes.maritalStatus][
                questionInputKeys.MARITAL_STATUS
              ] === optionKeys.PARTNERSHIP && (
                <>
                  <th className="text-left w-20">Partner Name</th>
                  <td width="30%">
                    {_startCase(
                      `${
                        spousalInformation[questionCodes.partnerLegalName][
                          questionInputKeys.FIRSTNAME
                        ]
                      } ${
                        spousalInformation[questionCodes.partnerLegalName][
                          questionInputKeys.MIDDLENAME
                        ] ?? ''
                      } ${
                        spousalInformation[questionCodes.partnerLegalName][
                          questionInputKeys.LASTNAME
                        ]
                      }`
                    )}
                  </td>
                </>
              )}
            </tr>
            <tr>
              <th className="text-left">Do you have children?</th>
              <td>
                {_startCase(
                  childrenInformation[questionCodes.haveChildren][
                    questionInputKeys.HAS_CHILDREN
                  ]
                )}
              </td>
              <th className="text-left">
                Would you like to provide for future children?
              </th>
              <td>
                {_startCase(
                  childrenInformation[questionCodes.hasFutureChildren][
                    questionInputKeys.HAS_FUTURE_CHILDREN
                  ]
                )}
              </td>
            </tr>
            {childrenInformation[questionCodes.haveChildren][
              questionInputKeys.HAS_CHILDREN
            ] === 'yes' && (
              <>
                <tr>
                  <th className="text-left w-30" colSpan={2}>
                    Children Name
                  </th>
                  <th className="text-left">Children Birth Date</th>
                  <th className="text-left">Is Specially Able/Disabled?</th>
                </tr>
                {childrenInformation[questionCodes.childrenNames] &&
                  childrenInformation[questionCodes.childrenNames].map(
                    (child: any, index: number) => (
                      <tr key={index}>
                        <td colSpan={2}>
                          {_startCase(
                            `${child[questionInputKeys.FIRSTNAME]} ${
                              child[questionInputKeys.MIDDLENAME] ?? ''
                            }${child[questionInputKeys.LASTNAME]}`
                          )}
                        </td>
                        <td>
                          {moment(
                            child[questionInputKeys.BIRTHDATE],
                            'YYYY-MM-DD'
                          ).format('MM-DD-YYYY')}
                        </td>
                        <td>
                          {child[questionInputKeys.IS_DISABLED] ? 'Yes' : 'No'}
                        </td>
                      </tr>
                    )
                  )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FamilyInformationAnswers;
