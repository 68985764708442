import useAxiosService from '../services/axios.service';

const useMissingWillSearchApi = () => {
  const { getUserHttpClient } = useAxiosService();
  const getMissingWillSearches = async (params?: any) => {
    return await getUserHttpClient()
      .get('/missing-will-search', { params: params ? params : {} })
      .then((response) => response?.data);
  };
  const getMissingWillSearchDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/missing-will-search/' + id)
      .then((response) => response?.data);
  };
  const deleteMissingWillSearch = async (id: string) => {
    return await getUserHttpClient()
      .delete('/missing-will-search/' + id)
      .then((response) => response?.data);
  };

  return {
    getMissingWillSearches,
    getMissingWillSearchDetails,
    deleteMissingWillSearch,
  };
};

export default useMissingWillSearchApi;
