import { IWillRegistrationSearchField } from '../datatypes/WillRegistration';

export const WillLocationTypes = {
  HOME: 'home',
  ATTORNEY: 'attorney',
  FINANCIAL_INSTITUTION: 'business',
  PRIVATE_PARTY: 'private',
  ICLOUD: 'icloud',
};

export const WillTypesWithAddress = [
  WillLocationTypes.HOME,
  WillLocationTypes.ATTORNEY,
  WillLocationTypes.FINANCIAL_INSTITUTION,
  WillLocationTypes.PRIVATE_PARTY,
];

export const WillTypes = {
  ORIGINAL: 'original',
  DUPLICATE: 'duplicate',
  TRUST: 'trust',
};

export const WillRegistrationListPagination = {
  per_page: 50,
  page: 1,
};

export const InitialWillRegistrationSearchField: IWillRegistrationSearchField =
  {
    first_name: '',
    last_name: '',
    email: '',
    state_id: '',
    will_code: '',
    created_from: '',
    created_to: '',
  };

export const WillRegistrationSearchFieldValidations: IWillRegistrationSearchField =
  {
    first_name: 'nameValidate|min:2',
    last_name: 'nameValidate|min:2',
    email: 'email',
    state_id: '',
    will_code: 'min:2|willRegCode',
    created_from: 'date:MM-DD-YYYY',
    created_to: 'date:MM-DD-YYYY|compareDateMore',
  };
