import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchLivingWillDetails } from '../../redux/slices/willRegistrationSlice';
import { IRouteParams } from '../../datatypes/CommonTypes';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import {
  IWillRegistrationBeneficiary,
  IWillRegistrationLocation,
} from '../../datatypes/WillRegistration';
import {
  WillLocationTypes,
  WillTypes,
  WillTypesWithAddress,
} from '../../constants/WillRegistration';
import useWillRegistrationApi from '../../api/willRegistration.api';
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../components/common/ToastMessage';
import _startCase from 'lodash/startCase';

interface IWillLocationProps {
  data: IWillRegistrationLocation;
}

const WillAddressDetails = ({ data }: IWillLocationProps) => {
  return (
    <>
      <tr>
        <th className="text-left">Address:</th>
        <td colSpan={3}>
          {data?.address1 ?? ''}
          {data?.address2 ? ', ' + data?.address2 : ''}
          {data?.city ? ', ' + data?.city : ''}
          {data?.county ? ', ' + data?.county : ''}
          {data?.state ? ', ' + data?.state : ''}
          {data?.zipcode ? ' - ' + data?.zipcode : ''}
        </td>
      </tr>
    </>
  );
};

const WillLocation = ({ data }: IWillLocationProps) => {
  switch (data?.will_location) {
    case WillLocationTypes.HOME:
      return (
        <tbody>
          <tr>
            <th colSpan={4}>
              <div className="table-title">
                <h3>
                  {_startCase(`${data?.will_type} Will`)} -{' '}
                  {_startCase(data?.will_location ?? '')}
                </h3>
              </div>
            </th>
          </tr>
          <tr>
            <th className="text-left">Located in Home?</th>
            <td colSpan={3}>{data?.home_location ?? 'N/A'}</td>
          </tr>
          <tr>
            <th className="text-left">Phone :</th>
            <td width="30%">{data?.home_phone ?? 'N/A'}</td>
            <th className="text-left">Email :</th>
            <td width="30%">{data?.home_email ?? 'N/A'}</td>
          </tr>
        </tbody>
      );
    case WillLocationTypes.PRIVATE_PARTY:
      return (
        <tbody>
          <tr>
            <th colSpan={4}>
              <div className="table-title">
                <h3>
                  {_startCase(`${data?.will_type} Will`)} -{' '}
                  {_startCase(data?.will_location ?? '')}
                </h3>
              </div>
            </th>
          </tr>
          <tr>
            <th className="text-left">Contact Person Name :</th>
            <td width="30%">{`${data?.private_contact_first_name} ${data?.private_contact_last_name}`}</td>
            <th className="text-left">Relationship :</th>
            <td width="30%">
              {_startCase(data?.private_contact_relationship ?? 'N/A')}
            </td>
          </tr>
          <tr>
            <th className="text-left">Phone :</th>
            <td width="30%">{data?.private_phone ?? 'N/A'}</td>
            <th className="text-left">Email :</th>
            <td width="30%">{data?.private_email ?? 'N/A'}</td>
          </tr>
        </tbody>
      );
    case WillLocationTypes.ATTORNEY:
      return (
        <tbody>
          <tr>
            <th colSpan={4}>
              <div className="table-title">
                <h3>
                  {_startCase(data?.will_type)} Will -{' '}
                  {_startCase(data?.will_location ?? '')}
                </h3>
              </div>
            </th>
          </tr>
          <tr>
            <th className="text-left">Attorney Name:</th>
            <td width="30%">{`${data?.attorney_first_name} ${data?.attorney_last_name}`}</td>
            <th className="text-left">Firm Name :</th>
            <td width="30%">{_startCase(data?.attorney_firm_name ?? 'N/A')}</td>
          </tr>
          <tr>
            <th className="text-left">Phone :</th>
            <td width="30%">{data?.attorney_phone ?? 'N/A'}</td>
            <th className="text-left">Email :</th>
            <td width="30%">{data?.attorney_email ?? 'N/A'}</td>
          </tr>
          <tr></tr>
        </tbody>
      );
    case WillLocationTypes.ICLOUD:
      return (
        <tbody>
          <tr>
            <th colSpan={4}>
              <div className="table-title">
                <h3>
                  {_startCase(data?.will_type)} Will -{' '}
                  {_startCase(data?.will_location ?? '')}
                </h3>
              </div>
            </th>
          </tr>
          <tr>
            <th className="text-left">Email :</th>
            <td width="30%">{data?.icloud_email ?? 'N/A'}</td>
            <th className="text-left">Website :</th>
            <td width="30%">{data?.icloud_website ?? 'N/A'}</td>
          </tr>
        </tbody>
      );
    default:
      return (
        <tbody>
          <tr>
            <th colSpan={4}>
              <div className="table-title">
                <h3>{_startCase(data.will_type ?? '')} Will</h3>
              </div>
            </th>
          </tr>
        </tbody>
      );
  }
};

const LivingWillDetails = () => {
  //route related hooks
  const navigate = useNavigate();
  const { id }: IRouteParams = useParams();
  const [isSending, setIsSending] = useState<boolean>(false);
  const { sendCertificate } = useWillRegistrationApi();

  //redux
  const dispatch = useAppDispatch();

  const { livingWillDetails: livingWill } = useAppSelector(
    (state) => state.livingWill
  );

  const livingWillDetails = livingWill.data;

  const getLivingWillDetailsData = () => {
    if (id) {
      dispatch(fetchLivingWillDetails(id));
    }
  };

  const sendWillRegistrationCertificate = async () => {
    setIsSending(true);
    if (id) {
      const response = await sendCertificate(id);
      if (response.success) {
        toastSuccessMessage('The Certificate email has been sent.');
      } else {
        toastErrorMessage(
          'Error in sending certificate email. Please try again.'
        );
      }
    }
    setIsSending(false);
  };

  //handle getting data on page load.
  useEffect(() => {
    getLivingWillDetailsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="living-will-details profile-details">
      <div className="d-flex justify-content-between align-items-center">
        <PageTitle
          heading={`Will Registration : ${
            livingWillDetails?.will_code ?? 'Loading ...'
          }`}
          border={false}
        />
        <div>
          <Button
            variant="primary"
            className="mr-2"
            onClick={sendWillRegistrationCertificate}
            disabled={isSending}
          >
            {isSending ? (
              <>
                <i className="fa fa-spin fa-spinner"></i> Please Wait...
              </>
            ) : (
              <>
                <i className="fa fa-paper-plane-o"></i> Send Certificate
              </>
            )}
          </Button>
          <Button variant="primary" onClick={() => navigate('/living-will')}>
            <i className="fa fa-list"></i> Listing
          </Button>
        </div>
      </div>
      {livingWill.loading ? (
        <Loader isFull />
      ) : !!livingWillDetails ? (
        <div>
          {/** Personal Details  */}
          <table className="living-will-details-table table table-hover table-bordered">
            <tbody>
              <tr>
                <th colSpan={4}>
                  <div className="table-title">
                    <h3>Personal Information</h3>
                  </div>
                </th>
              </tr>
              <tr>
                <th className="text-left">Full Name:</th>
                <td colSpan={3}>
                  {`${livingWillDetails?.personalInfo?.first_name} ${livingWillDetails?.personalInfo?.last_name}`}
                </td>
              </tr>
              <tr>
                <th className="text-left">Address:</th>
                <td colSpan={3}>
                  {livingWillDetails?.personalInfo?.address_1 ?? ''}
                  {livingWillDetails?.personalInfo?.address_2
                    ? ', ' + livingWillDetails?.personalInfo?.address_2
                    : ''}
                  {livingWillDetails?.personalInfo?.city
                    ? ', ' + livingWillDetails?.personalInfo?.city
                    : ''}
                  {livingWillDetails?.personalInfo?.county_name
                    ? ', ' + livingWillDetails?.personalInfo?.county_name
                    : ''}
                  {livingWillDetails?.personalInfo?.state_abbr
                    ? ', ' + livingWillDetails?.personalInfo?.state_abbr
                    : ''}
                  {livingWillDetails?.personalInfo?.zipcode
                    ? ' - ' + livingWillDetails?.personalInfo?.zipcode
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>

          {/** Will Locations */}
          {livingWillDetails.willLocations.map(
            (location: IWillRegistrationLocation, index) =>
              location?.will_type === WillTypes.TRUST &&
              location?.is_trust_stored_with_will === 1 ? (
                <table
                  className="living-will-details-table table table-hover table-bordered"
                  key={index}
                >
                  <tbody>
                    <tr>
                      <th colSpan={4}>
                        <div className="table-title">
                          <h3>{_startCase(location?.will_type)} Will</h3>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        Trust will is stored with{' '}
                        {_startCase(location?.trust_stored_with ?? 'N/A')} will.
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <table
                  className="living-will-details-table table table-hover table-bordered"
                  key={index}
                >
                  <WillLocation data={location} />
                  {WillTypesWithAddress.includes(
                    location?.will_location ?? ''
                  ) && <WillAddressDetails data={location} />}
                </table>
              )
          )}

          {/** Will Beneficiaries */}
          {livingWillDetails.beneficiaries.length !== 0 && (
            <table className="living-will-details-table table table-hover table-bordered">
              <tbody>
                <tr>
                  <th colSpan={2}>
                    <div className="table-title">
                      <h3>Will Beneficiaries</h3>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>Name</th>
                  <th>Relationship</th>
                </tr>

                {livingWillDetails.beneficiaries.map(
                  (beneficiary: IWillRegistrationBeneficiary, index) => (
                    <tr key={index}>
                      <td>
                        {_startCase(
                          `${beneficiary?.first_name} ${beneficiary?.last_name}`
                        )}
                      </td>
                      <td>{_startCase(beneficiary?.relationship ?? 'N/A')}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          )}
        </div>
      ) : (
        <NotFound
          heading="Living Will Details"
          subHeading="Living Will Details not found"
        />
      )}
    </div>
  );
};

export default LivingWillDetails;
