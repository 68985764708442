import { startCase } from 'lodash';
import { formattedInputKeys } from 'src/constants/FormattedInputKeys';
import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';

const NonProbateAssetsAnswers = ({ answers }: any) => {
  const nonProbateAssetsInformation = answers[pageCodes.nonProbateAssetsPage];
  const nonProbateAssets =
    nonProbateAssetsInformation &&
    nonProbateAssetsInformation[questionCodes.nonProbateAssets];

  return (
    <div>
      <div>
        <table className="default-table table table-bordered">
          <tbody>
            <tr>
              <th colSpan={5} className="table-title">
                <div>
                  <h4>Non-Probate Assets</h4>
                </div>
              </th>
            </tr>
            <tr>
              <th className="text-left w-20">
                Do you have non-probate assets?
              </th>
              <td colSpan={4}>
                {startCase(
                  nonProbateAssetsInformation[
                    questionCodes.hasNonProbateAssets
                  ][questionInputKeys.HAS_NON_PROBATE_ASSETS]
                )}
              </td>
            </tr>
            {nonProbateAssetsInformation[questionCodes.hasNonProbateAssets][
              questionInputKeys.HAS_NON_PROBATE_ASSETS
            ] === 'yes' && (
              <>
                <tr>
                  <th>Asset Type</th>
                  <th>Institution Name and/or Property Owner Name</th>
                  <th>Approx. Amount</th>
                  <th>Policy/Account #</th>
                  <th>Description</th>
                </tr>
                {nonProbateAssets.map((asset: any, index: number) => (
                  <tr key={index}>
                    <td>{formattedInputKeys[asset.probate_asset_type]}</td>
                    <td>{startCase(asset.provider)}</td>
                    <td>
                      {' '}
                      {asset.amount && asset.amount !== ''
                        ? new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(asset.amount)
                        : 'N/A'}
                    </td>
                    <td>
                      {asset.account_no && asset.account_no !== ''
                        ? asset.account_no
                        : 'N/A'}
                    </td>
                    <td>{asset.description}</td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NonProbateAssetsAnswers;
