import { deleteEmptyKeys } from '../../helpers/common';
import { reformatWillRegistrationData } from '../../helpers/WillRegistrationHelper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useWillRegistrationApi from '../../api/willRegistration.api';
import {
  IWillRegistrationDetails,
  IWillRegistrationLocation,
  IWillRegistrationPayload,
} from '../../datatypes/WillRegistration';

const initialState: IWillRegistrationPayload = {
  livingWillList: {
    loading: false,
    error: false,
    success: false,
    data: {
      data: [],
      current_page: 0,
      first_page_url: '',
      from: 0,
      last_page: 0,
      path: '',
      per_page: 0,
      prev_page_url: '',
      to: 0,
      total: 0,
    },
  },
  livingWillDetails: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  deleteWillRegistrationStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

export const willRegistrationSlice = createSlice({
  name: 'willRegistrations',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetWillRegistrationData: (state: IWillRegistrationPayload) => {
      state.deleteWillRegistrationStatus =
        initialState.deleteWillRegistrationStatus;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchWillRegistrations.pending, (state) => {
        state.livingWillList.loading = true;
        state.livingWillList.error = false;
        state.livingWillList.success = false;
      })
      .addCase(fetchWillRegistrations.fulfilled, (state, { payload }) => {
        state.livingWillList.data = payload?.livingWills;
        state.livingWillList.loading = false;
        state.livingWillList.success = true;
      })
      .addCase(fetchWillRegistrations.rejected, (state) => {
        state.livingWillList.loading = false;
        state.livingWillList.error = true;
      })
      .addCase(fetchLivingWillDetails.pending, (state) => {
        state.livingWillDetails.loading = true;
        state.livingWillDetails.error = false;
        state.livingWillDetails.success = false;
        state.livingWillDetails.data = initialState.livingWillDetails.data;
      })
      .addCase(fetchLivingWillDetails.fulfilled, (state, { payload }) => {
        state.livingWillDetails.data = payload;
        state.livingWillDetails.loading = false;
        state.livingWillDetails.success = true;
      })
      .addCase(deleteWillRegistrationData.pending, (state) => {
        state.deleteWillRegistrationStatus.loading = true;
        state.deleteWillRegistrationStatus.success = false;
        state.deleteWillRegistrationStatus.error = false;
      })
      .addCase(deleteWillRegistrationData.fulfilled, (state) => {
        state.deleteWillRegistrationStatus.loading = false;
        state.deleteWillRegistrationStatus.success = true;
      })
      .addCase(deleteWillRegistrationData.rejected, (state) => {
        state.deleteWillRegistrationStatus.loading = false;
        state.deleteWillRegistrationStatus.error = true;
      });
  },
});

function convertDateString(dateString: string) {
  // Split the date string by "/"
  const parts = dateString.split('/');

  // Ensure we have three parts (day, month, year)
  if (parts.length !== 3) {
    throw new Error('Invalid date format');
  }

  // Reformat the date parts and join them with "-"
  const year = parts[2].length === 4 ? parts[2] : '20' + parts[2];
  const month = parts[0].length === 1 ? '0' + parts[0] : parts[0];
  const day = parts[1].length === 1 ? '0' + parts[1] : parts[1];

  return `${year}-${month}-${day}`;
}

// Async API Hooks

/**
 * Fetch Living Will List from API and save in redux store
 */
export const fetchWillRegistrations = createAsyncThunk(
  'livingWill/fetchWillRegistrations',
  async (data: any, { rejectWithValue }) => {
    data.params = deleteEmptyKeys(data.params);

    // convert date string to api payload format.
    if (data.params['created_from']) {
      data.params['created_from'] = convertDateString(
        data.params['created_from']
      );
    }
    if (data.params['created_to']) {
      data.params['created_to'] = convertDateString(data.params['created_to']);
    }

    const response = await useWillRegistrationApi().getWillRegistrations(
      data.params
    );
    // The value we returns becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Delete Living Will Details
 */
export const deleteWillRegistrationData = createAsyncThunk(
  'livingWill/deleteWillRegistrationData',
  async (id: string, { rejectWithValue }) => {
    const response = await useWillRegistrationApi().deleteWillRegistrationData(
      id
    );
    // The value we returns becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Living Will Details from API and save in redux store
 */
export const fetchLivingWillDetails = createAsyncThunk(
  'livingWill/fetchLivingWillDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useWillRegistrationApi().getWillRegistrationDetails(
      id
    );

    if (response.status === 200) {
      const livingWill = JSON.parse(JSON.stringify(response?.data?.livingWIll));

      const data: IWillRegistrationDetails = {
        id: livingWill?.id,
        will_code: livingWill?.living_wills?.will_code,
        beneficiaries: livingWill?.livingWillBeneficiaries,
        personalInfo: livingWill?.personalInfo,
        willLocations: [],
      };

      const willLocations = [
        { ...livingWill?.originalWillLocation },
        { ...livingWill?.duplicateWillLocation },
        { ...livingWill?.trustLocation },
      ];

      const livingWillLocations: IWillRegistrationLocation[] = [];

      willLocations?.forEach((location: any) => {
        const payload: IWillRegistrationLocation =
          reformatWillRegistrationData(location);
        livingWillLocations.push(payload);
      });

      data.willLocations = livingWillLocations;

      return data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const { resetWillRegistrationData } = willRegistrationSlice.actions;

export default willRegistrationSlice.reducer;
