import {
  ICharityCategoryDetails,
  ICharityCategoryValidations,
} from '../datatypes/CharityCategory';
import { OptionType } from '../datatypes/CommonTypes';

// initial state for form and error
export const charityCategoryDetailsField: ICharityCategoryDetails = {
  name: '',
  content: '',
  meta_title: '',
  meta_description: '',
  status: 'active',
};

// validations for form
export const charityCategoryDetailsValidations: ICharityCategoryValidations = {
  name: 'required|min:2|max:50',
  content: 'required|min:20|max:255',
  meta_title: 'required|min:2|max:50',
  meta_description: 'required|min:20|max:255',
  status: 'required',
};

// options for status

export const charityCategoryStatusOptions: OptionType<string>[] = [
  {
    label: 'Active',
    value: 'active',
    disabled: false,
    color: 'btn-success',
  },
  {
    label: 'Inactive',
    value: 'inactive',
    disabled: false,
    color: 'btn-secondary',
  },
];
