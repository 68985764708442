const Page500 = () => {
  return (
    <div className="align-items-center justify-content-center vh-100 d-flex">
      <div>
        <h1 className="float-left display-3 mr-4">500</h1>
        <h4 className="pt-3">Internal Server Error!</h4>
        <p className="text-muted float-left">
          The server encountered an internal error or misconfiguration and{' '}
          <br />
          was unable to complete your request.
        </p>
        <p className="text-muted float-left">
          Please contact the server info@freeonlinewill.com to inform them{' '}
          <br />
          of the time this error orccured, and the actions you performed just
          before this error
        </p>
      </div>
    </div>
  );
};
export default Page500;
