import DataTable from 'react-data-table-component';
import { Card } from 'react-bootstrap';
import { dtCustomStyle } from '../../helpers/DataTableHelper';
import {
  IWillDetailColumn,
  IWillDetailRow,
} from '../../datatypes/MisssingWillSearch';
import { PageOptions } from '../../constants/Common';
import moment from 'moment';
import { IDashboardPayload } from '../../datatypes/Dashboard';
import PageTitle from '../../components/common/PageTitle';
import { useNavigate } from 'react-router-dom';
import NotFound from '../../components/common/NotFound';
import { Link } from 'react-router-dom';

interface IProps {
  missingWillData: IDashboardPayload['dashboardData']['data']['missingWillData'];
  isLoading: IDashboardPayload['dashboardData']['loading'];
}

const MissingWillSearchDashboardListing = ({
  missingWillData,
  isLoading,
}: IProps) => {
  const navigate = useNavigate();

  const columns: IWillDetailColumn[] = [
    {
      name: '',
      center: true,
      selector: (row: IWillDetailRow) => row['actions'],
      width: '2.5rem',
      cell: (row: IWillDetailRow) => (
        <div className="mx-auto">
          <Link
            to={`/missing-will-searches/${row?.id}/view`}
            state={{ source: 'dashboard' }}
            title="View"
            className="btn btn-secondary btn-sm"
            key="view"
          >
            <i className="fa fa-eye"></i>
          </Link>
        </div>
      ),
      right: true,
    },
    {
      name: 'Searcher Name Deceased Name',
      selector: (row: IWillDetailRow) => row['searcher_name'],
      sortable: true,
      width: '10.6rem',
      cell: (row: IWillDetailRow) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '3px 0px',
          }}
        >
          <div>{row['searcher_name']}</div>
          <div style={{ color: 'gray' }}>{row['deceased_name']}</div>
        </div>
      ),
    },

    {
      name: 'Searcher Contact',
      selector: (row: IWillDetailRow) => row['searcher_contact'],
      sortable: true,
      width: '11rem',
      compact: true,
      cell: (row: IWillDetailRow) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '3px 0px',
          }}
        >
          <a
            href={`mailto:${row['searcher_email']}`}
            style={{ color: 'black', textDecoration: 'none' }}
          >
            {row['searcher_email']}
          </a>
          <div style={{ color: 'gray' }}>{row['searcher_phone']}</div>
        </div>
      ),
    },
    {
      name: 'Deceased Birth\n& Death Date',
      selector: (row: IWillDetailRow) => row['searcher_contact'],
      sortable: true,
      width: '11rem',
      cell: (row: IWillDetailRow) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '3px 0px',
          }}
        >
          <span className="white-space-pre">
            {moment
              .utc(moment(row['deceased_birth_date']))
              .local()
              ?.format('MMM DD, YYYY') ?? 'N/A'}
          </span>
          <span className="white-space-pre" style={{ color: 'grey' }}>
            {moment
              .utc(moment(row['deceased_death_date']))
              .local()
              ?.format('MMM DD, YYYY') ?? 'N/A'}
          </span>
        </div>
      ),
    },
    {
      name: 'Created At',
      selector: (row: IWillDetailRow) => row['created_at'],
      sortable: true,
      cell: (row: IWillDetailRow) => (
        <span className="white-space-pre">
          {moment
            .utc(new Date(row['created_at']))
            .local()
            ?.format('MMM DD, YYYY[\r\n]h:mm a')}
        </span>
      ),
      width: '8.3rem',
      right: true,
    },
  ];

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <PageTitle
          heading="Last Week Missing Will Searches"
          buttonName="View All"
          buttonClick={() => {
            navigate('/missing-will-searches');
          }}
          border={false}
        />
        <Card>
          <Card.Body className="dashboard-card">
            {!!missingWillData.data ? (
              <div className="dashboard-table">
                <DataTable
                  className="dt-table"
                  persistTableHead
                  fixedHeader
                  fixedHeaderScrollHeight="330px"
                  responsive
                  progressPending={isLoading}
                  keyField="id"
                  pagination
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5, ...PageOptions]}
                  striped
                  highlightOnHover
                  defaultSortFieldId="title"
                  columns={columns}
                  paginationTotalRows={missingWillData.total}
                  data={missingWillData.data}
                  customStyles={dtCustomStyle}
                />
              </div>
            ) : (
              <NotFound
                heading="Will Details Not Found!"
                subHeading="Please try again after sometime."
              />
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default MissingWillSearchDashboardListing;
