import { toast } from 'react-toastify';

export const toastWarnMessage = (message: string) => {
  toast.warn(message);
};

export const toastErrorMessage = (message: string) => {
  toast.error(message, {
    theme: 'colored',
  });
};

export const toastInfoMessage = (message: string) => {
  toast.info(message);
};

export const toastSuccessMessage = (message: string) => {
  toast.success(message);
};
