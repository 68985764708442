import { useCallback, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { Form, Col } from 'react-bootstrap';
import _debounce from 'lodash/debounce';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { OptionType } from '../../datatypes/CommonTypes';
import ErrorMessage from './ErrorMessage';
import useCharityApi from '../../api/charity.api';

const CharitySelectInput = ({
  inputName,
  labelText,
  handleCharityChange,
  placeholder = 'Select Charity',
  error,
  mdColumnSize = 4,
  isRequired = false,
}: any): any => {
  const [options, setOptions] = useState<OptionType<string | number>[]>([]);
  const [charities, setCharities] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<
    OptionType<string | number> | null | undefined
  >(undefined);

  const { searchCharitiesFromCharityNavigator } = useCharityApi();

  const handleSelectInput = (option: any) => {
    setSelectedOption(option);
    const charity =
      option?.value?.length === 9
        ? charities.find((o: any) => o.ein === option.value)
        : { name: option?.label ?? '' };

    const zipcode =
      charity?.zip?.length === 5
        ? charity?.zip
        : charity?.zip?.substring(0, 5) ?? '';

    handleCharityChange({
      charity_name: charity?.name,
      ein_number: charity?.ein ?? '',
      address_1: charity?.street ?? '',
      address_2: charity?.street2 ?? '',
      city: charity?.city ?? '',
      state_abbr: charity?.state ?? '',
      zipcode: zipcode,
      country: charity?.country,
    });
  };

  const loadOptions = async (
    value: string | undefined
  ): Promise<OptionType<string | number>[]> => {
    if (value && value.length > 2) {
      try {
        const data = await searchCharitiesFromCharityNavigator(value);
        if (data?.length > 0) {
          const newOptions = data.map((o: any) => ({
            value: o.ein,
            label: o.name,
          }));
          setOptions(newOptions);
          setCharities(data);
          return newOptions;
        }
        return [];
      } catch (error) {
        console.error('Error fetching charity options:', error);
        return [];
      }
    }
    setCharities([]);
    setOptions([]);
    return Promise.resolve([]);
  };

  const debouncedLoadOptions = useCallback(
    _debounce(
      (
        inputValue: string,
        callback: (options: OptionType<string | number>[]) => void
      ) => {
        loadOptions(inputValue).then(callback);
      },
      300
    ),
    []
  );

  const handleCreate = (inputValue: string) => {
    const newOption: OptionType<string | number> = {
      value: nanoid(),
      label: inputValue,
    };
    setOptions([newOption]);
    setSelectedOption(newOption);
    handleSelectInput(newOption);
  };

  const formatCreateLabel = (inputValue: string) => {
    if (inputValue.length > 2) {
      return `Select "${inputValue}" as Other Charity`;
    }
  };
  return (
    <Form.Group as={Col} md={mdColumnSize} className="mb-3 p-0">
      {labelText && (
        <div className="instruction-title">
          <Form.Label>
            {labelText}
            {isRequired && <span className="red-required">*</span>}
          </Form.Label>
        </div>
      )}
      <AsyncCreatableSelect
        classNamePrefix="fow-select"
        name={inputName}
        formatCreateLabel={formatCreateLabel}
        isSearchable={true}
        isClearable={true}
        cacheOptions
        defaultOptions={options}
        loadOptions={debouncedLoadOptions}
        onCreateOption={handleCreate}
        onChange={handleSelectInput}
        value={selectedOption}
        placeholder={placeholder}
      />
      {error && <ErrorMessage errorMessage={error} />}
    </Form.Group>
  );
};

export default CharitySelectInput;
