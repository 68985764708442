const ErrorMessage = (props: any) => {
  return (
    <>
      <div className="will-registration-error-message required">
        <i className="fa fa-exclamation-triangle"></i>{' '}
        <span>{props.errorMessage}</span>
      </div>
    </>
  );
};
export default ErrorMessage;
