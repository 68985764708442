import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useCharityPartnershipApi from '../../api/charityPartnership.api';
import { ICharityDetails } from '../../datatypes/Charity';
import { ICharityPartnershipsPayload } from '../../datatypes/CharityPartnership';

//initial redux slice state
const initialState: ICharityPartnershipsPayload = {
  charityPartnershipList: {
    loading: false,
    data: {
      data: [],
      current_page: 0,
      total: 0,
    },
    error: false,
  },
  charityPartnershipDetails: {
    loading: false,
    error: false,
    success: false,
    data: null,
  },
  saveUpdateCharityPartnership: {
    success: false,
    loading: false,
    error: false,
  },
};

export const charityPartnershipSlice = createSlice({
  name: 'charityPartnerships',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetCharityPartnershipData: (state) => {
      state.charityPartnershipDetails = initialState.charityPartnershipDetails;
      state.saveUpdateCharityPartnership =
        initialState.saveUpdateCharityPartnership;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchCharityPartnershipList.pending, (state) => {
        state.charityPartnershipList.loading = true;
      })
      .addCase(fetchCharityPartnershipList.fulfilled, (state, { payload }) => {
        state.charityPartnershipList.data.data =
          payload?.charity_partnerships ?? [];
        state.charityPartnershipList.data.total = payload?.total ?? 0;
        state.charityPartnershipList.data.current_page =
          payload?.current_page ?? 0;
        state.charityPartnershipList.loading = false;
      })
      .addCase(fetchCharityPartnershipList.rejected, (state) => {
        state.charityPartnershipList.loading = false;
        state.charityPartnershipList.error = true;
      })
      .addCase(saveUpdateCharityPartnershipDetails.pending, (state) => {
        state.saveUpdateCharityPartnership.loading = true;
        state.saveUpdateCharityPartnership.success = false;
        state.saveUpdateCharityPartnership.error = false;
      })
      .addCase(saveUpdateCharityPartnershipDetails.fulfilled, (state) => {
        state.saveUpdateCharityPartnership.success = true;
        state.saveUpdateCharityPartnership.loading = false;
        state.saveUpdateCharityPartnership.error = false;
      })
      .addCase(saveUpdateCharityPartnershipDetails.rejected, (state) => {
        state.saveUpdateCharityPartnership.loading = false;
        state.saveUpdateCharityPartnership.success = false;
        state.saveUpdateCharityPartnership.error = true;
      })
      .addCase(fetchCharityPartnershipDetails.pending, (state) => {
        state.charityPartnershipDetails.loading = true;
        state.charityPartnershipDetails.error = false;
        state.charityPartnershipDetails.success = false;
      })
      .addCase(
        fetchCharityPartnershipDetails.fulfilled,
        (state, { payload }) => {
          state.charityPartnershipDetails.data = payload;
          state.charityPartnershipDetails.loading = false;
          state.charityPartnershipDetails.success = true;
          state.charityPartnershipDetails.error = false;
        }
      )
      .addCase(fetchCharityPartnershipDetails.rejected, (state) => {
        state.charityPartnershipDetails.loading = false;
        state.charityPartnershipDetails.error = true;
        state.charityPartnershipDetails.success = false;
      });
  },
});

// Async API Hooks
/**
 * Fetch Charity List from API and save in redux store
 */
export const fetchCharityPartnershipList = createAsyncThunk(
  'charity-partnership/fetchCharityPartnershipList',
  async (data: any, { rejectWithValue }) => {
    for (const key in data.params) {
      if (data.params[key] === '') {
        delete data.params[key];
      }
    }
    const response = await useCharityPartnershipApi().getCharityPartnerships(
      data.params
    );
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response;
    } else {
      return rejectWithValue(response?.data);
    }
  }
);

/**
 * Fetch Charity Details from API and save in redux store
 * @param id
 */
export const fetchCharityPartnershipDetails = createAsyncThunk(
  'charities/fetchCharityPartnershipDetails',
  async (id: string, { rejectWithValue }) => {
    const response =
      await useCharityPartnershipApi().getCharityPartnershipDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response?.charity_partnership;
    } else {
      return rejectWithValue(response?.data);
    }
  }
);

/**
 * Save / Update Charity Details to API and save in redux store
 * @package data
 */
export const saveUpdateCharityPartnershipDetails = createAsyncThunk(
  'charities/saveUpdateCharityPartnershipDetails',
  async (data: ICharityDetails, { rejectWithValue }) => {
    const id = data?.id;
    if (data['id']) {
      delete data['id'];
    }
    if (typeof data['logo'] !== 'object') {
      delete data['logo'];
    }
    const response = id
      ? await useCharityPartnershipApi().updateCharityPartnershipDetails(
          data,
          String(id)
        )
      : await useCharityPartnershipApi().saveCharityPartnershipDetails(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response?.data;
    } else {
      return rejectWithValue(response?.data);
    }
  }
);

export const { resetCharityPartnershipData } = charityPartnershipSlice.actions;

export default charityPartnershipSlice.reducer;
