import { dtCustomStyle } from '../../helpers/DataTableHelper';
import { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useAppDispatch } from '../../redux/hooks';
import DeleteModal from './DeleteModal';
import { PageOptions } from '../../constants/Common';
import {
  IShowDeleteLivingWillModal,
  ILivingWillFormRow,
  ILivingWillFormPayload,
  ILivingWillFormColumn,
} from '../../datatypes/LivingWillForm';
import { deleteLivingWillForm } from '../../redux/slices/livingWillFormSlice';
import { Link } from 'react-router-dom';

interface IProps {
  livingWillList: ILivingWillFormPayload['formList'];
  deleteFormDetail: ILivingWillFormPayload['deleteFormStatus'];
  showDeleteModal: IShowDeleteLivingWillModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeleteLivingWillModal>
  >;
}

const Listing = ({
  livingWillList,
  deleteFormDetail,
  showDeleteModal,
  setShowDeleteModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  // switch for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteLivingWillModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  //selected living will
  const selectedLivingWillId = (rows: ILivingWillFormRow[]) => {
    setShowDeleteModal((prev: IShowDeleteLivingWillModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  // handle delete living will
  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((formDetails: ILivingWillFormRow) => formDetails.id)
      .join(',');
    dispatch(deleteLivingWillForm(selectedIds));
  };

  // handle delete open modal
  const openDeleteModal = (row: ILivingWillFormRow) => {
    selectedLivingWillId([row]);
    toggleDeleteModal();
  };

  //columns for listing
  const columns: ILivingWillFormColumn[] = [
    {
      id: 'state',
      name: 'State',
      selector: (row: ILivingWillFormRow) => row.state_name,
      sortable: true,
      width: '9.5rem',
    },
    {
      id: 'form_name',
      name: 'State PDF',
      selector: (row: ILivingWillFormRow) => row.page_title,
      sortable: true,
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: ILivingWillFormRow) => row.actions,
      center: true,
      compact: true,
      width: '8.5rem',
      cell: (row: ILivingWillFormRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <Link
            className="btn btn-secondary btn-sm"
            to={`/living-will-forms/${row?.id}/view`}
            key="view"
          >
            <i className="fa fa-eye"></i>
          </Link>
          <Link
            className="btn btn-primary btn-sm"
            to={`/living-will-forms/${row.id}/edit`}
            key="edit"
          >
            <i className="fa fa-pencil"></i>
          </Link>
          <Button
            className="btn btn-danger btn-sm"
            key="delete"
            onClick={() => openDeleteModal(row)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
  ];

  // handle delete modal close on success delete
  useEffect(() => {
    if (deleteFormDetail?.success) {
      setShowDeleteModal((prev: IShowDeleteLivingWillModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteFormDetail?.success]);
  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={livingWillList.loading}
              keyField="id"
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={livingWillList.data?.total}
              data={livingWillList.data?.data}
              customStyles={dtCustomStyle}
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteLivingWillFormDetail={deleteFormDetail}
        />
      </div>
    </div>
  );
};

export default Listing;
