import { ValidateField } from '../../helpers/ValidatorHelper';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import { useEffect, useMemo, useState } from 'react';
import _find from 'lodash/find';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  templateCharityDetailsField,
  charityDetailsValidations,
  optionsCharityStatus,
} from '../../constants/Charity';
import { IRouteParams } from '../../datatypes/CommonTypes';
import { ICharityDetails, ICharityPayload } from '../../datatypes/Charity';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  fetchCharities,
  fetchCharityCategories,
} from '../../redux/slices/charitySlice';
import _forEach from 'lodash/forEach';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import { fetchStates } from '../../redux/slices/locationSlice';
import SelectInput from '../../components/common/SelectInput';
import RadioGroupInput from '../../components/common/RadioGroupInput';
import FileInput from '../../components/common/FileInput';
import { ILocationPayload } from '../../datatypes/Location';
import CharitySelectInput from '../../components/common/CharitySelectInput';
import CleaveInput from '../../components/common/CleaveInput';
import TextInput from '../../components/common/TextInput';
import {
  fetchCharityPartnershipDetails,
  resetCharityPartnershipData,
  saveUpdateCharityPartnershipDetails,
} from '../../redux/slices/charityPartnershipSlice';
import { ICharityPartnershipsPayload } from '../../datatypes/CharityPartnership';
import { isEmpty } from 'lodash';
import TextareaInput from '../../components/common/TextareaInput';

type CustomCharityDataType = {
  charity_name?: string;
  ein_number: string;
  address_1?: string;
  address_2?: string;
  city: string;
  state?: string;
  zipcode: string;
  country: string;
};

const CharityPartnershipDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id }: IRouteParams = useParams();
  const location = useLocation();

  const { charityDetails, charityCategoryList } =
    useAppSelector<ICharityPayload>((state) => state.charities);

  const { charityPartnershipDetails, saveUpdateCharityPartnership } =
    useAppSelector<ICharityPartnershipsPayload>(
      (state) => state.charityPartnerships
    );

  const { data: stateOptions, stateLoading } = useAppSelector<
    ILocationPayload['states']
  >((state) => state.location.states);

  const { charityList } = useAppSelector<ICharityPayload>(
    (state) => state.charities
  );

  const [charityExist, setCharityExist] = useState(false);
  const [charityType, setCharityType] = useState('new');
  const [filePreview, setFilePreview] = useState<string>('');

  const [customCharityData, setCustomCharityData] =
    useState<CustomCharityDataType | null>(null);

  const [formData, setFormData] = useState<ICharityDetails>({
    ...templateCharityDetailsField,
  });

  const [errors, setErrors] = useState<ICharityDetails>({
    ...templateCharityDetailsField,
    status: '',
  });
  const validations = useMemo(() => {
    return {
      ...charityDetailsValidations,
    };
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const allowedFileExtensions = ['.png', '.jpeg', '.jpg'];

      // Get the file extension from the file name
      const fileExtension = file.name.substring(file.name.lastIndexOf('.'));

      if (allowedFileExtensions.includes(fileExtension.toLowerCase())) {
        const reader = new FileReader();

        //create and set file preview path
        reader.onload = (event) => {
          if (event.target && typeof event.target.result === 'string') {
            setFilePreview(event.target.result);
          }
        };

        reader.readAsDataURL(file);

        handleInputs(e);
      } else {
        setFormData((prevData) => ({
          ...prevData,
          logo: '',
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          logo: 'Please upload a valid file type (jpeg, jpg, png).',
        }));
        setFilePreview('');
      }
    }
  };

  const handleInputs = (e: any) => {
    const name = e.target.name;
    let value: string | boolean;
    let error: string | undefined | null;
    //get value from checkbox / others
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    const fieldName =
      name !== 'state_id' && name !== 'county_id' && name !== 'category_id'
        ? name
        : name === 'state_id'
        ? 'State'
        : name === 'category_id'
        ? 'Category'
        : 'County';

    if (name !== 'logo') {
      //check for errors
      error = ValidateField(fieldName, value, validations[name]);

      //set errors
      if (error) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      }

      //update form Data
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    } else {
      //input handling for file
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      setFormData((prevData) => ({ ...prevData, [name]: e.target.files[0] }));
    }

    //handle status upate
    if (name === 'status' && charityType !== 'edit') {
      //set status
      setFormData((prevData) => ({ ...prevData, category_id: '' }));

      // Empty category and validation
      error = ValidateField('Category', null, validations['category_id']);
      setErrors((prevErrors) => ({
        ...prevErrors,
        category_id: String(error),
      }));

      //trigger dispatch to fetch category list
      dispatch(fetchCharityCategories(String(value)));
    }

    //handle state id update
    if (name === 'state_id') {
      setFormData((prevData) => ({ ...prevData, county_id: null }));
      error = ValidateField('County', null, validations['county_id']);
      setErrors((prevErrors) => ({ ...prevErrors, county_id: String(error) }));
    }
  };

  const hasFormError = () => {
    let hasErrors = false;
    _forEach(formData, (value, key) => {
      const fieldName =
        key !== 'state_id' && key !== 'county_id' && key !== 'category_id'
          ? key
          : key === 'state_id'
          ? 'State'
          : key === 'category_id'
          ? 'Category'
          : 'County';
      const error = ValidateField(fieldName, value, validations[key]);
      if (error) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
        hasErrors = true;
      }
    });
    return hasErrors;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!hasFormError()) {
      let payload = {
        ...formData,
        status: formData.status === 'active' ? 'active' : 'inactive',
      };
      dispatch(saveUpdateCharityPartnershipDetails(payload));
    }
  };

  // Component to render Edit button in page title if currently in view state.
  const EditButtonComponent = () => {
    if (charityType === 'view') {
      return (
        <Button
          variant="primary"
          type="button"
          onClick={() => navigate(`/charities/${id}/edit`)}
        >
          Edit
        </Button>
      );
    } else {
      return null;
    }
  };

  const handleCharityChange = (charityData: any) => {
    setErrors({
      ...templateCharityDetailsField,
      status: '',
    });
    setFormData(templateCharityDetailsField);
    if (!charityData.charity_name) {
      setCharityExist(false);
      setCustomCharityData(null);
    } else {
      const ein_number =
        charityData.ein_number.slice(0, 2) +
        '-' +
        charityData.ein_number.slice(2);

      setCustomCharityData((pre) => {
        if (!pre) {
          return {
            charity_name: charityData.charity_name,
            ein_number: ein_number,
            address_1: `${charityData.address_1}`,
            address_2: `${charityData.address_2 ?? ''}`,
            city: charityData.city,
            state: charityData.state_abbr,
            zipcode: charityData.zipcode,
            country: charityData.country,
          };
        }
        return {
          ...pre,
          charity_name: charityData.charity_name,
          ein_number: ein_number,
          address_1: `${charityData.address_1}`,
          address_2: `${charityData.address_2 ?? ''}`,
          city: charityData.city,
          state: charityData.state_abbr,
          zipcode: charityData.zipcode,
        };
      });
      const payload = {
        ein_number: ein_number,
      };
      dispatch(fetchCharities({ params: payload }));
    }
  };

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(fetchCharityPartnershipDetails(id));

      const containsView = location.pathname.includes('view');
      if (containsView) {
        setCharityType('view');
      } else {
        setCharityType('edit');
      }
    } else {
      setFormData(templateCharityDetailsField);
      dispatch(fetchCharityCategories('active'));
    }
    dispatch(fetchStates());
  }, [id, location.pathname, dispatch]);

  useEffect(() => {
    if (charityType === 'new') {
      const fieldsToCheck = [
        'category_id',
        'description',
        'logo',
        'status',
        'state_id',
        'address_1',
        'address_2',
        'city',
        'zipcode',
        'name',
        'ein_number',
      ];
      if (!isEmpty(charityList?.data.data)) {
        setCharityExist(true);
        Object.entries(charityList?.data.data[0]).forEach(([key, value]) => {
          if (key === 'logo') {
            setFilePreview(value);
          } else if (key === 'status') {
            dispatch(fetchCharityCategories(value));
          } else if (fieldsToCheck.includes(key)) {
            setFormData((prevData) => ({
              ...prevData, // spread the previous state
              [key]: value,
            }));
          }
          setErrors({ ...templateCharityDetailsField, status: '' });
        });
      } else {
        const state = _find(stateOptions, {
          state_abbr: customCharityData?.state,
        });
        if (!charityList?.loading) {
          setCharityExist(false);
          setFilePreview('');
          setFormData((prevData) => ({
            ...prevData, // spread the previous state
            ...fieldsToCheck.reduce((acc: any, field) => {
              if (field !== 'status') {
                acc[field] = ''; // set each specified field to null
                return acc;
              }
              return acc;
            }, {}),
          }));
          setFormData((prevData) => ({
            ...prevData,
            name: customCharityData?.charity_name ?? '',
            ein_number: customCharityData?.ein_number ?? '',
            address_1: customCharityData?.address_1 ?? '',
            address_2: customCharityData?.address_2 ?? '',
            city: customCharityData?.city ?? '',
            zipcode: customCharityData?.zipcode ?? '',
          }));
          if (state) {
            setFormData((prevData) => ({ ...prevData, state_id: state.id }));
          }
        }
      }
    }
  }, [charityList]);

  useEffect(() => {
    if (charityPartnershipDetails.success) {
      var ein_number: string = '';
      if (charityPartnershipDetails?.data?.charity_details.ein_number) {
        ein_number =
          charityPartnershipDetails?.data?.charity_details?.ein_number.slice(
            0,
            2
          ) +
          '-' +
          charityPartnershipDetails?.data?.charity_details?.ein_number.slice(2);
      }
      const charityData: ICharityDetails = {
        id: charityPartnershipDetails?.data?.id ?? '',
        name: charityPartnershipDetails?.data?.charity_details?.name ?? '',
        description:
          charityPartnershipDetails?.data?.charity_details?.description ?? '',
        category_id:
          charityPartnershipDetails?.data?.charity_details?.category_id ?? '',
        state_id:
          charityPartnershipDetails?.data?.charity_details?.state_id ?? '',
        status: charityPartnershipDetails?.data?.status ?? '',
        logo: charityPartnershipDetails?.data?.charity_details?.logo ?? '',
        ein_number: ein_number ?? '',
        address_1:
          charityPartnershipDetails?.data?.charity_details?.address_1 ?? '',
        address_2:
          charityPartnershipDetails?.data?.charity_details?.address_2 ?? '',
        city: charityPartnershipDetails?.data?.charity_details?.city ?? '',
        zipcode:
          charityPartnershipDetails?.data?.charity_details?.zipcode ?? '',
      };

      setCustomCharityData((prev) => ({
        ...prev,
        charity_name: charityData?.name,
        ein_number: ein_number,
        address: `${charityData.address_1} ${
          charityData.address_2 ?? ''
        }`.trim(),
        city: charityData?.city ?? '',
        zipcode: charityData?.zipcode ?? '',
        country: '',
      }));
      setFilePreview(String(charityData?.logo));
      setFormData({ ...charityData });
      dispatch(
        fetchCharityCategories(
          charityPartnershipDetails?.data?.charity_details?.status ?? 'active'
        )
      );
    } else if (charityDetails.error) {
      navigate('/partnerships');
    }
    // eslint-disable-next-line
  }, [charityPartnershipDetails]);

  useEffect(() => {
    if (saveUpdateCharityPartnership.success) {
      toastSuccessMessage(
        `Charity partnership ${
          charityType === 'new' ? 'created' : 'updated'
        } successfully`
      );
      dispatch(resetCharityPartnershipData());
      navigate('/partnerships');
    }
  }, [saveUpdateCharityPartnership]);

  return (
    <div className="charity-details profile-details">
      <PageTitle
        heading={
          charityType === 'new'
            ? 'New Charity Partnership'
            : ` Charity partnerships : ${
                charityPartnershipDetails?.data?.charity_details?.name ?? ''
              }`
        }
        buttonName="Listing"
        buttonClick={() => {
          dispatch(resetCharityPartnershipData());
          navigate('/partnerships');
        }}
        customButton={<EditButtonComponent />}
      />

      {charityPartnershipDetails.loading ? (
        <Loader isFull />
      ) : !!charityDetails ? (
        <Card>
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg={4} md={12} className="profile-pic-logo-section">
                  {filePreview ? (
                    <div className="profile-pic-logo-wrapper">
                      <img
                        src={filePreview}
                        className="profile-pic-logo-image"
                        alt="Profile Pic"
                      />
                    </div>
                  ) : (
                    <div className="profile-pic-logo-image-NA">
                      Image Not Available
                    </div>
                  )}
                </Col>
                <Col lg={8} md={12}>
                  <Row>
                    <Col lg={12} md={12}>
                      {charityType !== 'edit' ? (
                        <CharitySelectInput
                          inputName="name"
                          labelText="Charity Name"
                          handleCharityChange={handleCharityChange}
                          isRequired={true}
                          mdColumnSize={12}
                          error={errors?.name ?? ''}
                          placeholder="Start typing charity name(min 3 characters)"
                          value={charityType === 'edit' ? formData?.name : ''}
                          isDisabled={charityType === 'edit'}
                        />
                      ) : (
                        <TextInput
                          isRequired={true}
                          name="name"
                          label="Charity Name"
                          placeholder="Charity Name"
                          value={formData?.name ?? ''}
                          onChange={(e) => handleInputs(e)}
                          isDisabled={charityType === 'edit'}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={12}>
                      <RadioGroupInput
                        name="status"
                        value={formData.status}
                        label="Status"
                        options={
                          charityType !== 'edit'
                            ? charityList?.data.data[0]?.status === 'active'
                              ? optionsCharityStatus.filter(
                                  (option) => option.value === 'active'
                                )
                              : charityList?.data.data[0]?.status === 'inactive'
                              ? optionsCharityStatus.filter(
                                  (option) => option.value === 'inactive'
                                )
                              : optionsCharityStatus
                            : optionsCharityStatus
                        }
                        onChange={(e) => handleInputs(e)}
                        isRequired={true}
                        errorMessage={String(errors?.status)}
                        controlId="status"
                        isDisabled={
                          charityType !== 'edit' &&
                          !isEmpty(charityList?.data.data[0]?.status)
                        }
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      {!charityList?.data.data[0]?.logo &&
                        charityType !== 'edit' && (
                          <FileInput
                            controlId="logo"
                            label="Logo"
                            className="form-field"
                            placeholder="Logo"
                            name="logo"
                            value={formData.logo}
                            onChange={(e) => handleFileChange(e)}
                            errorMessage={String(errors?.logo)}
                            isRequired={true}
                          />
                        )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={12}>
                      <CleaveInput
                        placeholder="e.g. 12-65698684"
                        name="ein_number"
                        isDisabled={charityType === 'edit' || charityExist}
                        options={{
                          blocks: [2, 7],
                          delimiter: '-',
                        }}
                        label="EIN Number"
                        isRequired
                        value={formData?.ein_number ?? ''}
                        onChange={(e) => handleInputs(e)}
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      <TextInput
                        isRequired={false}
                        name="address_1"
                        label="Address"
                        placeholder="Address"
                        value={
                          formData?.address_1
                            ? formData?.address_1 + (formData?.address_2 ?? '')
                            : ''
                        }
                        onChange={(e) => handleInputs(e)}
                        isDisabled={
                          charityType === 'edit' ||
                          !isEmpty(charityList?.data.data[0]?.address_1)
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={12}>
                      <TextInput
                        isRequired={true}
                        name="city"
                        label="City"
                        placeholder="City"
                        value={formData?.city ?? ''}
                        onChange={(e) => handleInputs(e)}
                        errorMessage={errors?.city ?? ''}
                        isDisabled={
                          charityType === 'edit' ||
                          !isEmpty(charityList?.data.data[0]?.city)
                        }
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      <SelectInput
                        name="state_id"
                        placeholder="Select State"
                        value={formData.state_id}
                        optionLabel="state_name"
                        optionValue="id"
                        options={stateOptions}
                        isLoading={stateLoading}
                        handleSelectChange={(e) => handleInputs(e)}
                        label="State"
                        isRequired={true}
                        errorMessage={String(errors?.state_id)}
                        isDisabled={charityType === 'edit' || charityExist}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={12}>
                      <TextInput
                        isRequired={true}
                        name="zipcode"
                        label="Zipcode"
                        placeholder="Zipcode"
                        value={formData?.zipcode ?? ''}
                        onChange={(e) => handleInputs(e)}
                        errorMessage={errors?.zipcode ?? ''}
                        isDisabled={
                          charityType === 'edit' ||
                          !isEmpty(charityList?.data.data[0]?.zipcode)
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12}>
                      <SelectInput
                        name="category_id"
                        placeholder="Select Category"
                        value={formData.category_id}
                        optionLabel="name"
                        optionValue="id"
                        options={charityCategoryList?.data?.data ?? []}
                        isLoading={charityCategoryList?.loading}
                        handleSelectChange={(e) => handleInputs(e)}
                        label="Charity Category"
                        isRequired={true}
                        errorMessage={String(errors?.category_id)}
                        isDisabled={
                          charityType === 'edit' ||
                          !isEmpty(charityList?.data.data[0]?.status)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} md={12}>
                  <TextareaInput
                    isRequired={true}
                    name="description"
                    label="Description"
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.description ?? ''}
                    isDisabled={
                      charityType === 'edit' ||
                      !isEmpty(charityList?.data.data[0]?.description)
                    }
                    value={formData.description ?? ''}
                    controlId="description"
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              {charityType !== 'view' && (
                <Button variant="primary" type="submit">
                  {charityType === 'new' ? 'Save' : 'Update'}
                </Button>
              )}
              <Button
                onClick={() => {
                  dispatch(resetCharityPartnershipData());
                  navigate('/partnerships');
                }}
                className="button-link secondary"
                type="submit"
              >
                Cancel
              </Button>
            </Card.Footer>
          </Form>
        </Card>
      ) : (
        <NotFound
          heading="Charity Not Found"
          subHeading="Please try again after sometime"
        />
      )}
    </div>
  );
};

export default CharityPartnershipDetails;
