import { toastSuccessMessage } from '../../components/common/ToastMessage';
import PageTitle from '../../components/common/PageTitle';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import {
  IShowDeleteQuestionModal,
  IQuestionPayload,
} from '../../datatypes/Question';
import {
  fetchQuestions,
  resetQuestionsDetails,
} from '../../redux/slices/questionSlice';
import Listing from '../../components/questions/Listing';

const NewQuestionListing = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { questionList, deleteQuestionStatus } =
    useAppSelector<IQuestionPayload>((state) => state.questions);

  const [showDeleteModal, setShowDeleteModal] =
    useState<IShowDeleteQuestionModal>({
      show: false,
      idDetails: [],
    });

  const getQuestionsListData = () => {
    dispatch(fetchQuestions());
  };

  useEffect(() => {
    getQuestionsListData();
    // eslint-disable-next-line
  }, []);

  //handle successful delete api call and modal closing
  useEffect(() => {
    if (deleteQuestionStatus.success) {
      toastSuccessMessage('Question deleted successfully!');
      setShowDeleteModal({
        show: false,
        idDetails: [],
      });
      dispatch(resetQuestionsDetails());
      getQuestionsListData();
    }
    // eslint-disable-next-line
  }, [deleteQuestionStatus]);

  return (
    <div className="questions-listing">
      <PageTitle
        heading="Questions"
        buttonName="New"
        buttonClick={() => {
          dispatch(resetQuestionsDetails());
          navigate('/questions/new');
        }}
      />
      <div className="questions-listing-content">
        <Listing
          questionsList={questionList}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deleteQuestionDetail={deleteQuestionStatus}
        />
      </div>
    </div>
  );
};

export default NewQuestionListing;
