import { Navbar, Nav } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom'; // Use Link instead of navigate
import Navigations from './adminNav';

const TheSidebar = () => {
  const location = useLocation();
  const activeRoute = '/' + location.pathname.split('/')[1];
  const navigate = useNavigate();
  return (
    <div className="sidebar sidebar-fixed">
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand>
          <span className="ap-logo">Free Online Will</span>
          <span className="ap-text">Admin Panel</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav activeKey={activeRoute} className="mr-auto">
            {Navigations.map((route, i) => {
              if (route._tag === 'item') {
                return (
                  <Nav.Link
                    key={i} // Use Link instead of custom click handler
                    onClick={() => {
                      navigate(String(route.to));
                    }}
                    className={`nav-item ${
                      route.to === activeRoute ? 'active' : ''
                    }`}
                  >
                    <i className={route.icon}></i>
                    <span>{route.name}</span>
                  </Nav.Link>
                );
              } else {
                return (
                  <span key={i} className="nav-title">
                    {route.name}
                  </span>
                );
              }
            })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default TheSidebar;
