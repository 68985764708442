import { OptionType } from '../datatypes/CommonTypes';
import { IFaqDetails, IFaqSearch, IFaqValidations } from '../datatypes/Faq';

// initial state for form and error
export const templateFaqDetailsField: IFaqDetails = {
  question_text: '',
  answer_text: '',
  type: '',
  status: '',
};

// validations for form
export const faqDetailsValidations: IFaqValidations = {
  question_text: 'required|min:2',
  answer_text: 'required|min:2',
  type: 'max:50',
};

// template for terminlogy search
export const searchFaqField: IFaqSearch = {
  question_text: '',
  answer_text: '',
  type: '',
};

export const faqSearchValidations: IFaqValidations = {
  question_text: 'max:50',
  answer_text: 'max:255',
  type: 'max:50',
  status: 'required',
};

export const statusOptions: OptionType<string>[] = [
  {
    label: 'Active',
    value: 'active',
    disabled: false,
    color: 'btn-success',
  },
  {
    label: 'Inactive',
    value: 'inactive',
    disabled: false,
    color: 'btn-secondary',
  },
];

export const typeOptions: OptionType<string>[] = [
  {
    label: 'General',
    value: 'miscellaneous',
  },
  {
    label: 'Create a Will',
    value: 'create_will',
  },
  {
    label: 'Find a Missing Will',
    value: 'find_missing_will',
  },
  {
    label: 'Register a Will',
    value: 'will_registration',
  },
];
