import { useEffect, useState } from 'react';
import PageTitle from '../../components/common/PageTitle';
import SearchForm from '../../components/will-creators/SearchForm';
import Listing from '../../components/will-creators/Listing';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  exportWillCreators,
  fetchWillCreators,
  resetWillCreatorData,
} from '../../redux/slices/willCreatorSlice';
import {
  willCreatorsPagination,
  initialWillCreatorSearchField,
  willCreatorSearchFieldValidations,
  initialWillCreatorExportFields,
  willCreatorExportFieldValidations,
} from '../../constants/WillCreator';
import { ValidateField } from '../../helpers/ValidatorHelper';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import {
  IWillCreatorPayload,
  IShowDeleteWillCreatorModal,
  IWillCreatorPagination,
  ICharity,
} from '../../datatypes/WillCreator';
import FowButton from '../../components/common/FowButton';
import ExportForm from 'src/components/will-creators/ExportForm';
import { downloadCSV, formatNumber } from 'src/helpers/common';
import _startCase from 'lodash/startCase';
import moment from 'moment';
import { ISortParams } from 'src/datatypes/CommonTypes';

const WillCreatorsListing = () => {
  const [searchWill, setSearchWill] = useState({
    ...initialWillCreatorSearchField,
  });

  const [exportWill, setExportWill] = useState({
    ...initialWillCreatorExportFields,
  });
  const [lastValidSearch, setLastValidSearch] = useState({
    ...initialWillCreatorSearchField,
  });
  const [errors, setErrors] = useState({ ...initialWillCreatorSearchField });
  const [exportErrors, setExportErrors] = useState({
    ...initialWillCreatorSearchField,
  });
  const [showAdvanceSearch, setShowAdvanceSearch] = useState({
    show: false,
    filterApplied: false,
  });
  const [showExportFilter, setShowExportFilter] = useState({
    show: false,
    filterApplied: false,
  });

  const [pagination, setPagination] = useState<IWillCreatorPagination>(
    willCreatorsPagination
  );
  const [sort, setSort] = useState<ISortParams>({
    orderBy: 'id',
    order: 'desc',
  });

  const [showDeleteModal, setShowDeleteModal] =
    useState<IShowDeleteWillCreatorModal>({
      show: false,
      idDetails: [],
    });

  const dispatch = useAppDispatch();

  const { willCreators, deleteWillCreatorStatus } =
    useAppSelector<IWillCreatorPayload>((state) => state.willStatus);

  const getWillCreatorsData = () => {
    dispatch(fetchWillCreators({ params: { ...pagination, ...sort } }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchWill((searchWill) => ({ ...searchWill, [name]: value }));

    if (name === 'state_id') {
      setSearchWill((searchWill) => ({ ...searchWill, county_id: '' }));
    }

    let tempValue: any = value;
    if (name === 'created_from') {
      tempValue = {
        value1: value,
        value2: searchWill.created_to,
      };
    } else if (name === 'created_to') {
      tempValue = {
        value1: value,
        value2: searchWill.created_from,
      };
    }

    const error =
      value === ''
        ? null
        : ValidateField(
            e.target.name,
            tempValue,
            willCreatorSearchFieldValidations?.[name]
          );

    setErrors((errors) => ({ ...errors, [name]: error || '' }));
  };

  const handleExportInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setExportWill((exportWill) => ({ ...exportWill, [name]: value }));

    if (name === 'state_id') {
      setExportWill((exportWill) => ({ ...exportWill, county_id: '' }));
    }
  };

  const toggleFilter = (filterType: string) => {
    if (filterType === 'advance') {
      setShowExportFilter({ ...showExportFilter, show: false });
      setShowAdvanceSearch({
        ...showAdvanceSearch,
        show: !showAdvanceSearch.show,
      });
    } else if (filterType === 'export') {
      setShowAdvanceSearch({ ...showAdvanceSearch, show: false });
      setShowExportFilter({
        ...showExportFilter,
        show: !showExportFilter.show,
      });
    }
  };

  const toggleFilterApplied = (
    filterType: 'advance' | 'export',
    isApplied: boolean
  ) => {
    if (filterType === 'advance') {
      setShowAdvanceSearch((prevState) => ({
        ...prevState,
        filterApplied: isApplied,
      }));
    } else if (filterType === 'export') {
      setShowExportFilter((prevState) => ({
        ...prevState,
        filterApplied: isApplied,
      }));
    }
  };

  const validateForm = () => {
    let hasValuesInSearchWill = false;

    if (Object.keys(searchWill).some((key) => searchWill[key] !== '')) {
      hasValuesInSearchWill = true;
    } else if (JSON.stringify(lastValidSearch) !== JSON.stringify(searchWill)) {
      hasValuesInSearchWill = true;
    }

    const hasErrors = Object.keys(errors).some((key) => errors[key] !== '');
    return !hasValuesInSearchWill || hasErrors;
  };

  const onWillSearch = () => {
    const hasError = validateForm();
    if (!hasError) {
      setLastValidSearch(searchWill);
      dispatch(
        fetchWillCreators({
          params: { ...searchWill, ...pagination, page: 1, ...sort },
        })
      );
      toggleFilterApplied('advance', true);
    }
  };

  const onExportWill = async () => {
    if (Object.values(exportErrors).some((error) => error !== '')) {
      return; // Exit if there are validation errors
    }

    const { charity, charity_ids, ...rest } = exportWill;

    if (charity !== 'specific_charity') {
      rest.contributors =
        charity === 'any_charity'
          ? 'yes'
          : charity === 'no_charity'
          ? 'no'
          : '';
    } else {
      rest.charity_ids = charity_ids;
    }

    const resultAction = await dispatch(
      exportWillCreators({ params: { ...rest } })
    );

    if (resultAction.payload.success) {
      const formattedData = prepareDataForExport(
        resultAction?.payload?.data ?? []
      );
      downloadCSV({
        data: formattedData,
        fileName: 'will-creators-' + moment().format('DD-MM-yyyy-hh-mm-ss'),
      });
    }
    toggleFilterApplied('export', true);
  };

  const prepareDataForExport = (data: any) => {
    const formatCharities = (charities: ICharity[]) => {
      return (
        charities?.reduce((result, charity) => {
          if (charity.charity_name) {
            const allocation = formatNumber(charity.portion, charity.unit);
            const charityInfo = `${charity.charity_name} (${charity.ein_number}) - [${allocation}]`;
            return result ? `${result}, ${charityInfo}` : charityInfo;
          }
          return result;
        }, '') ?? ''
      );
    };

    return data.map((row: any) => ({
      'Full Name': `${_startCase(row.first_name)}${
        row.middle_name ? ` ${_startCase(row.middle_name)}` : ''
      } ${_startCase(row.last_name)}`,
      Location: `${_startCase(row.city)}${row.state && ', ' + row.state}`,
      County: row.county_name,
      'Confirm Date': moment
        .utc(moment(row.confirm_date))
        .local()
        ?.format('MMM DD, YYYY'),
      'Marital Status': _startCase(row.marital_status) ?? '',
      'Spouse Name': _startCase(row.spouse_name) ?? '',
      'Has Children?': _startCase(row.has_children) ?? '',
      'Assets Value': row.assets_value ?? '',
      'Wants to Donate?': _startCase(row.want_to_donate) ?? '',
      Charities: formatCharities(row.charities) ?? '',
      'Has Left for charity?': _startCase(row.want_to_donate) ?? '',
      'Notify Charity?': _startCase(row.notify_charity) ?? '',
      'Has Made F.A.?': _startCase(row.has_made_funeral_arrangement) ?? '',
      'Req Fun Info?': _startCase(row.required_funeral_info) ?? '',
    }));
  };

  const resetWillSearch = () => {
    setLastValidSearch(initialWillCreatorSearchField);
    dispatch(fetchWillCreators({ params: { ...pagination, ...sort } }));
    toggleFilterApplied('advance', false);
    setSearchWill({ ...initialWillCreatorSearchField });
  };
  const resetExportWillFilter = () => {
    dispatch(resetWillCreatorData());
    toggleFilterApplied('export', false);
    setExportWill({ ...initialWillCreatorExportFields });
  };

  //handle successful delete api call and modal closing
  useEffect(() => {
    if (deleteWillCreatorStatus.success) {
      toastSuccessMessage('Will deleted successfully!');

      setShowDeleteModal({
        show: false,
        idDetails: [],
      });
      dispatch(resetWillCreatorData());
      getWillCreatorsData();
    }
    // eslint-disable-next-line
  }, [deleteWillCreatorStatus]);

  //handle page change
  useEffect(() => {
    dispatch(
      fetchWillCreators({
        params: { ...pagination, ...lastValidSearch, ...sort },
      })
    );
    // eslint-disable-next-line
  }, [pagination, sort]);

  //handle page change
  useEffect(() => {
    if (exportWill.charity === 'specific_charity') {
      const error = ValidateField(
        'charity',
        exportWill.charity_ids,
        willCreatorExportFieldValidations?.charity
      );
      setExportErrors((errors) => ({ ...errors, charity: error || '' }));
    } else {
      setExportErrors((errors) => ({ ...errors, charity: '' }));
    }
    // eslint-disable-next-line
  }, [exportWill]);

  return (
    <div className="will-status">
      <PageTitle heading="Will Creators" />
      <div className="will-status-content">
        <div className="control-top-button mb-2 d-flex justify-content-between">
          <FowButton
            variant="primary"
            className="mr-2"
            onClick={() => toggleFilter('advance')}
          >
            Advanced Search
          </FowButton>
          <FowButton
            variant="outline-primary"
            onClick={() => toggleFilter('export')}
          >
            <div className="d-flex align-items-center">
              <i className="fa fa-download mr-2" aria-hidden="true"></i>
              <span>Export</span>
            </div>
          </FowButton>
        </div>
        <div>
          {showAdvanceSearch.show && (
            <SearchForm
              errors={errors}
              handleInputChange={handleInputChange}
              searchWill={searchWill}
              onWillSearch={onWillSearch}
              resetWillSearch={resetWillSearch}
              isFilterApplied={showAdvanceSearch.filterApplied}
            />
          )}
          {showExportFilter.show && (
            <ExportForm
              errors={exportErrors}
              handleInputChange={handleExportInputChange}
              exportWill={exportWill}
              onExportWill={onExportWill}
              resetExportWillFilter={resetExportWillFilter}
              isFilterApplied={showExportFilter.filterApplied}
            />
          )}
          <Listing
            willCreators={willCreators}
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            deleteWillStatusDetail={deleteWillCreatorStatus}
            setPagination={setPagination}
            setSort={setSort}
          />
        </div>
      </div>
    </div>
  );
};

export default WillCreatorsListing;
