import { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

const withAuth = (Component) => (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const cookies = new Cookies();

  const [token, setToken] = useState(localStorage.getItem('access_token'));
  const [expirationTime, setExpirationTime] = useState(
    localStorage.getItem('expiration_time') ?? undefined
  );

  function getCurrentTimeInSeconds() {
    // Get the current time in milliseconds
    const currentTimeInMillis = new Date().getTime();

    // Convert milliseconds to seconds
    const currentTimeInSeconds = Math.floor(currentTimeInMillis / 1000);

    return currentTimeInSeconds;
  }

  useEffect(() => {
    const currentToken = localStorage.getItem('access_token');
    const currentExpirationTime = localStorage.getItem('expiration_time');
    const currentTime = getCurrentTimeInSeconds();
    const expirationTimestamp = parseInt(currentExpirationTime);
    const timeToExpire = expirationTimestamp - currentTime;

    if (
      currentToken &&
      currentExpirationTime &&
      location.pathname !== '/login'
    ) {
      // Set a timeout to redirect to login if the currentToken is yet to expire
      if (timeToExpire > 0) {
        const timeoutId = setTimeout(() => {
          // Store the current path as the last visited page
          cookies.set('last_visited_page', location.pathname, {
            path: '/',
            maxAge: 86400,
          });

          // Remove token and expiration time from localStorage
          localStorage.removeItem('access_token');
          localStorage.removeItem('expiration_time');
          setToken(null);
          setExpirationTime(null);

          // Redirect to the login page
          navigate('/login');
        }, timeToExpire * 1000);

        return () => clearTimeout(timeoutId);
      } else if (timeToExpire <= 0) {
        // Token has already expired
        localStorage.removeItem('access_token');
        localStorage.removeItem('expiration_time');
        setToken(null);
        setExpirationTime(null);

        navigate('/login');
      }
    } else {
      localStorage.clear();

      // Redirect to the login page
      navigate('/login');
    }
    // eslint-disable-next-line
  }, []);

  if (!token || (!expirationTime && location.pathname !== '/login')) {
    // Token or expiration time is missing, redirect to login
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Component {...props} />;
};

export default withAuth;
