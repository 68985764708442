import {
  ICharityDetails,
  ICharitySearch,
  ICharityValidations,
} from '../datatypes/Charity';
import { OptionType } from '../datatypes/CommonTypes';

//template for charity fields
export const templateCharityDetailsField: ICharityDetails = {
  name: '',
  description: '',
  category_id: '',
  state_id: '',
  status: 'active',
  logo: '',
  city: '',
  zipcode: '',
  address_1: '',
  address_2: '',
  ein_number: '',
};

//template for validations
export const charityDetailsValidations: ICharityValidations = {
  name: 'required|min:2|max:50',
  description: 'required|min:20',
  category_id: 'required',
  state_id: 'required',
  city: 'required',
  zipcode: 'required|min:5|max:5',
  status: 'required',
  logo: 'required',
  ein_number: 'required',
};

//template for status options
export const optionsCharityStatus: OptionType<string>[] = [
  {
    label: 'Active',
    value: 'active',
    disabled: false,
    color: 'btn-success',
  },
  {
    label: 'Inactive',
    value: 'inactive',
    disabled: false,
    color: 'btn-secondary',
  },
];

//template for search charity
export const searchCharityField: ICharitySearch = {
  name: '',
  status: '',
  category_id: '',
  state_id: '',
};

//template for search charity validations
export const charitySearchValidations: ICharitySearch = {
  name: 'nameValidate',
};
