import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useTemplateApi from '../../api/template.api';
import { ITemplateDetails, ITemplatePayload } from '../../datatypes/Template';

const initialState: ITemplatePayload = {
  templateList: {
    loading: false,
    data: {
      data: [],
      current_page: 0,
      first_page_url: '',
      from: 0,
      last_page: 0,
      path: '',
      per_page: 0,
      prev_page_url: '',
      to: 0,
      total: 0,
    },
    error: false,
  },
  templateDetails: {
    loading: false,
    data: {
      title: '',
      slug: '',
      code: '',
      subject: '',
      type: '',
      content: '',
      status: '',
    },
    error: false,
    success: false,
  },
  saveUpdateStatus: {
    loading: false,
    success: false,
    error: false,
  },
  deleteTemplateStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

export const templateSlice = createSlice({
  name: 'templates',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetTemplateData: (state: ITemplatePayload) => {
      state.saveUpdateStatus = initialState.saveUpdateStatus;
      state.deleteTemplateStatus = initialState.deleteTemplateStatus;
      state.templateDetails = initialState.templateDetails;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplates.pending, (state) => {
        state.templateList.loading = true;
      })
      .addCase(fetchTemplates.fulfilled, (state, { payload }) => {
        state.templateList.data = payload;
        state.templateList.loading = false;
      })
      .addCase(fetchTemplates.rejected, (state) => {
        state.templateList.loading = false;
        state.templateList.error = true;
      })
      .addCase(fetchTemplateDetails.pending, (state) => {
        state.templateDetails.loading = true;
        state.templateDetails.error = false;
        state.templateDetails.success = false;
      })
      .addCase(fetchTemplateDetails.fulfilled, (state, { payload }) => {
        state.templateDetails.data = payload;
        state.templateDetails.success = true;
        state.templateDetails.error = false;
        state.templateDetails.loading = false;
      })
      .addCase(fetchTemplateDetails.rejected, (state) => {
        state.templateDetails.loading = false;
        state.templateDetails.error = true;
        state.templateDetails.success = false;
      })
      .addCase(deleteTemplateData.pending, (state) => {
        state.deleteTemplateStatus.success = false;
        state.deleteTemplateStatus.loading = true;
        state.deleteTemplateStatus.error = false;
      })
      .addCase(deleteTemplateData.fulfilled, (state) => {
        state.deleteTemplateStatus.success = true;
        state.deleteTemplateStatus.loading = false;
        state.deleteTemplateStatus.error = false;
      })
      .addCase(deleteTemplateData.rejected, (state) => {
        state.deleteTemplateStatus.success = false;
        state.deleteTemplateStatus.loading = false;
        state.deleteTemplateStatus.error = true;
      })
      .addCase(updateTemplateDetails.pending, (state) => {
        state.saveUpdateStatus.loading = true;
        state.saveUpdateStatus.error = false;
        state.saveUpdateStatus.success = false;
      })
      .addCase(updateTemplateDetails.fulfilled, (state) => {
        state.saveUpdateStatus.success = true;
        state.saveUpdateStatus.loading = false;
        state.saveUpdateStatus.error = false;
      })
      .addCase(updateTemplateDetails.rejected, (state) => {
        state.saveUpdateStatus.success = false;
        state.saveUpdateStatus.loading = false;
        state.saveUpdateStatus.error = true;
      })
      .addCase(saveTemplateDetails.pending, (state) => {
        state.saveUpdateStatus.loading = true;
        state.saveUpdateStatus.error = false;
        state.saveUpdateStatus.success = false;
      })
      .addCase(saveTemplateDetails.fulfilled, (state) => {
        state.saveUpdateStatus.success = true;
        state.saveUpdateStatus.loading = false;
        state.saveUpdateStatus.error = false;
      })
      .addCase(saveTemplateDetails.rejected, (state) => {
        state.saveUpdateStatus.success = false;
        state.saveUpdateStatus.loading = false;
        state.saveUpdateStatus.error = true;
      });
  },
});

// Async API Hooks
/**
 * Fetch Template List from API and save in redux store
 */
export const fetchTemplates = createAsyncThunk(
  'template/fetchTemplates',
  async (data: any, { rejectWithValue }) => {
    const response = await useTemplateApi().getTemplates(data.params);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data?.template;
    } else {
      return rejectWithValue(response.data);
    }
  }
);
/**
 * Fetch Template Details for the from API and save in redux store
 */
export const fetchTemplateDetails = createAsyncThunk(
  'template/fetchTemplateDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useTemplateApi().getTemplateDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data?.template;
    } else {
      return rejectWithValue(response.data);
    }
  }
);
/**
 * Save Template Details for the from API and save in redux store
 */
export const saveTemplateDetails = createAsyncThunk(
  'template/saveTemplateDetails',
  async (data: ITemplateDetails, { rejectWithValue }) => {
    const response = await useTemplateApi().saveTemplateDetails(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);
/**
 * Fetch Template Details for the from API and save in redux store
 */
export const updateTemplateDetails = createAsyncThunk(
  'template/updateTemplateDetails',
  async (data: ITemplateDetails, { rejectWithValue }) => {
    const response = await useTemplateApi().updateTemplateDetails(
      data,
      String(data?.id)
    );
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);
/**
 * Delete single Template API
 */
export const deleteTemplateData = createAsyncThunk(
  'template/deleteTemplate',
  async (id: string, { rejectWithValue }) => {
    const response = await useTemplateApi().deleteTemplateData(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.success) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const { resetTemplateData } = templateSlice.actions;

export default templateSlice.reducer;
