import useAxiosService from '../services/axios.service';

const useDashboardApi = () => {
  const { getUserHttpClient } = useAxiosService();

  // get dashboard listings data
  const getDashboardListings = async () => {
    return await getUserHttpClient()
      .get('/dashboard/listing')
      .then((response) => response);
  };

  return { getDashboardListings };
};

export default useDashboardApi;
