import { ReactNode, MouseEventHandler } from 'react';
import { Button as CustomButton } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

interface IProps {
  variant?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  children: ReactNode;
  className?: string;
}
const FowButton = ({
  isLoading = false,
  isDisabled = false,
  variant = '',
  onClick,
  children,
  className,
}: IProps) => {
  return (
    <CustomButton
      onClick={!isDisabled ? onClick : undefined}
      variant={variant}
      disabled={isDisabled}
      className={isLoading ? 'loading-button' : className}
    >
      {isLoading && (
        <div className="align-items-center d-flex justify-content-center w-100 h-100">
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            variant="info"
          />
        </div>
      )}
      <div
        style={{
          opacity: isLoading ? 0 : 1,
          ...(isLoading ? { height: 0 } : {}),
        }}
      >
        {children}
      </div>
    </CustomButton>
  );
};

export default FowButton;
