import notFound from '../../assets/images/notFound.png';

interface IProps {
  heading: string;
  subHeading: string;
}

const NotFound = ({ heading = '', subHeading = '' }: IProps) => {
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="not-found-box">
              <div className="not-found-image">
                <img src={notFound} alt="" />
              </div>
              <h4>{heading}</h4>
              <p>{subHeading}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
