import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useCharityApi from '../../api/charity.api';
import { ICharityDetails, ICharityPayload } from '../../datatypes/Charity';

//initial redux slice state
const initialState: ICharityPayload = {
  charityList: {
    loading: false,
    data: {
      data: [],
      current_page: 0,
      total: 0,
    },
    error: false,
  },
  charityDetails: {
    loading: false,
    data: null,
    success: false,
    error: false,
  },
  charityCategoryList: {
    loading: false,
    data: {
      category: 'active',
      data: [],
    },
    error: false,
    success: false,
  },
  saveUpdateCharity: {
    success: false,
    loading: false,
    error: false,
  },
  deleteCharity: {
    success: false,
    loading: false,
    error: false,
  },
};

export const charitySlice = createSlice({
  name: 'charities',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetCharityData: (state) => {
      state.charityDetails = initialState.charityDetails;
      state.charityList = initialState.charityList;
      state.saveUpdateCharity = initialState.saveUpdateCharity;
      state.deleteCharity = initialState.deleteCharity;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchCharities.pending, (state) => {
        state.charityList.loading = true;
      })
      .addCase(fetchCharities.fulfilled, (state, { payload }) => {
        state.charityList.data.data = payload?.charities ?? [];
        state.charityList.data.total = payload?.total ?? 0;
        state.charityList.data.current_page = payload?.current_page ?? 0;
        state.charityList.loading = false;
      })
      .addCase(fetchCharities.rejected, (state) => {
        state.charityList.loading = false;
        state.charityList.error = true;
      })
      .addCase(fetchCharityDetails.pending, (state) => {
        state.charityDetails.loading = true;
        state.charityDetails.error = false;
        state.charityDetails.success = false;
      })
      .addCase(fetchCharityDetails.fulfilled, (state, { payload }) => {
        state.charityDetails.data = payload;
        state.charityDetails.loading = false;
        state.charityDetails.success = true;
        state.charityDetails.error = false;
      })
      .addCase(fetchCharityDetails.rejected, (state) => {
        state.charityDetails.loading = false;
        state.charityDetails.error = true;
        state.charityDetails.success = false;
      })
      .addCase(saveUpdateCharityDetails.pending, (state) => {
        state.saveUpdateCharity.loading = true;
        state.saveUpdateCharity.success = false;
        state.saveUpdateCharity.error = false;
      })
      .addCase(saveUpdateCharityDetails.fulfilled, (state) => {
        state.saveUpdateCharity.success = true;
        state.saveUpdateCharity.loading = false;
        state.saveUpdateCharity.error = false;
      })
      .addCase(saveUpdateCharityDetails.rejected, (state) => {
        state.saveUpdateCharity.loading = false;
        state.saveUpdateCharity.success = false;
        state.saveUpdateCharity.error = true;
      })
      .addCase(deleteCharityDetails.pending, (state) => {
        state.deleteCharity.loading = true;
        state.deleteCharity.success = false;
        state.deleteCharity.error = false;
      })
      .addCase(deleteCharityDetails.fulfilled, (state) => {
        state.deleteCharity.success = true;
        state.deleteCharity.loading = false;
        state.deleteCharity.error = false;
      })
      .addCase(deleteCharityDetails.rejected, (state) => {
        state.deleteCharity.loading = false;
        state.deleteCharity.success = false;
        state.deleteCharity.error = true;
      })
      .addCase(fetchCharityCategories.pending, (state, action) => {
        state.charityCategoryList.loading = true;
        state.charityCategoryList.error = false;
        state.charityCategoryList.success = false;
        state.charityCategoryList.data.category = action.payload ?? '';
        state.charityCategoryList.data.data = [];
      })
      .addCase(fetchCharityCategories.fulfilled, (state, { payload }) => {
        state.charityCategoryList.data.data = payload;
        state.charityCategoryList.loading = false;
        state.charityCategoryList.error = false;
        state.charityCategoryList.success = true;
      })
      .addCase(fetchCharityCategories.rejected, (state) => {
        state.charityCategoryList.loading = false;
        state.charityCategoryList.error = true;
        state.charityCategoryList.success = false;
        state.charityCategoryList.data = initialState.charityCategoryList.data;
      });
  },
});

// Async API Hooks
/**
 * Fetch Charity List from API and save in redux store
 */
export const fetchCharities = createAsyncThunk(
  'charities/fetchCharities',
  async (data: any, { rejectWithValue }) => {
    for (const key in data.params) {
      if (data.params[key] === '') {
        delete data.params[key];
      }
    }
    const response = await useCharityApi().getCharities(data.params);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response;
    } else {
      return rejectWithValue(response?.data);
    }
  }
);

/**
 * Fetch Charity Details from API and save in redux store
 * @param id
 */
export const fetchCharityDetails = createAsyncThunk(
  'charities/fetchCharityDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useCharityApi().getCharityDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response?.charity;
    } else {
      return rejectWithValue(response?.data);
    }
  }
);

/**
 * Save / Update Charity Details to API and save in redux store
 * @package data
 */
export const saveUpdateCharityDetails = createAsyncThunk(
  'charities/saveUpdateCharityDetails',
  async (data: ICharityDetails, { rejectWithValue }) => {
    const id = data?.id;
    if (data['id']) {
      delete data['id'];
    }
    if (typeof data['logo'] !== 'object') {
      delete data['logo'];
    }
    const response = id
      ? await useCharityApi().updateCharityDetails(data, String(id))
      : await useCharityApi().saveCharityDetails(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response?.data;
    } else {
      return rejectWithValue(response?.data);
    }
  }
);

/**
 * Update Charity Details to API and save in redux store
 * @package data
 * @param id
 */
export const updateCharityDetails = createAsyncThunk(
  'charities/updateCharityDetails',
  async (data: ICharityDetails, { rejectWithValue }) => {
    const response = await useCharityApi().updateCharityDetails(
      data,
      String(data?.id)
    );
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response?.data;
    } else {
      return rejectWithValue(response?.data);
    }
  }
);

/**
 * Delete Charity Details
 * @param id
 */
export const deleteCharityDetails = createAsyncThunk(
  'charities/deleteCharityDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useCharityApi().deleteCharity(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response?.data;
    } else {
      return rejectWithValue(response?.data);
    }
  }
);

/**
 * Charity Category List
 * @param status
 */
export const fetchCharityCategories = createAsyncThunk(
  'charities/charityCategory',
  async (status: string, { rejectWithValue }) => {
    const response = await useCharityApi().getCharityCategories({ status });
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response?.charityCategories;
    } else {
      return rejectWithValue(response?.data);
    }
  }
);

export const { resetCharityData } = charitySlice.actions;

export default charitySlice.reducer;
