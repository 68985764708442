import { toastSuccessMessage } from '../../components/common/ToastMessage';
import PageTitle from '../../components/common/PageTitle';
import { useEffect, useState } from 'react';
import {
  charitySearchValidations,
  searchCharityField,
} from '../../constants/Charity';
import {
  IShowDeleteCharityModal,
  ICharityPayload,
} from '../../datatypes/Charity';
import {
  resetCharityData,
  fetchCharities,
  fetchCharityCategories,
} from '../../redux/slices/charitySlice';
import Listing from '../../components/charities/Listing';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CharitySearch from '../../components/charities/CharitySearch';
import { ValidateField } from '../../helpers/ValidatorHelper';

const CharityListing = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [charitySearch, setCharitySearch] = useState({
    ...searchCharityField,
  });

  const [errors, setErrors] = useState({
    ...searchCharityField,
  });

  const [showAdvancedSearch, setShowAdvancedSearch] = useState({
    show: false,
    filterApplied: false,
  });

  const [showDeleteModal, setShowDeleteModal] =
    useState<IShowDeleteCharityModal>({
      show: false,
      idDetails: [],
    });

  const { charityList, deleteCharity } = useAppSelector<ICharityPayload>(
    (state) => state.charities
  );

  //function to toggle advanced search
  const toggleAdvanceFilter = () => {
    setShowAdvancedSearch({
      ...showAdvancedSearch,
      show: !showAdvancedSearch.show,
    });
  };

  //function to toggle filter applied
  const toggleFilterApplied = (isApplied: boolean) => {
    setShowAdvancedSearch({
      ...showAdvancedSearch,
      filterApplied: isApplied,
    });
  };

  //function to handle search input
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCharitySearch((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const error =
      value === ''
        ? null
        : ValidateField(name, value, String(charitySearchValidations[name]));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error || '',
    }));

    if (name === 'status') {
      setCharitySearch((prevData) => ({
        ...prevData,
        category_id: '',
      }));
      dispatch(fetchCharityCategories(String(value)));
    }
  };

  //function to handle validation on search click
  const validateForm = () => {
    const hasValues = Object.keys(charitySearch).some(
      (key) => charitySearch[key] !== ''
    );
    const hasErrors = Object.keys(errors).some((key) => errors[key] !== '');
    return !hasValues || hasErrors;
  };

  //function to handle search button click
  const onCharitySearch = () => {
    const hasError = validateForm();
    if (!hasError) {
      const payload = {
        ...charitySearch,
      };
      dispatch(fetchCharities({ params: payload }));
      toggleFilterApplied(true);
    }
  };

  //function to handle reset button click
  const resetCharitySearch = () => {
    dispatch(fetchCharities({}));
    setCharitySearch({
      ...searchCharityField,
    });
    toggleFilterApplied(false);
  };

  useEffect(() => {
    dispatch(fetchCharities({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (deleteCharity.success) {
      toastSuccessMessage('Charity deleted successfully');
      setShowDeleteModal({
        show: false,
        idDetails: [],
      });
      dispatch(resetCharityData());
      dispatch(fetchCharities({}));
    }
    // eslint-disable-next-line
  }, [deleteCharity]);

  return (
    <div className="charity-templates">
      <PageTitle
        heading="Charity Listing"
        buttonName="Add New"
        buttonClick={async () => {
          dispatch(resetCharityData());
          navigate('/charities/new');
        }}
      />
      <div className="control-top-button mb-2">
        <Button
          variant="primary"
          className="mr-2"
          onClick={() => toggleAdvanceFilter()}
        >
          Advanced Search
        </Button>
      </div>
      <div className="charity-content">
        {showAdvancedSearch.show && (
          <CharitySearch
            errors={errors}
            handleInputChange={handleInputChange}
            charitySearch={charitySearch}
            onCharitySearch={onCharitySearch}
            resetCharitySearch={resetCharitySearch}
            isFilterApplied={showAdvancedSearch.filterApplied}
          />
        )}
        <Listing
          charityList={charityList}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deleteCharityDetail={deleteCharity}
        />
      </div>
    </div>
  );
};

export default CharityListing;
