import { Link } from 'react-router-dom';

const TheFooter = () => {
  return (
    <footer className="footer footer-fixed">
      <div className="mfs-auto">
        <span className="mr-1">Copyright </span>
        <Link
          to="https://www.theuswillregistry.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          The US Will Registry
        </Link>
      </div>
    </footer>
  );
};

export default TheFooter;
