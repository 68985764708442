import { toastSuccessMessage } from '../../components/common/ToastMessage';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useDeathNoticeApi from '../../api/deathNotice.api';
import { IObituaryPayload, IObituaryDetails } from '../../datatypes/Obituary';

const initialState: IObituaryPayload = {
  obituaryList: {
    loading: false,
    data: {
      data: [],
      current_page: 0,
      first_page_url: '',
      from: 0,
      last_page: 0,
      path: '',
      per_page: 0,
      prev_page_url: '',
      to: 0,
      total: 0,
    },
    error: false,
  },
  saveUpdateStatus: {
    loading: false,
    success: false,
    error: false,
  },
  obituaryDetails: { loading: false, success: false, data: null, error: false },
  deleteObituary: { loading: false, success: false, error: false },
};

export const obituariesSlice = createSlice({
  name: 'obituaries',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetObituaryData: (state) => {
      state.saveUpdateStatus = initialState.saveUpdateStatus;
      state.deleteObituary = initialState.deleteObituary;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchObituaries.pending, (state) => {
        state.obituaryList.loading = true;
      })
      .addCase(fetchObituaries.fulfilled, (state, { payload }) => {
        state.obituaryList.data = payload?.obituries;
        state.obituaryList.loading = false;
      })
      .addCase(fetchObituaries.rejected, (state) => {
        state.obituaryList.loading = false;
        state.obituaryList.error = true;
      })
      .addCase(fetchObituaryDetails.pending, (state) => {
        state.obituaryDetails.loading = true;
      })
      .addCase(fetchObituaryDetails.fulfilled, (state, { payload }) => {
        state.obituaryDetails.data = payload?.obituary;
        state.obituaryDetails.loading = false;
        state.obituaryDetails.success = true;
      })
      .addCase(fetchObituaryDetails.rejected, (state) => {
        state.obituaryDetails.loading = false;
        state.obituaryDetails.error = true;
      })
      .addCase(updateObituaryDetails.pending, (state) => {
        state.saveUpdateStatus.loading = true;
        state.saveUpdateStatus.error = false;
        state.saveUpdateStatus.success = false;
      })
      .addCase(updateObituaryDetails.fulfilled, (state) => {
        state.saveUpdateStatus.success = true;
        state.saveUpdateStatus.error = false;
        state.saveUpdateStatus.loading = false;
      })
      .addCase(updateObituaryDetails.rejected, (state) => {
        state.saveUpdateStatus.loading = false;
        state.saveUpdateStatus.error = true;
        state.saveUpdateStatus.success = false;
      })
      .addCase(deleteObituary.pending, (state) => {
        state.deleteObituary.loading = true;
        state.deleteObituary.success = false;
        state.deleteObituary.error = false;
      })
      .addCase(deleteObituary.fulfilled, (state) => {
        state.deleteObituary.loading = false;
        state.deleteObituary.success = true;
        state.deleteObituary.error = false;
      })
      .addCase(deleteObituary.rejected, (state) => {
        state.deleteObituary.loading = false;
        state.deleteObituary.error = true;
      });
  },
});

/**
 * Fetch Obituary List from API and save in redux store
 */
export const fetchObituaries = createAsyncThunk(
  'obituaries/fetchObituaries',
  async (data: any, { rejectWithValue }) => {
    const response = await useDeathNoticeApi().getObituaries(data.params);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.status === 201) {
      return response?.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

/**
 * Fetch Obituary Details from API and save in redux store
 */
export const fetchObituaryDetails = createAsyncThunk(
  'obituaries/fetchObituaryDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useDeathNoticeApi().getObituaryDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.status === 201) {
      return response?.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const updateObituaryDetails = createAsyncThunk(
  'obituaries/updateObituaryDetails',
  async (data: IObituaryDetails, { rejectWithValue }) => {
    if (typeof data['death_img'] !== 'object') {
      delete data['death_img'];
    }
    const id = data.id;
    delete data.id;
    const response = await useDeathNoticeApi().updateObituaryDetails(
      data,
      String(id)
    );
    // The value we returns becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Delete Obituary Details from API and remove from redux store
 */
export const deleteObituary = createAsyncThunk(
  'obituaries/deleteDeathNotice',
  async (id: string, { rejectWithValue }) => {
    const response = await useDeathNoticeApi().deleteObituary(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.status === 201) {
      toastSuccessMessage('Successfully Deleted');
      return response?.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const { resetObituaryData } = obituariesSlice.actions;

export default obituariesSlice.reducer;
