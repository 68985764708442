import { Spinner } from 'react-bootstrap';

export const dtCustomStyle = {
  headCells: {
    style: {
      fontSize: '16px', // override the cell padding for head cells
      fontWeight: 'bold',
      color: '#000',
      width: '100%',
    },
  },
  cells: {
    style: {
      fontSize: '14px',
      color: '#000',
      width: '100%',
    },
  },
};

export const Dtspinner = () => {
  return (
    <div className="my-2 align-items-center d-flex justify-content-center">
      <Spinner as="span" animation="border" role="status" aria-hidden="true" />
    </div>
  );
};
