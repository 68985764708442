import { startCase } from 'lodash';
import { formattedInputKeys } from 'src/constants/FormattedInputKeys';
import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';

const ExecutorAnswers = ({ answers }: any) => {
  const executorInformation = answers[pageCodes.executorPage];
  const executor =
    executorInformation &&
    executorInformation[questionCodes.estateExecutorNames];

  return (
    <div>
      <div>
        <table className="default-table table table-bordered">
          <tbody>
            <tr>
              <th colSpan={4} className="table-title">
                <div>
                  <h4>Will Executor</h4>
                </div>
              </th>
            </tr>
            <tr>
              <th className="text-left">Primary Executor</th>
              <td>
                {startCase(
                  `${executor[questionInputKeys.PRIMARY_FIRSTNAME]} ${
                    executor[questionInputKeys.PRIMARY_LASTNAME]
                  }`
                )}{' '}
                (
                {
                  formattedInputKeys[
                    executor[questionInputKeys.PRIMARY_RELATIONSHIP]
                  ]
                }
                )
              </td>
              {executor[questionInputKeys.ALTERNATE_FIRSTNAME] &&
                executor[questionInputKeys.ALTERNATE_LASTNAME] &&
                executor[questionInputKeys.ALTERNATE_RELATIONSHIP] && (
                  <>
                    <th>Alternate Executor</th>
                    <td>
                      {`${startCase(
                        `${executor[questionInputKeys.ALTERNATE_FIRSTNAME]} ${
                          executor[questionInputKeys.ALTERNATE_LASTNAME]
                        }`
                      )} 
                        (${
                          formattedInputKeys[
                            executor[questionInputKeys.ALTERNATE_RELATIONSHIP]
                          ]
                        }
                      )`}
                    </td>
                  </>
                )}
            </tr>
            <tr>
              <th className="w-60">
                Would you like your executor to receive compensation for the
                work and services provided?
              </th>
              <td
                colSpan={
                  executorInformation[
                    questionCodes.shouldEstateExecutorReceiveCompenstation
                  ][questionInputKeys.SHOULD_EXECUTOR_RECEIVE_COMPENSATION] ===
                  'no'
                    ? 3
                    : 1
                }
              >
                {startCase(
                  executorInformation[
                    questionCodes.shouldEstateExecutorReceiveCompenstation
                  ][questionInputKeys.SHOULD_EXECUTOR_RECEIVE_COMPENSATION]
                )}
              </td>
              {executorInformation &&
                executorInformation[
                  questionCodes.shouldEstateExecutorReceiveCompenstation
                ] &&
                executorInformation[
                  questionCodes.shouldEstateExecutorReceiveCompenstation
                ][questionInputKeys.SHOULD_EXECUTOR_RECEIVE_COMPENSATION] ===
                  'yes' && (
                  <>
                    <th>Compensation</th>
                    <td>
                      {executorInformation[
                        questionCodes.estateExecutorCompensation
                      ] &&
                      executorInformation[
                        questionCodes.estateExecutorCompensation
                      ][questionInputKeys.COMPENSATION_UNIT] === '%'
                        ? `${
                            executorInformation[
                              questionCodes.estateExecutorCompensation
                            ][questionInputKeys.COMPENSATION_VALUE]
                          }%`
                        : `${new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(
                            executorInformation[
                              questionCodes.estateExecutorCompensation
                            ][questionInputKeys.COMPENSATION_VALUE]
                          )}`}
                    </td>
                  </>
                )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExecutorAnswers;
