import { Dtspinner, dtCustomStyle } from '../../helpers/DataTableHelper';
import { Button, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import {
  IShowDeleteUserModal,
  IUsersColumns,
  IUsersRow,
  IUsersPayload,
} from '../../datatypes/User';
import { useAppDispatch } from '../../redux/hooks';
import DeleteModal from './DeleteModal';
import { deleteAdminUser } from '../../redux/slices/userSlice';
import { useEffect } from 'react';
import { PageOptions } from '../../constants/Common';
import { Link } from 'react-router-dom';

interface IProps {
  usersList: IUsersPayload['admin']['adminList'];
  deleteUsersDetail: IUsersPayload['admin']['deleteAdmin'];
  showDeleteUserModal: IShowDeleteUserModal;
  setShowDeleteUserModal: React.Dispatch<
    React.SetStateAction<IShowDeleteUserModal>
  >;
}

const Listing = ({
  usersList,
  deleteUsersDetail,
  showDeleteUserModal,
  setShowDeleteUserModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  //switch function for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteUserModal((prev: IShowDeleteUserModal) => ({
      ...prev,
      show: !showDeleteUserModal.show,
      ...(showDeleteUserModal.show ? { idDetails: [] } : {}),
    }));
  };

  const selectedUserId = (rows: IUsersRow[]) => {
    setShowDeleteUserModal((prev: IShowDeleteUserModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  //function to handle delete
  const handleDelete = () => {
    const selectedIds = showDeleteUserModal.idDetails
      .map((userDetail: IUsersRow) => userDetail.id)
      .join(',');
    dispatch(deleteAdminUser(selectedIds));
  };

  //function to handle delete modal
  const openDeleteModal = (row: IUsersRow) => {
    selectedUserId([row]);
    toggleDeleteModal();
  };

  useEffect(() => {
    if (deleteUsersDetail?.success) {
      setShowDeleteUserModal((prev: IShowDeleteUserModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteUsersDetail?.success]);

  const columns: IUsersColumns[] = [
    {
      name: 'First Name',
      selector: (row: IUsersRow) => row.first_name,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: (row: IUsersRow) => row.last_name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row: IUsersRow) => row.email,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: (row: IUsersRow) => row.phone,
      sortable: true,
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: IUsersRow) => row.actions,
      center: true,
      compact: true,
      cell: (row: IUsersRow) => (
        <div>
          <Link
            title="Edit"
            className="btn btn-primary btn-sm"
            to={`/admin-users/${row.id}/edit`}
            key="edit"
          >
            <i className="fa fa-pencil"></i>
          </Link>
          <Link
            title="View"
            className="btn btn-secondary btn-sm ml-2"
            to={`/admin-users/${row.id}/view`}
            key="view"
          >
            <i className="fa fa-eye"></i>
          </Link>
          <Button
            title="Delete"
            className="btn btn-danger btn-sm ml-2"
            key="delete"
            onClick={() => openDeleteModal(row)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="row mt-3">
      <div className="col-12">
        <Card>
          <Card.Body>
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={usersList.loading}
              paginationRowsPerPageOptions={PageOptions}
              pagination
              paginationPerPage={50}
              striped
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={usersList.data?.total}
              data={usersList.data?.data}
              customStyles={dtCustomStyle}
              progressComponent={<Dtspinner />}
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteUserModal.show}
          selectedIdDetails={showDeleteUserModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteUsersDetail={deleteUsersDetail}
        />
      </div>
    </div>
  );
};

export default Listing;
