import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';

const SpecialInstructionAnswer = ({ answers }: any) => {
  const specialInstructionsResponse =
    answers[pageCodes.specialInstructionsPage];

  return (
    <div>
      <div>
        <table className="default-table table table-bordered">
          <tbody>
            <tr>
              <th colSpan={4} className="table-title">
                <div>
                  <h4>Special Instructions</h4>
                </div>
              </th>
            </tr>
            <tr>
              <td colSpan={4}>
                {specialInstructionsResponse[questionCodes.specialInstructions][
                  questionInputKeys.SPECIAL_INSTRUCTIONS
                ] ?? 'N/A'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SpecialInstructionAnswer;
