import { startCase } from 'lodash';
import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';

const DigitalAssetsAnswers = ({ answers }: any) => {
  const digitalAssetsInformation = answers[pageCodes.digitalAssetsPage];
  const digitalAssets =
    digitalAssetsInformation &&
    digitalAssetsInformation[questionCodes.digitalAssets];

  return (
    <div>
      <div>
        <table className="default-table table table-bordered">
          <tbody>
            <tr>
              <th colSpan={5} className="table-title">
                <div>
                  <h4>Digital Assets</h4>
                </div>
              </th>
            </tr>
            <tr>
              <th className="text-left w-25">
                Do you want to leave digital assets?
              </th>
              <td colSpan={4}>
                {startCase(
                  digitalAssetsInformation[questionCodes.hasDigitalAssets][
                    questionInputKeys.HAS_DIGITAL_ASSETS
                  ]
                )}
              </td>
            </tr>
            {digitalAssets &&
              digitalAssetsInformation[questionCodes.hasDigitalAssets][
                questionInputKeys.HAS_DIGITAL_ASSETS
              ] === 'yes' &&
              digitalAssets.length > 0 && (
                <>
                  <tr>
                    <th>Asset Name</th>
                    <th>Website URL</th>
                    <th>Description</th>
                    <th>Primary Beneficiary</th>
                    <th>Alt. Beneficiary</th>
                  </tr>
                  {digitalAssets.map((digitalAsset: any, index: number) => (
                    <tr key={index}>
                      <td>{startCase(digitalAsset.asset_name)}</td>
                      <td>
                        <a
                          href={digitalAsset.website_address}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {digitalAsset.website_address}
                        </a>
                      </td>
                      <td>
                        {digitalAsset.asset_description &&
                          digitalAsset.asset_description}
                      </td>
                      <td>
                        {digitalAsset[questionInputKeys.PRIMARY_FIRSTNAME] &&
                          digitalAsset[questionInputKeys.PRIMARY_LASTNAME] &&
                          digitalAsset[
                            questionInputKeys.PRIMARY_RELATIONSHIP
                          ] &&
                          startCase(
                            `${
                              digitalAsset[questionInputKeys.PRIMARY_FIRSTNAME]
                            } ${
                              digitalAsset[questionInputKeys.PRIMARY_LASTNAME]
                            }`
                          )}
                      </td>
                      <td>
                        {digitalAsset[questionInputKeys.ALTERNATE_FIRSTNAME] &&
                        digitalAsset[questionInputKeys.ALTERNATE_LASTNAME] &&
                        digitalAsset[questionInputKeys.ALTERNATE_RELATIONSHIP]
                          ? startCase(
                              `${
                                digitalAsset[
                                  questionInputKeys.ALTERNATE_FIRSTNAME
                                ]
                              } ${
                                digitalAsset[
                                  questionInputKeys.ALTERNATE_LASTNAME
                                ]
                              }`
                            )
                          : 'N/A'}
                      </td>
                    </tr>
                  ))}
                </>
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DigitalAssetsAnswers;
