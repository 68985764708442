import useAxiosService from '../services/axios.service';

const useAnswersApi = () => {
  const { getUserHttpClient } = useAxiosService();

  //get Answers by user id
  const getAnswers = async (userId: string) => {
    return await getUserHttpClient()
      .get('/answers/' + userId)
      .then((response) => response?.data);
  };

  return {
    getAnswers,
  };
};

export default useAnswersApi;
