import { useEffect, useState } from 'react';
import PageTitle from '../../components/common/PageTitle';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ValidateField } from '../../helpers/ValidatorHelper';
import FowButton from '../../components/common/FowButton';
import {
  IObituaryPagination,
  IObituaryPayload,
} from '../../datatypes/Obituary';
import {
  fetchObituaries,
  resetObituaryData,
} from '../../redux/slices/obituarySlice';
import {
  obituaryListPagination,
  obituaryValidations,
  searchObituaryField,
} from '../../constants/Obituary';
import ObituarySearch from '../../components/obituries/ObituarySearch';
import ObituaryList from '../../components/obituries/ObituaryList';
import { ISortParams } from 'src/datatypes/CommonTypes';

const ObituaryListing = () => {
  const [obituarySearch, setObituarySearch] = useState({
    ...searchObituaryField,
  });
  const [errors, setErrors] = useState({ ...searchObituaryField });
  const [showAdvanceSearch, setShowAdvanceSearch] = useState({
    show: false,
    filterApplied: false,
  });
  const [pagination, setPagination] = useState<IObituaryPagination>(
    obituaryListPagination
  );
  const [sort, setSort] = useState<ISortParams>({
    orderBy: 'id',
    order: 'desc',
  });
  const dispatch = useAppDispatch();
  const { obituaryList, deleteObituary } = useAppSelector<IObituaryPayload>(
    (state) => state.obituaries
  );

  useEffect(() => {
    if (!deleteObituary.loading && deleteObituary.success) {
      dispatch(fetchObituaries({}));
      dispatch(resetObituaryData());
    }
    // eslint-disable-next-line
  }, [deleteObituary.loading, dispatch]);

  const getObituaryListData = () => {
    dispatch(fetchObituaries({}));
  };

  useEffect(() => {
    getObituaryListData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchObituaries({ params: { ...pagination, ...sort } }));
  }, [pagination, dispatch, sort]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setObituarySearch((obituarySearch) => ({
      ...obituarySearch,
      [name]: value,
    }));

    let tempValue: any = value;
    if (name === 'created_date_from') {
      tempValue = {
        value1: value,
        value2: obituarySearch.created_date_to,
      };
    } else if (name === 'created_date_to') {
      tempValue = {
        value1: value,
        value2: obituarySearch.created_date_from,
      };
    }
    const error =
      value === ''
        ? null
        : ValidateField(e.target.name, tempValue, obituaryValidations?.[name]);

    setErrors((errors) => ({ ...errors, [name]: error || '' }));
  };

  const toggleAdvanceFilter = () => {
    setShowAdvanceSearch({
      ...showAdvanceSearch,
      show: !showAdvanceSearch.show,
    });
  };

  const toggleFilterApplied = (isApplied: boolean) => {
    setShowAdvanceSearch({
      ...showAdvanceSearch,
      filterApplied: isApplied,
    });
  };

  const validateForm = () => {
    const hasValuesInvoiceSearch = Object.keys(obituarySearch).some(
      (key) => obituarySearch[key] !== ''
    );
    const hasErrors = Object.keys(errors).some((key) => errors[key] !== '');
    return !hasValuesInvoiceSearch || hasErrors;
  };

  const onObituarySearch = () => {
    const hasError = validateForm();
    if (!hasError) {
      const tempObituarySearch = {
        ...obituarySearch,
        page: 1,
      };
      dispatch(fetchObituaries({ params: tempObituarySearch }));
      toggleFilterApplied(true);
    }
  };

  const resetObituarySearch = () => {
    dispatch(fetchObituaries({}));
    toggleFilterApplied(false);
    setObituarySearch({ ...searchObituaryField });
  };

  return (
    <div className="missing-wills">
      <PageTitle heading="Obituaries" />
      <div className="missing-wills-content">
        <div className="control-top-button mb-2">
          <FowButton
            variant="primary"
            className="mr-2"
            onClick={() => toggleAdvanceFilter()}
          >
            Advanced Search
          </FowButton>
        </div>
        <div>
          {showAdvanceSearch.show && (
            <ObituarySearch
              errors={errors}
              handleInputChange={handleInputChange}
              obituarySearch={obituarySearch}
              onObituarySearch={onObituarySearch}
              resetObituarySearch={resetObituarySearch}
              isFilterApplied={showAdvanceSearch.filterApplied}
            />
          )}
          <ObituaryList
            obituaryList={obituaryList}
            setPagination={setPagination}
            setSort={setSort}
          />
        </div>
      </div>
    </div>
  );
};

export default ObituaryListing;
