import PageTitle from '../../components/common/PageTitle';
import { useEffect } from 'react';
import Listing from '../../components/charity-partnerships/Listing';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import {
  fetchCharityPartnershipList,
  resetCharityPartnershipData,
} from '../../redux/slices/charityPartnershipSlice';
import { ICharityPartnershipsPayload } from '../../datatypes/CharityPartnership';
import { resetCharityData } from '../../redux/slices/charitySlice';

const CharityPartnershipListing = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { charityPartnershipList } =
    useAppSelector<ICharityPartnershipsPayload>(
      (state) => state.charityPartnerships
    );

  useEffect(() => {
    dispatch(fetchCharityPartnershipList({}));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="charity-templates">
      <PageTitle
        heading="Charity Partnership Listing"
        buttonName="Add New"
        buttonClick={async () => {
          dispatch(resetCharityPartnershipData());
          dispatch(resetCharityData());
          navigate('/partnerships/new');
        }}
      />
      <div className="charity-content">
        <Listing charityPartnershipList={charityPartnershipList} />
      </div>
    </div>
  );
};

export default CharityPartnershipListing;
