import useAxiosService from '../services/axios.service';

const useWillRegistrationApi = () => {
  const { getUserHttpClient } = useAxiosService();
  const getWillRegistrations = async (params?: any) => {
    return getUserHttpClient()
      .get('/living-will', { params: params ? params : {} })
      .then((response) => {
        return response;
      });
  };

  const getWillRegistrationDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/living-will/' + id)
      .then((response) => response);
  };

  const deleteWillRegistrationData = async (id: string) => {
    return await getUserHttpClient()
      .delete('/living-will/' + id)
      .then((response) => response);
  };

  const sendCertificate = async (id: string) => {
    return await getUserHttpClient()
      .post(`/living-will/${id}/certificate`)
      .then(() => ({
        success: true,
      }))
      .catch(() => ({ success: false }));
  };

  return {
    getWillRegistrations,
    getWillRegistrationDetails,
    sendCertificate,
    deleteWillRegistrationData,
  };
};

export default useWillRegistrationApi;
