export const questionCodes = {
  //Personal Information
  legalName: 'Q001S001',
  gender: 'Q002S001',
  birthdate: 'Q003S001',
  otherGender: 'Q002S002',
  phone: 'Q021S001',

  //Address Information
  legalAddress: 'Q004S001',
  assetsValue: 'Q005S001',

  //Spousal information
  maritalStatus: 'Q006S001',
  spouseLegalName: 'Q006S002',
  partnerLegalName: 'Q006S005',

  //Children Information
  haveChildren: 'Q007S001',
  childrenNames: 'Q007S002',
  hasFutureChildren: 'Q007S003',

  //Guardian information
  guardianForMinorAndDisabledChildren: 'Q008S001',
  requiredFinancialGuardians: 'Q008S002',
  financialGuardians: 'Q008S003',
  shouldFinancialGuardianReceiveCompensation: 'Q008S004',
  financialGuardianCompensation: 'Q008S005',

  // Beneficiary Exclusion
  excludeChildren: 'Q009S001',
  childrenToBeExcluded: 'Q009S002',
  descendentInheritChildrenPortion: 'Q009S003',
  excludeBenificiaryFromWill: 'Q010S001',
  excludedOtherBeneficiary: 'Q010S002',

  //pets
  hasPets: 'Q013S001',
  pets: 'Q013S002',
  petsCareTakers: 'Q013S004',

  //insurance policies
  hasNonProbateAssets: 'Q014S001',
  nonProbateAssets: 'Q014S003',
  receiveInsurancePolicy: 'Q014S004',

  //digital assets
  hasDigitalAssets: 'Q015S001',
  digitalAssets: 'Q015S002',

  //specific bequests
  hasSpecificBequests: 'Q016S001',
  specificBequests: 'Q016S002',

  //create legacy
  wantToDonateInCharities: 'Q011S001',
  charities: 'Q011S002',
  charityPortion: 'Q012S001',
  informCharity: 'Q012S002',
  needMoreCharityInfo: 'Q012S003',

  //residuary property
  residuaryPropertyPB: 'Q017S001',
  residuaryPropertySBWhenPBIsSpouse: 'Q017S002',
  residuaryPropertySBWhenPBIsPartner: 'Q017S003',
  residuaryPropertyTBWhenPBIsSpouseAndSBIsChildren: 'Q017S004',
  residuaryPropertyTBWhenPBIsPartnerAndSBIsChildren: 'Q017S005',
  residuaryPropertySBWhenPBIsCustomPlan: 'Q017S007',
  residuaryPropertyTBWhenPBIsSpouseAndSBIsCharity: 'Q017S008',
  residuaryPropertyTBWhenPBIsSpouseAndSBIsCustomPlan: 'Q017S009',
  residuaryPropertyTBWhenPBIsPartnerAndSBIsCharity: 'Q017S010',
  residuaryPropertyTBWhenPBIsPartnerAndSBIsCustomPlan: 'Q017S011',
  residuaryPropertyQBWhenPBIsSpouseAndSBIsChildrenAndTBIsCharity: 'Q017S012',
  residuaryPropertyQBWhenPBIsSpouseAndSBIsChildrenAndTBIsCustomPlan: 'Q017S013',
  residuaryPropertyQBWhenPBIsPartnerAndSBIsChildrenAndTBIsCharity: 'Q017S014',
  residuaryPropertyQBWhenPBIsPartnerAndSBIsChildrenAndTBIsCustomPlan:
    'Q017S015',
  residuaryPropertyTBWhenPBIsChildrenAndSBIsCharity: 'Q017S016',
  residuaryPropertyTBWhenPBIsChildrenAndSBIsCustomPlan: 'Q017S017',
  residuaryPropertyTBWhenPBIsCustomPlanAndSBIsAlternateBeneficiary: 'Q017S018',
  residuaryPropertyTBWhenPBIsCustomPlanAndSBIsAlternateBeneficiaryTBIsCustomPlan:
    'Q017S019',
  residuaryPropertySBWhenPBIsChildrenOrSpouseChildrenOrPartnerChildren:
    'Q017S020',
  residuaryPropertyTBWhenSBCharityAndPBIsChildrenOrSpouseChildrenOrPartnerChildren:
    'Q017S021',

  // Executor Information
  estateExecutorNames: 'Q018S001',
  shouldEstateExecutorReceiveCompenstation: 'Q018S002',
  estateExecutorCompensation: 'Q018S003',

  // Funeral Information
  hasFuneralArrangements: 'Q020S001',
  funeralArrangementInformation: 'Q020S002',
  receiveFuneralPlanning: 'Q020S003',

  //Last will provisions
  contentProvision: 'Q021S002',
  survivalRequirement: 'Q021S003',

  //Special Instructions
  specialInstructions: 'Q022S001',
};

export const pageCodes: any = {
  personalInfoPage: 'P001',
  addressInfoPage: 'P002',
  assetInfoPage: 'P003',
  spouseInfoPage: 'P004',
  childrenInfoPage: 'P005',
  guardianInfoPage: 'P006',
  beneficiaryExclusionPage: 'P007',
  petsInfoPage: 'P008',
  nonProbateAssetsPage: 'P009',
  digitalAssetsPage: 'P010',
  specificBequestsPage: 'P011',
  charitiesPage: 'P012',
  residuaryEstatePage: 'P013',
  executorPage: 'P014',
  lastWillProvision: 'P015',
  funeralInfoPage: 'P016',
  specialInstructionsPage: 'P017',
};
