import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useInvoiceApi from '../../api/invoice.api';
import { IInvoicePayload } from '../../datatypes/Invoice';
import { deleteEmptyKeys } from '../../helpers/common';

const initialState: IInvoicePayload = {
  invoiceList: {
    loading: false,
    data: {
      data: [],
      current_page: 0,
      first_page_url: '',
      from: 0,
      last_page: 0,
      path: '',
      per_page: 0,
      prev_page_url: '',
      to: 0,
      total: 0,
    },
    error: false,
  },
  invoiceDetails: { loading: false, data: null, error: false },
};

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoices.pending, (state) => {
        state.invoiceList.loading = true;
      })
      .addCase(fetchInvoices.fulfilled, (state, { payload }) => {
        state.invoiceList.data = payload?.invoices;
        state.invoiceList.loading = false;
      })
      .addCase(fetchInvoices.rejected, (state) => {
        state.invoiceList.loading = false;
        state.invoiceList.error = true;
      })
      .addCase(fetchInvoiceDetails.pending, (state) => {
        state.invoiceDetails.loading = true;
      })
      .addCase(fetchInvoiceDetails.fulfilled, (state, { payload }) => {
        state.invoiceDetails.data = payload?.invoice;
        state.invoiceDetails.loading = false;
      })
      .addCase(fetchInvoiceDetails.rejected, (state) => {
        state.invoiceDetails.loading = false;
        state.invoiceDetails.error = true;
      });
  },
});

// Async API Hooks
/**
 * Fetch Invoice List from API and save in redux store
 */
export const fetchInvoices = createAsyncThunk(
  'invoice/fetchInvoices',
  async (data: any, { rejectWithValue }) => {
    let filters = JSON.parse(JSON.stringify(data.params));
    filters = deleteEmptyKeys(filters);
    const response = await useInvoiceApi().getInvoices(filters);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response?.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);
/**
 * Fetch Invoice Details for the from API and save in redux store
 */
export const fetchInvoiceDetails = createAsyncThunk(
  'invoice/fetchInvoiceDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useInvoiceApi().getInvoiceDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response?.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export default invoiceSlice.reducer;
