import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { IRouteParams } from '../../datatypes/CommonTypes';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import { fetchWillCreatorDetails } from '../../redux/slices/willCreatorSlice';
import { Link } from 'react-router-dom';

const WillCreatorDetails = () => {
  //route related hooks
  const navigate = useNavigate();
  const location = useLocation();

  const { id }: IRouteParams = useParams();

  //redux
  const dispatch = useAppDispatch();

  const { willCreatorDetails: willStatus } = useAppSelector(
    (state) => state.willStatus
  );

  const willStatusDetails = willStatus.data;

  const getWillStatusDetailsData = () => {
    if (id) {
      dispatch(fetchWillCreatorDetails(id));
    }
  };

  //handle getting data on page load.
  useEffect(() => {
    getWillStatusDetailsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="living-will-details profile-details">
      <PageTitle
        heading={`View Will Status Of 
        ${
          willStatus.loading
            ? 'Loading...'
            : willStatusDetails?.first_name +
                ' ' +
                willStatusDetails?.middle_name +
                ' ' +
                willStatusDetails?.last_name +
                ' ( ' +
                willStatusDetails?.id +
                ' )' ?? 'N/A'
        }
        `}
        buttonName={location.state?.source ? 'Dashboard' : 'Listing'}
        buttonClick={() =>
          navigate(location.state?.source ? '/' : '/will-creators')
        }
      />
      {willStatus.loading ? (
        <Loader isFull />
      ) : !!willStatusDetails ? (
        <div>
          <table className="living-will-details-table table table-hover table-bordered">
            <tbody>
              <tr>
                <th colSpan={4}>
                  <div className="table-title">
                    <h4>Personal Information</h4>
                  </div>
                </th>
              </tr>
              <tr>
                <th className="text-left">Email:</th>
                <td width="30%">
                  {willStatusDetails?.email && willStatusDetails?.email !== ''
                    ? willStatusDetails?.email
                    : 'N/A'}
                </td>
                <th className="text-left">Full Name:</th>
                <td width="30%">
                  {`${willStatusDetails?.first_name} ${willStatusDetails?.last_name}`}
                </td>
              </tr>
              <tr>
                <th className="text-left">Address:</th>
                <td colSpan={3}>
                  {willStatusDetails?.address_1 ?? ''}
                  {willStatusDetails?.address_2
                    ? ', ' + willStatusDetails?.address_2
                    : ''}
                  {willStatusDetails?.city
                    ? ', ' + willStatusDetails?.city
                    : ''}
                  {willStatusDetails?.county_name
                    ? ', ' + willStatusDetails?.county_name
                    : ''}
                  {willStatusDetails?.state_name
                    ? ', ' + willStatusDetails?.state_name
                    : ''}
                  {willStatusDetails?.country
                    ? ', ' + willStatusDetails?.country
                    : ''}
                  {willStatusDetails?.zipcode
                    ? ' - ' + willStatusDetails?.zipcode
                    : ''}
                </td>
              </tr>
              <tr>
                <th className="text-left">Date of Birth:</th>
                <td width="30%">
                  {willStatusDetails?.birthdate &&
                  willStatusDetails?.birthdate !== ''
                    ? willStatusDetails?.birthdate
                    : 'N/A'}
                </td>
                <th className="text-left">Gender:</th>
                <td width="30%">
                  {willStatusDetails?.gender && willStatusDetails?.gender !== ''
                    ? willStatusDetails?.gender
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <th className="text-left">Phone:</th>
                <td width="30%">
                  {willStatusDetails?.phone && willStatusDetails?.phone !== ''
                    ? willStatusDetails?.phone
                    : 'N/A'}
                </td>
              </tr>

              <tr>
                <th className="text-left">Will Status :</th>
                <td width="30%">
                  {' '}
                  {willStatusDetails?.status === 'in_progress' ? (
                    <span className="badge badge-secondary">In Progress</span>
                  ) : willStatusDetails?.status === 'finished' ? (
                    <span className="badge badge-success">Finished</span>
                  ) : (
                    <span className="badge badge-warning">Not Started</span>
                  )}
                </td>
                <th className="text-left">Document File:</th>
                <td width="30%">
                  {!willStatusDetails?.document_file ? (
                    'N/A'
                  ) : (
                    <Link
                      to={willStatusDetails?.document_file}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Available
                    </Link>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          {willStatusDetails?.document_file && (
            <div
              className="living-will-details-document"
              style={{ height: '50vw' }}
            >
              <iframe
                src={willStatusDetails?.document_file}
                width="100%"
                height="100%"
                className="pdf-preview-iframe"
                title="pdf-preview"
              />
            </div>
          )}
        </div>
      ) : (
        <NotFound
          heading="Create Will Details"
          subHeading="Create Will Details not found"
        />
      )}
    </div>
  );
};

export default WillCreatorDetails;
