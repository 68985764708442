import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ValidateField } from '../../helpers/ValidatorHelper';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import { IRouteParams } from '../../datatypes/CommonTypes';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import _forEach from 'lodash/forEach';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import TextInput from '../../components/common/TextInput';
import FileInput from '../../components/common/FileInput';
import {
  templateMetaDetails,
  metaDetailsValidations,
} from '../../constants/Meta';
import { IMetaDetails, IMetaPayload } from '../../datatypes/Meta';
import {
  fetchMetaDetails,
  saveUpdateMetaDetails,
} from '../../redux/slices/metaSlice';
import { Link } from 'react-router-dom';

const MetaDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id }: IRouteParams = useParams();
  const location = useLocation();

  const { metaDetails, saveUpdateMeta } = useAppSelector<IMetaPayload>(
    (state) => state.meta
  );

  const [pageType, setPageType] = useState<'new' | 'edit' | 'view'>('new');

  const [filePreview, setFilePreview] = useState<string>('');

  const [formData, setFormData] = useState<IMetaDetails>({
    ...templateMetaDetails,
  });

  const [errors, setErrors] = useState<IMetaDetails>({
    ...templateMetaDetails,
  });

  const validations = useMemo(() => {
    return {
      ...metaDetailsValidations,
    };
  }, []);

  //handle file upload to help with file preview
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const allowedFileExtensions = ['.png', '.jpeg', '.jpg'];

      // Get the file extension from the file name
      const fileExtension = file.name.substring(file.name.lastIndexOf('.'));

      if (allowedFileExtensions.includes(fileExtension.toLowerCase())) {
        const reader = new FileReader();

        //create and set file preview path
        reader.onload = (event) => {
          if (event.target && typeof event.target.result === 'string') {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
              // Access the image dimensions after it has loaded
              const imageWidth = img.naturalWidth;
              const imageHeight = img.naturalHeight;

              if (imageWidth > 1200 || imageHeight > 600) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  page_image:
                    'Please upload an image with dimensions less than 1200x600.',
                }));
              } else {
                setFilePreview(img.src);
                handleInputs(e);
              }
            };
          }
        };

        reader.readAsDataURL(file);
      } else {
        setFormData((prevData) => ({
          ...prevData,
          page_image: '',
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          page_image: 'Please upload a valid file type (jpeg, jpg, png).',
        }));
        setFilePreview('');
      }
    }
  };

  const getDisplayName = (key: string) => {
    switch (key) {
      case 'page_code':
        return 'Page Code';
      case 'page_title':
        return 'Page Title';
      case 'meta_title':
        return 'Meta Title';
      case 'meta_description':
        return 'Meta Description';
      case 'meta_keywords':
        return 'Meta Keywords';
      default:
        return key;
    }
  };

  //handle normal input
  const handleInputs = (e: any) => {
    const name = e.target.name;
    let value: string | number;

    // get value from checkbox / others
    if (e.target.type === 'checkbox') {
      value = e.target.checked ? e.target.defaultValue : '';
    } else {
      value = e.target.value;
    }

    const fieldName = getDisplayName(name);

    if (name !== 'page_image') {
      const error = ValidateField(fieldName, value, validations[name]) ?? '';

      //set errors
      if (error) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      }

      if (!value && !validations[name]?.includes('required')) {
        setErrors((prevData) => ({ ...prevData, [name]: '' }));
      }

      //set form data
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    } else {
      // input handling for file

      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      setFormData((prevData) => ({ ...prevData, [name]: e.target.files[0] }));
    }
  };

  //check if meta - form has any error
  const hasFormError = () => {
    let hasErrors = false;
    const newFormErrors = JSON.parse(JSON.stringify(errors));
    _forEach(formData, (value, key) => {
      const displayName = getDisplayName(key);
      const error = ValidateField(displayName, value, validations[key]);
      if (error && key !== 'meta_keywords') {
        newFormErrors[key] = error;
        hasErrors = true;
      } else if (error && key === 'meta_keywords' && value !== '') {
        newFormErrors[key] = error;
        hasErrors = true;
      } else {
        newFormErrors[key] = '';
      }
    });
    setErrors(newFormErrors);
    return hasErrors;
  };

  //handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!hasFormError()) {
      let payload = { data: { ...formData }, id: id };
      dispatch(saveUpdateMetaDetails(payload));
    }
  };

  // function to get meta details for the page
  const getMetaPageDetails = async () => {
    if (id && id !== 'new') {
      dispatch(fetchMetaDetails(id));
      const containsView = location.pathname.includes('view');
      if (containsView) {
        setPageType('view');
      } else {
        setPageType('edit');
      }
    }
  };

  // Component to render Edit button in page title if currently in view state.
  const EditButtonComponent = () => {
    if (pageType === 'view') {
      return (
        <Button
          variant="primary"
          type="button"
          onClick={() => navigate(`/meta-tags/${id}/edit`)}
        >
          Edit
        </Button>
      );
    } else {
      return null;
    }
  };

  // on meta details for page load
  useEffect(() => {
    setFormData(templateMetaDetails);
    setErrors(templateMetaDetails);
    getMetaPageDetails();

    // eslint-disable-next-line
  }, []);

  // on successful update / save
  useEffect(() => {
    if (saveUpdateMeta?.success) {
      toastSuccessMessage(
        `Meta Details ${
          pageType === 'edit' ? 'updated' : 'saved'
        } successfully!`
      );
      navigate('/meta-tags');
    }
    // eslint-disable-next-line
  }, [saveUpdateMeta]);

  // on successful fetch of page meta details
  useEffect(() => {
    if (metaDetails?.success && metaDetails?.data) {
      setFormData({
        ...metaDetails.data,
        meta_description: metaDetails.data.meta_description ?? '',
        meta_keywords: metaDetails.data.meta_keywords ?? '',
      });
      setFilePreview(String(metaDetails.data.page_image ?? ''));
      setErrors({
        ...templateMetaDetails,
      });
    }
    // eslint-disable-next-line
  }, [metaDetails]);

  return (
    <div className="meta-details">
      <PageTitle
        heading={
          pageType === 'new'
            ? 'New Meta Details'
            : ` Meta Details: ${metaDetails?.data?.page_title ?? ''}`
        }
        buttonName="Listing"
        buttonClick={() => navigate('/meta-tags')}
        customButton={<EditButtonComponent />}
      />
      {metaDetails.loading ? (
        <Loader isFull />
      ) : !!metaDetails ? (
        <Card>
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body>
              <Row>
                <Col lg={7} md={12}>
                  <Row className="align-items-center">
                    <Col lg={6} md={12}>
                      <TextInput
                        controlId="page_code"
                        label="Page Code"
                        className="form-field"
                        placeholder="Page Code"
                        name="page_code"
                        value={formData.page_code}
                        onChange={(e) => handleInputs(e)}
                        errorMessage={errors?.page_code}
                        isRequired={true}
                        isDisabled={pageType === 'view'}
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      <TextInput
                        controlId="page_title"
                        label="Page Title"
                        className="form-field"
                        placeholder="Page Title"
                        name="page_title"
                        value={formData.page_title}
                        onChange={(e) => handleInputs(e)}
                        errorMessage={errors?.page_title}
                        isRequired={true}
                        isDisabled={pageType === 'view'}
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col lg={pageType === 'view' ? 12 : 6} md={12}>
                      <TextInput
                        controlId="meta_title"
                        label="Meta Title"
                        className="form-field"
                        placeholder="Meta Title"
                        name="meta_title"
                        value={formData.meta_title}
                        onChange={(e) => handleInputs(e)}
                        errorMessage={errors?.meta_title}
                        isRequired={true}
                        isDisabled={pageType === 'view'}
                      />
                    </Col>
                    {pageType !== 'view' && (
                      <Col lg={6} md={12}>
                        <FileInput
                          controlId="page_image"
                          label="Page Image"
                          className="form-field"
                          placeholder="Page Image"
                          name="page_image"
                          value={formData.page_image}
                          onChange={(e) => handleFileChange(e)}
                          errorMessage={String(errors?.page_image)}
                          isRequired={false}
                        />
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col lg={12} md={12}>
                      <TextInput
                        controlId="meta_description"
                        label="Meta Description"
                        className="form-field"
                        placeholder="Meta Description"
                        name="meta_description"
                        value={formData.meta_description}
                        onChange={(e) => handleInputs(e)}
                        errorMessage={errors?.meta_description}
                        isRequired={false}
                        type="textarea"
                        rows={4}
                        isDisabled={pageType === 'view'}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={5} md={12} className="meta-page-image-section">
                  {filePreview ? (
                    <div className="meta-page-image-wrapper">
                      <img
                        src={filePreview}
                        className="meta-page-image"
                        alt={formData.meta_title}
                      />
                    </div>
                  ) : (
                    <div className="meta-page-image-NA">
                      Image Not Available
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12}>
                  <TextInput
                    controlId="meta_keywords"
                    label="Meta Keywords"
                    className="form-field"
                    placeholder="Meta Keywords"
                    name="meta_keywords"
                    value={formData.meta_keywords}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.meta_keywords}
                    isRequired={false}
                    type="textarea"
                    rows={4}
                    isDisabled={pageType === 'view'}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Row className="align-items-center">
                <Col lg={12} md={12}>
                  {pageType !== 'view' && (
                    <Button
                      variant="primary"
                      type="submit"
                      style={{ marginRight: '10px' }}
                      disabled={saveUpdateMeta?.loading}
                    >
                      {pageType === 'new' ? 'Save' : 'Update'}
                    </Button>
                  )}
                  <Link
                    className={`button-link secondary ${
                      saveUpdateMeta?.loading ? 'disabled' : ''
                    }`}
                    to="/meta-tags"
                  >
                    Cancel
                  </Link>
                </Col>
              </Row>
            </Card.Footer>
          </Form>
        </Card>
      ) : (
        <NotFound
          heading="Page Not Found"
          subHeading="Please try again after sometime"
        />
      )}
    </div>
  );
};

export default MetaDetails;
