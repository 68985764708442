import { useEffect, useState } from 'react';
import PageTitle from '../../components/common/PageTitle';
import SearchForm from '../../components/missing-wills/SearchForm';
import Listing from '../../components/missing-wills/Listing';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  fetchMissingWillSearches,
  resetMissingWillSearchData,
} from '../../redux/slices/missingWillSearchSlice';
import {
  searchMissingWillValidations,
  searchWillField,
  willListPagination,
} from '../../constants/MissingWillSearch';
import { ValidateField } from '../../helpers/ValidatorHelper';
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../components/common/ToastMessage';
import {
  IMissingWillSearchPayload,
  IShowDeleteModal,
  IWillPagination,
} from '../../datatypes/MisssingWillSearch';
import FowButton from '../../components/common/FowButton';
import { ISortParams } from 'src/datatypes/CommonTypes';

const MissingWillSearchListing = () => {
  const [searchWill, setSearchWill] = useState({ ...searchWillField });

  const [lastValidWillSearch, setLastValidWillSearch] = useState({
    ...searchWillField,
  });
  const [errors, setErrors] = useState({ ...searchWillField });
  const [showAdvanceSearch, setShowAdvanceSearch] = useState({
    show: false,
    filterApplied: false,
  });

  const [pagination, setPagination] =
    useState<IWillPagination>(willListPagination);
  const [sort, setSort] = useState<ISortParams>({
    orderBy: 'id',
    order: 'desc',
  });
  const [showDeleteModal, setShowDeleteModal] = useState<IShowDeleteModal>({
    show: false,
    idDetails: [],
  });

  const dispatch = useAppDispatch();
  const { willList, deleteWill } = useAppSelector<IMissingWillSearchPayload>(
    (state) => state.missingWill
  );

  const getWillListData = () => {
    dispatch(
      fetchMissingWillSearches({
        params: { ...pagination, ...lastValidWillSearch, ...sort },
      })
    );
  };

  const handleMultipleWillDelete = () => {
    if (showDeleteModal.idDetails.length > 0) {
      setShowDeleteModal((prev) => ({ ...prev, show: true }));
    } else {
      toastErrorMessage('Please select atleast one WILL to delete!');
    }
  };

  useEffect(() => {
    getWillListData();
    // eslint-disable-next-line
  }, [pagination, sort]);

  useEffect(() => {
    if (deleteWill.success) {
      toastSuccessMessage('Missing Will Search Deleted Successfully!');
      setShowDeleteModal({
        show: false,
        idDetails: [],
      });
      dispatch(resetMissingWillSearchData());
      getWillListData();
    }
    // eslint-disable-next-line
  }, [deleteWill]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchWill((searchWill) => ({ ...searchWill, [name]: value }));

    let tempValue: any = value;
    if (name === 'created_from') {
      tempValue = {
        value1: value,
        value2: searchWill.created_to,
      };
    } else if (name === 'created_to') {
      tempValue = {
        value1: value,
        value2: searchWill.created_from,
      };
    }

    const error =
      value === ''
        ? null
        : ValidateField(
            e.target.name,
            tempValue,
            searchMissingWillValidations?.[name]
          );

    setErrors((errors) => ({ ...errors, [name]: error || '' }));
  };

  const toggleAdvanceFilter = () => {
    setShowAdvanceSearch({
      ...showAdvanceSearch,
      show: !showAdvanceSearch.show,
    });
  };

  const toggleFilterApplied = (isApplied: boolean) => {
    setShowAdvanceSearch({
      ...showAdvanceSearch,
      filterApplied: isApplied,
    });
  };

  const validateForm = () => {
    let hasValuesInSearchWill = false;

    if (Object.keys(searchWill).some((key) => searchWill[key] !== '')) {
      hasValuesInSearchWill = true;
    } else if (
      JSON.stringify(lastValidWillSearch) !== JSON.stringify(searchWill)
    ) {
      hasValuesInSearchWill = true;
    }

    const hasErrors = Object.keys(errors).some((key) => errors[key] !== '');
    return !hasValuesInSearchWill || hasErrors;
  };

  const onWillSearch = () => {
    const hasError = validateForm();
    if (!hasError) {
      setLastValidWillSearch(searchWill);
      dispatch(fetchMissingWillSearches({ params: searchWill }));
      toggleFilterApplied(true);
    }
  };

  const resetWillSearch = () => {
    setLastValidWillSearch(searchWillField);
    dispatch(fetchMissingWillSearches({ params: willListPagination }));
    toggleFilterApplied(false);
    setSearchWill({ ...searchWillField });
  };

  return (
    <div className="missing-wills">
      <PageTitle heading="Missing Wills" />
      <div className="missing-wills-content">
        <div className="control-top-button mb-2">
          <FowButton
            variant="primary"
            className="mr-2"
            onClick={() => toggleAdvanceFilter()}
          >
            Advanced Search
          </FowButton>
          <FowButton variant="danger" onClick={handleMultipleWillDelete}>
            Delete
          </FowButton>
        </div>
        <div>
          {showAdvanceSearch.show && (
            <SearchForm
              errors={errors}
              handleInputChange={handleInputChange}
              searchWill={searchWill}
              onWillSearch={onWillSearch}
              resetWillSearch={resetWillSearch}
              isFilterApplied={showAdvanceSearch.filterApplied}
            />
          )}
          <Listing
            willList={willList}
            setPagination={setPagination}
            deleteWillDetail={deleteWill}
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            setSort={setSort}
          />
        </div>
      </div>
    </div>
  );
};

export default MissingWillSearchListing;
