import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useTerminologyApi from '../../api/terminology.api';
import {
  ITerminologyDetails,
  ITerminologyPayload,
} from '../../datatypes/Terminology';

// initial / template state of terminology redux slice
const initialState: ITerminologyPayload = {
  terminologyList: {
    loading: false,
    error: false,
    success: false,
    data: {
      current_page: 0,
      total: 0,
      data: [],
    },
  },
  terminologyDetails: {
    loading: false,
    error: false,
    success: false,
    data: {
      id: 0,
      term: '',
      description: '',
      alternate_term: '',
    },
  },
  saveUpdateStatus: {
    success: false,
    loading: false,
    error: false,
  },
  deleteTerminologyStatus: {
    success: false,
    loading: false,
    error: false,
  },
};

//redux slice for terminology
export const terminologySlice = createSlice({
  name: 'terminology',
  initialState,
  // reducers that don't require API calls
  reducers: {
    resetTerminologyData: (state) => {
      state.terminologyDetails = initialState.terminologyDetails;
      state.saveUpdateStatus = initialState.saveUpdateStatus;
      state.deleteTerminologyStatus = initialState.deleteTerminologyStatus;
    },
  },
  // reducers that require API calls
  extraReducers: (builder) => {
    builder
      .addCase(fetchTerminologies.pending, (state) => {
        state.terminologyList.loading = true;
        state.terminologyList.error = false;
        state.terminologyList.success = false;
        state.terminologyList.data = initialState.terminologyList.data;
      })
      .addCase(fetchTerminologies.fulfilled, (state, { payload }) => {
        state.terminologyList.data.data = payload.terminologies;
        state.terminologyList.data.current_page = payload.current_page ?? 0;
        state.terminologyList.data.total = payload.total ?? 0;
        state.terminologyList.loading = false;
        state.terminologyList.success = true;
      })
      .addCase(fetchTerminologies.rejected, (state) => {
        state.terminologyList.loading = false;
        state.terminologyList.error = true;
        state.terminologyList.data = initialState.terminologyList.data;
      })
      .addCase(fetchTerminologyDetails.pending, (state) => {
        state.terminologyDetails.loading = true;
        state.terminologyDetails.error = false;
        state.terminologyDetails.success = false;
        state.terminologyDetails.data = initialState.terminologyDetails.data;
      })
      .addCase(fetchTerminologyDetails.fulfilled, (state, { payload }) => {
        state.terminologyDetails.data = payload;
        state.terminologyDetails.loading = false;
        state.terminologyDetails.success = true;
      })
      .addCase(fetchTerminologyDetails.rejected, (state) => {
        state.terminologyDetails.loading = false;
        state.terminologyDetails.error = true;
        state.terminologyDetails.data = initialState.terminologyDetails.data;
      })
      .addCase(saveUpdateTerminology.pending, (state) => {
        state.saveUpdateStatus.loading = true;
        state.saveUpdateStatus.error = false;
        state.saveUpdateStatus.success = false;
      })
      .addCase(saveUpdateTerminology.fulfilled, (state) => {
        state.saveUpdateStatus.loading = false;
        state.saveUpdateStatus.success = true;
      })
      .addCase(saveUpdateTerminology.rejected, (state) => {
        state.saveUpdateStatus.loading = false;
        state.saveUpdateStatus.error = true;
      })
      .addCase(deleteTerminology.pending, (state) => {
        state.deleteTerminologyStatus.loading = true;
        state.deleteTerminologyStatus.error = false;
        state.deleteTerminologyStatus.success = false;
      })
      .addCase(deleteTerminology.fulfilled, (state) => {
        state.deleteTerminologyStatus.loading = false;
        state.deleteTerminologyStatus.success = true;
      })
      .addCase(deleteTerminology.rejected, (state) => {
        state.deleteTerminologyStatus.loading = false;
        state.deleteTerminologyStatus.error = true;
      });
  },
});

// Async API Hooks

/**
 * Fetch Terminology List from API and save in redux store
 */
export const fetchTerminologies = createAsyncThunk(
  'terminology/fetchTerminologies',
  async (data: any, { rejectWithValue }) => {
    const payload = JSON.parse(JSON.stringify(data));
    for (const key in payload.params) {
      if (payload.params[key] === '') {
        delete payload.params[key];
      }
    }

    const response = await useTerminologyApi().getTerminologies(payload);

    // The value we return becomes the `fulfilled` action payload
    if (response.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Terminology Details from API and save in redux store
 */
export const fetchTerminologyDetails = createAsyncThunk(
  'terminology/fetchTerminologyDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useTerminologyApi().getTerminologyById(id);

    // The value we return becomes the `fulfilled` action payload
    if (response.success === true) {
      return response?.terminology;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Save Terminology Details
 */
export const saveUpdateTerminology = createAsyncThunk(
  'terminology/saveUpdateTerminology',
  async (data: ITerminologyDetails, { rejectWithValue }) => {
    const payload = JSON.parse(JSON.stringify(data));
    const id = payload?.id;
    if (payload['id']) {
      delete payload['id'];
    }
    if (payload['created_at']) {
      delete payload['created_at'];
    }
    if (payload['updated_at']) {
      delete payload['updated_at'];
    }

    const response = id
      ? await useTerminologyApi().updateTerminology(payload, String(id))
      : await useTerminologyApi().saveTerminology(payload);

    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response?.success === true) {
      return response.terminology;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Delete Terminology
 */
export const deleteTerminology = createAsyncThunk(
  'terminology/deleteTerminology',
  async (id: string, { rejectWithValue }) => {
    const response = await useTerminologyApi().deleteTerminology(id);

    // The value we return becomes the `fulfilled` action payload
    if (response?.success === true) {
      return response.terminology;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const { resetTerminologyData } = terminologySlice.actions;

export default terminologySlice.reducer;
