import { isEmpty, startCase } from 'lodash';
import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';

const BeneficiaryExclusionAnswers = ({ answers }: any) => {
  const beneficiaryExclusionInformation =
    answers[pageCodes.beneficiaryExclusionPage];
  const childrenInformation = answers[pageCodes.childrenInfoPage];
  const children = childrenInformation[questionCodes.childrenNames];

  return (
    <div>
      <div>
        <table className="default-table table table-bordered">
          <tbody>
            <tr>
              <th colSpan={4} className="table-title">
                <h4>Beneficiary Exclusion</h4>
              </th>
            </tr>
            {childrenInformation[questionCodes.haveChildren][
              questionInputKeys.HAS_CHILDREN
            ] === 'yes' &&
              !isEmpty(
                beneficiaryExclusionInformation[questionCodes.excludeChildren][
                  questionInputKeys.EXCLUDE_CHILDREN
                ]
              ) && (
                <>
                  <tr>
                    <th className="w-40">
                      Do you wish to exclude any of your children?
                    </th>
                    <td>
                      {startCase(
                        beneficiaryExclusionInformation[
                          questionCodes.excludeChildren
                        ][questionInputKeys.EXCLUDE_CHILDREN]
                      )}
                    </td>
                    {beneficiaryExclusionInformation[
                      questionCodes.excludeChildren
                    ][questionInputKeys.EXCLUDE_CHILDREN] === 'yes' && (
                      <>
                        <th className="w-20">Excluded Children</th>
                        <td>
                          {beneficiaryExclusionInformation &&
                            beneficiaryExclusionInformation[
                              questionCodes.childrenToBeExcluded
                            ] &&
                            beneficiaryExclusionInformation[
                              questionCodes.childrenToBeExcluded
                            ][questionInputKeys.EXCLUDED_CHILDREN] &&
                            children &&
                            children
                              .filter((child: any) =>
                                beneficiaryExclusionInformation[
                                  questionCodes.childrenToBeExcluded
                                ][questionInputKeys.EXCLUDED_CHILDREN].includes(
                                  child.id
                                )
                              )
                              .map((child: any) =>
                                startCase(
                                  `${child[questionInputKeys.FIRSTNAME]} ${
                                    child[questionInputKeys.MIDDLENAME] ?? ''
                                  }${child[questionInputKeys.LASTNAME]}`
                                )
                              )
                              .join(', ')}
                        </td>
                      </>
                    )}
                  </tr>
                </>
              )}
            <tr>
              <th className="w-40">
                Do you wish to exclude anyone else from being a beneficiary?
              </th>
              <td>
                {startCase(
                  beneficiaryExclusionInformation[
                    questionCodes.excludeBenificiaryFromWill
                  ][questionInputKeys.EXCLUDE_BENEFICIARY]
                )}
              </td>
              {beneficiaryExclusionInformation &&
                beneficiaryExclusionInformation[
                  questionCodes.excludeBenificiaryFromWill
                ] &&
                beneficiaryExclusionInformation[
                  questionCodes.excludeBenificiaryFromWill
                ][questionInputKeys.EXCLUDE_BENEFICIARY] &&
                beneficiaryExclusionInformation[
                  questionCodes.excludeBenificiaryFromWill
                ][questionInputKeys.EXCLUDE_BENEFICIARY] === 'yes' && (
                  <>
                    <th>Other Beneficiaries Excluded</th>
                    <td>
                      {beneficiaryExclusionInformation &&
                        beneficiaryExclusionInformation[
                          questionCodes.excludedOtherBeneficiary
                        ] &&
                        beneficiaryExclusionInformation[
                          questionCodes.excludedOtherBeneficiary
                        ].length > 0 &&
                        beneficiaryExclusionInformation[
                          questionCodes.excludedOtherBeneficiary
                        ]
                          .map((child: any) =>
                            startCase(
                              `${child[questionInputKeys.FIRSTNAME]} ${
                                child[questionInputKeys.MIDDLENAME] ?? ''
                              }${child[questionInputKeys.LASTNAME]}`
                            )
                          )
                          .join(', ')}
                    </td>
                  </>
                )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BeneficiaryExclusionAnswers;
