import useAxiosService from '../services/axios.service';

const useTerminologyApi = () => {
  const { getUserHttpClient } = useAxiosService();

  // get terminologies list
  const getTerminologies = async (params?: any) => {
    return await getUserHttpClient()
      .get('/terminologies', params)
      .then((response) => {
        return response?.data;
      });
  };

  // get terminology by id
  const getTerminologyById = async (id: string) => {
    return await getUserHttpClient()
      .get(`/terminologies/${id}`)
      .then((response) => {
        return response?.data;
      });
  };

  // save new terminology
  const saveTerminology = async (data: any) => {
    return await getUserHttpClient()
      .post('/terminologies', data)
      .then((response) => {
        return response?.data;
      });
  };

  // update old terminology
  const updateTerminology = async (data: any, id: string) => {
    return await getUserHttpClient()
      .put(`/terminologies/${id}`, data)
      .then((response) => {
        return response?.data;
      });
  };

  // delete terminology
  const deleteTerminology = async (id: string) => {
    return await getUserHttpClient()
      .delete(`/terminologies/${id}`)
      .then((response) => {
        return response?.data;
      });
  };

  return {
    getTerminologies,
    getTerminologyById,
    saveTerminology,
    updateTerminology,
    deleteTerminology,
  };
};

export default useTerminologyApi;
