export const optionKeys = {
  SINGLE: 'single',
  MARRIED: 'married',
  PARTNERSHIP: 'domestic-partnership',
  CHILDREN: 'children',
  CUSTOMIZE_PLAN: 'customize',
  SPOUSE_CHILDREN: 'spouse_children',
  PARTNER_CHILDREN: 'partner_children',
  SPOUSE: 'spouse',
  PARTNER: 'partner',
  CHARITY: 'charity',
  CHILD: 'child',
  ALTERNATE: 'alternate',
  OTHER_BENEFICIARIES: 'other_beneficiaries',
};
