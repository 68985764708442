import React from 'react';
import { Col, Form } from 'react-bootstrap';
import ErrorMessage from './ErrorMessage';
import { OptionType } from '../../datatypes/CommonTypes';

interface IProps {
  name: string;
  value: string;
  label?: string;
  options: OptionType<string | number>[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  errorMessage?: string;
  controlId?: string;
  className?: string;
  isDisabled?: boolean;
}

const RadioGroupInput = ({
  value,
  label = '',
  onChange = () => {},
  isRequired = true,
  errorMessage = '',
  controlId = '',
  className = '',
  isDisabled = false,
  name,
  options,
}: IProps) => {
  return (
    <Form.Group controlId={controlId}>
      {label && (
        <div className="instruction-title">
          <Form.Label>
            {label}
            {isRequired && <span className="required">*</span>}
          </Form.Label>
        </div>
      )}
      <Form.Group
        as={Col}
        className={`d-flex mt-2 gender-lable flex-wrap ${className}`}
      >
        {options.map((option: any) => (
          <Form.Check
            className="mr-3"
            key={option.value + '_radio'}
            type="radio"
            id={option.value + '_radio'}
            name={name}
            disabled={isDisabled || option?.disabled}
          >
            <Form.Check.Input
              type="radio"
              name={name}
              checked={value === option?.value}
              value={option.value}
              onChange={onChange}
              disabled={isDisabled || option?.disabled}
            />
            <Form.Check.Label>&nbsp;{option.label}</Form.Check.Label>
          </Form.Check>
        ))}
      </Form.Group>
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </Form.Group>
  );
};

export default RadioGroupInput;
