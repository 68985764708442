import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import ErrorMessage from './ErrorMessage';
import { OptionType } from 'src/datatypes/CommonTypes';
import { filter, find } from 'lodash';

interface IProps {
  name: string;
  value: string | number | (string | number)[] | undefined | null;
  fieldKey?: string | number;
  options: any[];
  instructions?: string;
  isLoading?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
  optionLabel?: string | undefined;
  optionValue?: string | undefined;
  type?: string;
  label?: string;
  handleSelectChange: (e: any) => void;
  isRequired?: boolean;
  errorMessage?: string;
  controlId?: string;
  className?: string;
  placeholder?: string;
}

const CustomOption = (props: any) => (
  <components.Option {...props}>
    <Form.Check
      className="check-box d-flex align-items-center gap-2"
      checked={props.isSelected}
      readOnly={true}
      label={props.label}
    />
  </components.Option>
);

const SelectInput = ({
  fieldKey,
  name,
  value,
  label,
  handleSelectChange,
  isRequired,
  errorMessage,
  controlId,
  className,
  placeholder,
  options,
  instructions,
  isLoading,
  isMulti,
  isDisabled,
  optionLabel,
  optionValue,
}: IProps) => {
  const [selectedOptions, setSelectedOptions] = useState<
    string[] | number[] | null | undefined
  >([]);

  const [selectedOption, setSelectedOption] = useState<
    string | number | null | undefined
  >(null);

  const handleSelectInput = (e: any) => {
    let selectedValue;

    if (isMulti) {
      selectedValue = (e ?? []).map(
        (val: any) => val?.[optionValue || 'value']
      );
      setSelectedOptions(e);
    } else {
      selectedValue = e?.[optionValue || 'value'];
      setSelectedOption(e);
    }

    handleSelectChange({
      target: { value: selectedValue, name },
    });
  };

  useEffect(() => {
    if (value) {
      if (isMulti && Array.isArray(value)) {
        setSelectedOptions(
          filter(options, (v: OptionType<string | number>) =>
            value.includes(v.value as never)
          )
        );
      } else {
        setSelectedOption(
          find(options, { [optionValue || 'value']: value })?.[
            optionValue || 'value'
          ]
        );
      }
    }
  }, [value, isMulti, options, optionValue]);
  return (
    <Form.Group key={fieldKey} controlId={controlId}>
      <div className="instruction-title">
        <Form.Label>
          {label}
          {isRequired && <span className="required">*</span>}
        </Form.Label>
        {instructions && (
          <span className="instruction-text">({instructions})</span>
        )}
      </div>
      <Select
        placeholder={placeholder}
        classNamePrefix={`fow-select fow-custom-select ${
          errorMessage ? 'error-focus-input' : ''
        } ${className ? className : ''}`}
        isDisabled={isDisabled}
        isClearable={true}
        isLoading={isLoading}
        getOptionLabel={(option: any) => option?.[optionLabel || 'label']}
        getOptionValue={(option: any) => option?.[optionValue || 'value']}
        isMulti={isMulti}
        isSearchable={true}
        options={options}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        value={
          isMulti
            ? selectedOptions
            : !!selectedOption
            ? options.find(
                (option) => option[optionValue || 'value'] === selectedOption
              )
            : ''
        }
        onChange={handleSelectInput}
        components={
          isMulti
            ? {
                Option: CustomOption,
              }
            : undefined
        }
        styles={{
          option: (provided: any, state: any) => {
            return {
              ...provided,
              color: state.isSelected ? '#00306a' : '#000',
              background: state.isSelected
                ? '#efefef !important'
                : '#fff !important',
              fontWeight: state.isSelected ? 'bold' : 'normal',
            };
          },
        }}
      />
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </Form.Group>
  );
};

export default SelectInput;
