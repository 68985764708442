import { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { dtCustomStyle } from '../../helpers/DataTableHelper';
import {
  IShowDeleteCharityCategoryModal,
  ICharityCategoryRow,
  ICharityCategoryPayload,
  ICharityCategoryColumn,
} from '../../datatypes/CharityCategory';
import { useAppDispatch } from '../../redux/hooks';
import DeleteModal from './DeleteModal';
import { deleteCharityCategoryDetails } from '../../redux/slices/charityCategorySlice';
import { PageOptions } from '../../constants/Common';
import { Link } from 'react-router-dom';

interface IProps {
  charityCategoryList: ICharityCategoryPayload['charityCategoryList'];
  deleteCharityDetails: ICharityCategoryPayload['deleteCharityCategory'];
  showDeleteModal: IShowDeleteCharityCategoryModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeleteCharityCategoryModal>
  >;
}

const Listing = ({
  charityCategoryList,
  deleteCharityDetails,
  showDeleteModal,
  setShowDeleteModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  // switch for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteCharityCategoryModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  // handle delete testimonial
  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map(
        (charityCategoryDetail: ICharityCategoryRow) => charityCategoryDetail.id
      )
      .join(',');
    dispatch(deleteCharityCategoryDetails(selectedIds));
  };

  //columns for listing
  const columns: ICharityCategoryColumn[] = [
    {
      name: 'Name',
      selector: (row: ICharityCategoryRow) => row.name,
      sortable: true,
    },
    {
      name: 'Slug',
      selector: (row: ICharityCategoryRow) => row.meta_title,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row: ICharityCategoryRow) => row.status,
      sortable: true,
      style: {
        paddingRight: '20px',
      },
      compact: true,
      center: true,
      cell: (row: ICharityCategoryRow) => {
        if (row.status === 'active') {
          return (
            <div className="pricing-status">
              <i className="fa fa-check"></i>
            </div>
          );
        } else {
          return (
            <div className="pricing-status">
              <i className="fa fa-times"></i>
            </div>
          );
        }
      },
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: ICharityCategoryRow) => row.actions,
      center: true,
      compact: true,
      width: '8.5rem',
      cell: (row: ICharityCategoryRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <Link
            title="View"
            className="btn btn-secondary btn-sm"
            to={`/charity-categories/${row?.id}/view`}
            key="view"
          >
            <i className="fa fa-eye"></i>
          </Link>
          <Link
            title="Edit"
            className="btn btn-primary btn-sm ml-2"
            to={`/charity-categories/${row.id}/edit`}
            key="edit"
          >
            <i className="fa fa-pencil"></i>
          </Link>
        </div>
      ),
    },
  ];

  //handle delete modal close on success delete
  useEffect(() => {
    if (deleteCharityDetails?.success) {
      setShowDeleteModal((prev: IShowDeleteCharityCategoryModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteCharityDetails?.success]);

  return (
    <Row className="mt-3">
      <Col lg={12}>
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              keyField="id"
              progressPending={charityCategoryList.loading}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={charityCategoryList.data?.total}
              data={charityCategoryList.data?.data}
              customStyles={dtCustomStyle}
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          toggleDeleteModal={toggleDeleteModal}
          selectedIdDetails={showDeleteModal.idDetails}
          handleSubmit={handleDelete}
          deleteCharityCategory={deleteCharityDetails}
        />
      </Col>
    </Row>
  );
};

export default Listing;
