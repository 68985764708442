import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useMetaApi from '../../api/meta.api';
import { IMetaPayload, ISaveUpdateMeta } from '../../datatypes/Meta';

// initial / template state of meta redux slice
const initialState: IMetaPayload = {
  metaList: {
    loading: false,
    error: false,
    success: false,
    data: {
      data: [],
      current_page: 0,
      total: 0,
    },
  },
  metaDetails: {
    loading: false,
    error: false,
    success: false,
    data: null,
  },
  saveUpdateMeta: {
    loading: false,
    error: false,
    success: false,
  },
  deleteMetaStatus: {
    loading: false,
    error: false,
    success: false,
  },
};

//redux slice for meta tags
export const metaSlice = createSlice({
  name: 'meta',
  initialState,
  // reducers that don't require API calls
  reducers: {
    resetMetaData: (state: IMetaPayload) => {
      state.metaDetails = initialState.metaDetails;
      state.metaList = initialState.metaList;
      state.saveUpdateMeta = initialState.saveUpdateMeta;
      state.deleteMetaStatus = initialState.deleteMetaStatus;
    },
  },
  // reducers that require API calls
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetas.pending, (state) => {
        state.metaList.loading = true;
        state.metaList.error = false;
        state.metaList.success = false;
      })
      .addCase(fetchMetas.fulfilled, (state, { payload }) => {
        state.metaList.data.data = payload.metadata;
        state.metaList.data.current_page = payload?.current_page ?? 0;
        state.metaList.data.total = payload?.total ?? 0;
        state.metaList.loading = false;
        state.metaList.success = true;
      })
      .addCase(fetchMetas.rejected, (state) => {
        state.metaList.loading = false;
        state.metaList.error = true;
      })
      .addCase(fetchMetaDetails.pending, (state) => {
        state.metaDetails.loading = true;
        state.metaDetails.error = false;
        state.metaDetails.success = false;
      })
      .addCase(fetchMetaDetails.fulfilled, (state, { payload }) => {
        state.metaDetails.data = payload;
        state.metaDetails.loading = false;
        state.metaDetails.success = true;
      })
      .addCase(fetchMetaDetails.rejected, (state) => {
        state.metaDetails.loading = false;
        state.metaDetails.error = true;
      })
      .addCase(saveUpdateMetaDetails.pending, (state) => {
        state.saveUpdateMeta.loading = true;
        state.saveUpdateMeta.error = false;
        state.saveUpdateMeta.success = false;
      })
      .addCase(saveUpdateMetaDetails.fulfilled, (state) => {
        state.saveUpdateMeta.loading = false;
        state.saveUpdateMeta.success = true;
      })
      .addCase(saveUpdateMetaDetails.rejected, (state) => {
        state.saveUpdateMeta.loading = false;
        state.saveUpdateMeta.error = true;
      })
      .addCase(deleteMeta.pending, (state) => {
        state.deleteMetaStatus.loading = true;
        state.deleteMetaStatus.error = false;
        state.deleteMetaStatus.success = false;
      })
      .addCase(deleteMeta.fulfilled, (state) => {
        state.deleteMetaStatus.loading = false;
        state.deleteMetaStatus.success = true;
      })
      .addCase(deleteMeta.rejected, (state) => {
        state.deleteMetaStatus.loading = false;
        state.deleteMetaStatus.error = true;
      });
  },
});

// Async API Hooks

/**
 * Fetch Meta List from API and save in redux store
 */
export const fetchMetas = createAsyncThunk(
  'meta/fetchMetas',
  async (_, { rejectWithValue }) => {
    const response = await useMetaApi().getMetas();
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Meta Details from API and save in redux store
 */
export const fetchMetaDetails = createAsyncThunk(
  'meta/fetchMetaDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useMetaApi().getMetaDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response.data.metaDetail;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Save or Update Meta Details
 */
export const saveUpdateMetaDetails = createAsyncThunk(
  'meta/saveUpdateMeta',
  async (data: ISaveUpdateMeta, { rejectWithValue }) => {
    const payload = JSON.parse(JSON.stringify(data.data));
    const id = data?.id;

    if (typeof data.data.page_image !== 'object' || !data.data.page_image) {
      delete payload.page_image;
    } else {
      payload.page_image = data.data.page_image;
    }
    if (id) {
      delete payload.id;
    }

    const response = id
      ? await useMetaApi().updateMetaDetails(payload, id)
      : await useMetaApi().saveMetaDetails(payload);

    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Delete Meta API
 */
export const deleteMeta = createAsyncThunk(
  'meta/deleteMeta',
  async (id: string, { rejectWithValue }) => {
    const response = await useMetaApi().deleteMetaDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const { resetMetaData } = metaSlice.actions;

export default metaSlice.reducer;
