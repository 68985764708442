import useAxiosService from '../services/axios.service';

const useFaqApi = () => {
  const { getUserHttpClient } = useAxiosService();

  // get faqs list
  const getFaqs = async (params?: any) => {
    return await getUserHttpClient()
      .get('/faqs', params)
      .then((response) => {
        return response?.data;
      });
  };

  // get terminology by id
  const getFaqById = async (id: string) => {
    return await getUserHttpClient()
      .get(`/faqs/${id}`)
      .then((response) => {
        return response?.data;
      });
  };

  // save new terminology
  const saveFaq = async (data: any) => {
    return await getUserHttpClient()
      .post('/faqs', data)
      .then((response) => {
        return response?.data;
      });
  };

  // update old terminology
  const updateFaq = async (data: any, id: string) => {
    return await getUserHttpClient()
      .put(`/faqs/${id}`, data)
      .then((response) => {
        return response?.data;
      });
  };

  // delete terminology
  const deleteFaq = async (id: string) => {
    return await getUserHttpClient()
      .delete(`/faqs/${id}`)
      .then((response) => {
        return response?.data;
      });
  };

  return {
    getFaqs,
    getFaqById,
    saveFaq,
    updateFaq,
    deleteFaq,
  };
};

export default useFaqApi;
