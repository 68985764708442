import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useAnswersApi from 'src/api/answers.api';
import { IAnswersPayload } from 'src/datatypes/Answers';
// initial / template state of answers redux slice
const initialState: IAnswersPayload = {
  loading: false,
  error: false,
  success: false,
  data: null,
};

// redux slice for answers
export const answerSlice = createSlice({
  name: 'answers',
  initialState,
  // reducers that don't require API Calls
  reducers: {
    resetAnswerData: (state: IAnswersPayload) => {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
      state.data = initialState.data;
    },
  },
  // reducers that require API calls
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnswers.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(fetchAnswers.fulfilled, (state, { payload }: any) => {
        state.data = payload;
        state.loading = false;
        state.success = true;
      })
      .addCase(fetchAnswers.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

// Async API Hooks

/**
 * Fetch Question Details from API and save in redux store
 */
export const fetchAnswers = createAsyncThunk(
  'answers/fetchAnswers',
  async (userId: string, { rejectWithValue }) => {
    const response = await useAnswersApi().getAnswers(userId);
    // The value we return becomes the `fulfilled` action payload
    if (response.success === 200 || response.success === true) {
      let answerData = {};
      if (response && response.answers && response.answers.length > 0) {
        response.answers.forEach((pageAnswer: any) => {
          answerData = {
            ...answerData,
            [pageAnswer.page_code]: pageAnswer.answer,
          };
        });
        return answerData;
      }
      return answerData;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const { resetAnswerData } = answerSlice.actions;

export default answerSlice.reducer;
