export const questionInputKeys = {
  FIRSTNAME: 'firstname',
  MIDDLENAME: 'middlename',
  LASTNAME: 'lastname',
  GENDER: 'gender',
  OTHERGENDER: 'otherGender',
  BIRTHDATE: 'birthdate',
  PHONE: 'phone',

  ADDRESS1: 'address1',
  ADDRESS2: 'address2',
  CITY: 'city',
  STATE: 'state',
  ZIPCODE: 'zipcode',
  COUNTY: 'county',
  ASSETS_VALUE: 'assetsValue',

  MARITAL_STATUS: 'marital_status',
  HAS_CHILDREN: 'hasChildren',
  CHILDREN: 'children',
  HAS_FUTURE_CHILDREN: 'hasFutureChildren',
  IS_DISABLED: 'isDisabled',

  EXCLUDE_CHILDREN: 'excludeChildren',
  EXCLUDED_CHILDREN: 'excludedChildren',
  EXCLUDE_BENEFICIARY: 'excludeBeneficiary',
  SHOULD_DESCENDENT_INHERIT_PORTION: 'inherit_descendent_portion',

  HAS_FINANCIAL_GUARDIAN: 'hasFinancialGuardian',
  SHOULD_FINANCIAL_GUARDIAN_RECEIVE_COMPENSATION:
    'shouldFinancialGuardianReceiveCompensation',
  HAS_PETS: 'hasPets',
  PETS: 'pets',
  PET_NAME_DESC: 'petNameDesc',
  PET_TYPE: 'petType',

  HAS_NON_PROBATE_ASSETS: 'hasNonProbateAssets',
  INSURANCE_COMPANY: 'insurance_company',
  INSURANCE_AMOUNT: 'insurance_amount',
  INSURANCE_NUMBER: 'insurance_no',
  NEED_INSURANCE_INFO: 'needInsuranceInfo',

  WANT_TO_DONATE: 'wantToDonate',
  INFORM_CHARITY: 'informCharity',
  NEED_MORE_CHARITY_INFO: 'needMoreCharityInfo',
  PERSON: 'person',
  BENEFICIARY_TYPE: 'beneficiary_type',

  PRIMARY_FIRSTNAME: 'primary_firstname',
  PRIMARY_LASTNAME: 'primary_lastname',
  PRIMARY_RELATIONSHIP: 'primary_relationship',
  ALTERNATE_FIRSTNAME: 'alternate_firstname',
  ALTERNATE_LASTNAME: 'alternate_lastname',
  ALTERNATE_RELATIONSHIP: 'alternate_relationship',

  PERSON_FIRSTNAME: 'person_firstname',
  PERSON_LASTNAME: 'person_lastname',
  RELATIONSHIP: 'relationship',

  HAS_DIGITAL_ASSETS: 'allocateDigitalAssets',
  HAS_SPECIFIC_BEQUESTS: 'hasSpecificBequests',

  CHARITY_PORTIONS: 'charityPortions',
  CHARITY: 'charity',
  CHARITY_NAME: 'charity_name',
  UNIT: 'unit',
  PORTION: 'portion',
  RESIDUARY_PB: 'residuaryEstate00',
  RESIDUARY_WHEN_PB_SPOUSE: 'residuaryEstate10',
  RESIDUARY_WHEN_PB_PARTNER: 'residuaryEstate20',
  RESIDUARY_WEHN_PB_CHILDREN: 'residuaryEstate30',
  RESIDUARY_When_PB_CUSTOM: 'residuaryEstate40',
  RESIDUARY_When_PB_SPOUSE_CHILDREN: 'residuaryEstate50',
  RESIDUARY_When_PB_PARTNER_CHILDREN: 'residuaryEstate60',

  RESIDUARY_WHEN_PB_SPOUSE_SB_CHILDREN: 'residuaryEstate11',
  RESIDUARY_WHEN_PB_SPOUSE_SB_CHARITY: 'residuaryEstate12',
  RESIDUARY_WHEN_PB_SPOUSE_SB_CUSTOM: 'residuaryEstate13',
  RESIDUARY_WHEN_PB_SPOUSE_SB_CHILDREN_TB_CHARITY: 'residuaryEstate14',
  RESIDUARY_WHEN_PB_SPOUSE_SB_CHILDREN_TB_CUSTOM: 'residuaryEstate15',

  RESIDUARY_WHEN_PB_PARTNER_SB_CHILDREN: 'residuaryEstate21',
  RESIDUARY_WHEN_PB_PARTNER_SB_CHARITY: 'residuaryEstate22',
  RESIDUARY_WHEN_PB_PARTNER_SB_CUSTOM: 'residuaryEstate23',
  RESIDUARY_WHEN_PB_PARTNER_SB_CHILDREN_TB_CHARITY: 'residuaryEstate24',
  RESIDUARY_WHEN_PB_PARTNER_SB_CHILDREN_TB_CUSTOM: 'residuaryEstate25',

  RESIDUARY_WHEN_PB_CUSTOM_SB_ALTERNATE: 'residuaryEstate41',
  RESIDUARY_WHEN_PB_CUSTOM_SB_ALTERNATE_TB_CUSTOM: 'residuaryEstate42',
  RESIDUARY_WHEN_PB_SPC_SB_CHARITY: 'residuaryEstate51',

  SHOULD_EXECUTOR_RECEIVE_COMPENSATION: 'wantToGiveExecutorCompensation',
  COMPENSATION_UNIT: 'compensation_unit',
  COMPENSATION_VALUE: 'compensation_value',

  HAS_FUNERAL_ARRANGEMENTS: 'hasFuneralPlan',
  FUNERAL_HOME_NAME: 'funeral_home_name',
  FUNERAL_HOME_CITY: 'city',
  FUNERAL_HOME_STATE: 'state',
  FUNDERAL_DETAIL: 'funeral_detail',
  RECEIVE_FUNERAL_PLANNING_INFORMATION: 'needFuneralPlanning',
  CONTEST_PROVISION: 'contest_provision',
  SURVIVAL_REQUIREMENT: 'survival_requirement',
  SPECIAL_INSTRUCTIONS: 'special_instructions',
  EIN: 'ein_number',
};
