import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ZipcodeListing = () => {
  return (
    <Card>
      <Card.Body>
        <div className="">
          <Link to="/zipcodes/new" className="button-link">
            <i className="fa fa-plus"></i> NEW
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ZipcodeListing;
