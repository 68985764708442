import { Spinner } from 'react-bootstrap';

interface IProps {
  isFull?: boolean;
}

const Loader = ({ isFull }: IProps) => {
  return (
    <div className={isFull ? 'background-loader' : 'loader'}>
      <Spinner
        as="span"
        animation="border"
        role="status"
        aria-hidden="true"
        variant="primary"
      />
    </div>
  );
};

export default Loader;
