import { OptionType } from '../datatypes/CommonTypes';
import {
  IUserDetails,
  IUsersSearch,
  IAdminValidations,
} from '../datatypes/User';

// template for admin user fields
export const templateAdminUserDetailsField: IUserDetails = {
  first_name: '',
  middle_name: '',
  last_name: '',
  email: '',
  password: '',
  birthdate: '',
  gender: '',
  city: '',
  state: '',
  county: '',
  country: 'United States',
  address_1: '',
  address_2: '',
  zipcode: '',
  phone: '',
  bio: '',
  confirmed: 1,
  user_type: 'admin',
};

// template for admin validations
export const adminUserDetailsValidations: IAdminValidations = {
  first_name: 'required|nameValidate|min:2|max:50',
  middle_name: 'nameValidate|min:2|max:50',
  last_name: 'required|nameValidate|min:2|max:50',
  email: 'required|email',
  password: 'required|min:8|max:50',
  birthdate: 'birthdate',
  gender: 'required',
  city: 'required|min:2|max:50',
  state: 'required',
  county: 'required',
  country: 'required|min:2|max:50',
  address_1: 'max:50',
  address_2: 'max:50',
  zipcode: 'zipcode',
  phone: 'mobile',
  bio: 'max:500',
  user_type: 'required',
  confirmed: 'required',
};

// template for pagination
export const usersListPagination = {
  per_page: 50,
  page: 1,
};

// template for search users
export const usersSearchField: IUsersSearch = {
  first_name: '',
  last_name: '',
  state: '',
};

// template for search validations
export const usersSearchValidations: IUsersSearch = {
  first_name: 'nameValidate',
  last_name: 'nameValidate',
  state: '',
};

// options for gender
export const genderOptions: OptionType<string>[] = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

// Options for Marital Status
export const maritalStatusOptions: OptionType<string>[] = [
  {
    label: 'Single',
    value: 'single',
  },
  {
    label: 'Married',
    value: 'married',
  },
  {
    label: 'Domestic Partnership',
    value: 'domestic-partnership',
  },
];

// Options for Children status
export const hasChildrenOptions: OptionType<string>[] = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

// Options for Charity preferences selection
export const charityOptions: OptionType<string>[] = [
  {
    label: 'Specific Charity',
    value: 'specific_charity',
  },
  {
    label: 'Any Charity',
    value: 'any_charity',
  },
  {
    label: 'No Charity',
    value: 'no_charity',
  },
];

export const willStatusOptions: OptionType<string>[] = [
  {
    value: 'finished',
    label: 'Completed',
  },
  {
    value: 'in_progress',
    label: 'In Progress',
  },
  {
    value: 'updating',
    label: 'Updating',
  },
  {
    value: 'all',
    label: 'All',
  },
];
