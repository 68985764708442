import TextInput from '../common/TextInput';
import FowButton from '../common/FowButton';
import React, { useEffect } from 'react';
import { IDeathNoticeField } from '../../datatypes/DeathNotice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchStates } from '../../redux/slices/locationSlice';
import SelectInput from '../common/SelectInput';
import { ILocationPayload } from '../../datatypes/Location';

interface IProps {
  deathNoticeSearch: IDeathNoticeField;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDeathNoticeSearch: () => void;
  resetDeathNoticeSearch: () => void;
  isFilterApplied: boolean;
  errors: IDeathNoticeField;
}

const DeathNoticeSearch = ({
  deathNoticeSearch,
  handleInputChange,
  onDeathNoticeSearch,
  resetDeathNoticeSearch,
  isFilterApplied,
  errors,
}: IProps) => {
  const dispatch = useAppDispatch();
  const stateOptions = useAppSelector<ILocationPayload['states']['data']>(
    (state) => state.location.states.data
  );
  const stateLoading = useAppSelector<
    ILocationPayload['states']['stateLoading']
  >((state) => state.location.states.stateLoading);

  useEffect(() => {
    dispatch(fetchStates());
    // eslint-disable-next-line
  }, []);
  return (
    <div className="missing-wills-form">
      <div className="row">
        <div className="col-lg-4">
          <TextInput
            name="first_name"
            placeholder="First Name"
            value={deathNoticeSearch.first_name}
            onChange={(e) => handleInputChange(e)}
            label="First Name"
            errorMessage={errors.first_name}
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <TextInput
            name="last_name"
            placeholder="Last Name"
            value={deathNoticeSearch.last_name}
            onChange={(e) => handleInputChange(e)}
            label="Last Name"
            errorMessage={errors.last_name}
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <SelectInput
            name="state_id"
            placeholder="Select State"
            value={deathNoticeSearch.state_id}
            optionLabel="state_name"
            optionValue="id"
            options={stateOptions}
            isLoading={stateLoading}
            handleSelectChange={(e) => handleInputChange(e)}
            label="Searcher State"
          />
        </div>
        <div className="col-lg-4">
          <TextInput
            name="created_date_from"
            placeholder="YYYY-MM-DD"
            value={deathNoticeSearch.created_date_from}
            onChange={(e) => handleInputChange(e)}
            label="Create Date From"
            type="date"
            errorMessage={errors.created_date_from}
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <TextInput
            name="created_date_to"
            placeholder="YYYY-MM-DD"
            value={deathNoticeSearch.created_date_to}
            onChange={(e) => handleInputChange(e)}
            label="Create Date To"
            type="date"
            errorMessage={errors.created_date_to}
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <TextInput
            name="city"
            placeholder="City"
            value={deathNoticeSearch.city}
            onChange={(e) => handleInputChange(e)}
            label="City"
            errorMessage={errors.city}
            isRequired={false}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-lg-12">
          <div className="d-flex gap-3 justify-content-between w-100">
            <FowButton variant="success" onClick={onDeathNoticeSearch}>
              <i className="fa fa-search mr-2"></i> Search
            </FowButton>
            {isFilterApplied && (
              <FowButton
                variant="outline-secondary"
                onClick={resetDeathNoticeSearch}
              >
                <i className="fa fa-undo mr-2"></i> Reset
              </FowButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeathNoticeSearch;
