import { useEffect, useState } from 'react';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import PageTitle from '../../components/common/PageTitle';
import {
  IShowDeleteLivingWillModal,
  IWillRegistrationPayload,
  IWillRegistrationPagination,
} from '../../datatypes/WillRegistration';
import {
  fetchWillRegistrations,
  resetWillRegistrationData,
} from '../../redux/slices/willRegistrationSlice';
import Listing from '../../components/will-registrations/Listing';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  InitialWillRegistrationSearchField,
  WillRegistrationListPagination,
  WillRegistrationSearchFieldValidations,
} from '../../constants/WillRegistration';
import { ValidateField } from '../../helpers/ValidatorHelper';
import FowButton from '../../components/common/FowButton';
import SearchForm from '../../components/will-registrations/SearchForm';
import { ISortParams } from 'src/datatypes/CommonTypes';

const LivingWillListing = () => {
  const [searchWill, setSearchWill] = useState({
    ...InitialWillRegistrationSearchField,
  });

  const [lastValidSearch, setLastValidSearch] = useState({
    ...InitialWillRegistrationSearchField,
  });

  const [errors, setErrors] = useState({
    ...InitialWillRegistrationSearchField,
  });
  const [showAdvanceSearch, setShowAdvanceSearch] = useState({
    show: false,
    filterApplied: false,
  });
  const dispatch = useAppDispatch();

  const { livingWillList, deleteWillRegistrationStatus } =
    useAppSelector<IWillRegistrationPayload>((state) => state.livingWill);

  const [pagination, setPagination] = useState<IWillRegistrationPagination>(
    WillRegistrationListPagination
  );

  const [sort, setSort] = useState<ISortParams>({
    orderBy: 'id',
    order: 'desc',
  });

  const [showDeleteModal, setShowDeleteModal] =
    useState<IShowDeleteLivingWillModal>({
      show: false,
      idDetails: [],
    });

  const getLivingWillListData = () => {
    console.log('lastValidSearch', lastValidSearch);
    dispatch(fetchWillRegistrations({ params: { ...pagination, ...sort } }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchWill((searchWill) => ({ ...searchWill, [name]: value }));

    let tempValue: any = value;
    if (name === 'created_from') {
      tempValue = {
        value1: value,
        value2: searchWill.created_to,
      };
    } else if (name === 'created_to') {
      tempValue = {
        value1: value,
        value2: searchWill.created_from,
      };
    }

    const error =
      value === ''
        ? null
        : ValidateField(
            e.target.name,
            tempValue,
            WillRegistrationSearchFieldValidations?.[name]
          );

    setErrors((errors) => ({ ...errors, [name]: error || '' }));
  };

  const toggleAdvanceFilter = () => {
    setShowAdvanceSearch({
      ...showAdvanceSearch,
      show: !showAdvanceSearch.show,
    });
  };

  const toggleFilterApplied = (isApplied: boolean) => {
    setShowAdvanceSearch({
      ...showAdvanceSearch,
      filterApplied: isApplied,
    });
  };

  const validateForm = () => {
    let hasValuesInSearchWill = false;

    if (Object.keys(searchWill).some((key) => searchWill[key] !== '')) {
      hasValuesInSearchWill = true;
    } else if (JSON.stringify(lastValidSearch) !== JSON.stringify(searchWill)) {
      hasValuesInSearchWill = true;
    }

    const hasErrors = Object.keys(errors).some((key) => errors[key] !== '');
    return !hasValuesInSearchWill || hasErrors;
  };

  const onWillSearch = () => {
    const hasError = validateForm();
    if (!hasError) {
      setLastValidSearch(searchWill);

      dispatch(
        fetchWillRegistrations({
          params: {
            ...searchWill,
            will_code: searchWill?.will_code
              ? 'WREG' + searchWill?.will_code
              : '',
            ...pagination,
            page: 1,
          },
        })
      );
      toggleFilterApplied(true);
    }
  };
  console.log('lastValidSearch', lastValidSearch);

  const resetWillSearch = () => {
    setLastValidSearch(InitialWillRegistrationSearchField);
    dispatch(fetchWillRegistrations({ params: { ...pagination, ...sort } }));
    toggleFilterApplied(false);
    setSearchWill({ ...InitialWillRegistrationSearchField });
  };

  //handle successful delete api call and modal closing
  useEffect(() => {
    if (deleteWillRegistrationStatus.success) {
      toastSuccessMessage('Will deleted successfully!');

      setShowDeleteModal({
        show: false,
        idDetails: [],
      });
      dispatch(resetWillRegistrationData());
      getLivingWillListData();
    }
    // eslint-disable-next-line
  }, [deleteWillRegistrationStatus]);

  //handle page change
  useEffect(() => {
    dispatch(
      fetchWillRegistrations({
        params: { ...pagination, ...lastValidSearch, ...sort },
      })
    );
    // eslint-disable-next-line
  }, [pagination, sort]);

  return (
    <div className="living-will-listing">
      <PageTitle heading="Will Registrants" />
      <div className="living-will-listing-content">
        <div className="control-top-button mb-2">
          <FowButton
            variant="primary"
            className="mr-2"
            onClick={() => toggleAdvanceFilter()}
          >
            Advanced Search
          </FowButton>
        </div>
        <div>
          {showAdvanceSearch.show && (
            <SearchForm
              errors={errors}
              handleInputChange={handleInputChange}
              searchWill={searchWill}
              onWillSearch={onWillSearch}
              resetWillSearch={resetWillSearch}
              isFilterApplied={showAdvanceSearch.filterApplied}
            />
          )}
          <Listing
            livingWillList={livingWillList}
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            deleteWillRegistrationDetails={deleteWillRegistrationStatus}
            setPagination={setPagination}
            setSort={setSort}
          />
        </div>
      </div>
    </div>
  );
};

export default LivingWillListing;
