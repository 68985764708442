import { dtCustomStyle } from '../../helpers/DataTableHelper';
import { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import {
  IShowDeleteStateModal,
  IStatesColumn,
  IStateRow,
  ILocationPayload,
} from '../../datatypes/Location';
import { useAppDispatch } from '../../redux/hooks';
import DeleteModal from './DeleteModal';
import { deleteStateDetails } from '../../redux/slices/locationSlice';
import { PageOptions } from '../../constants/Common';
import { Link } from 'react-router-dom';

interface IProps {
  stateList: ILocationPayload['states']['data'];
  statesLoading: ILocationPayload['states']['stateLoading'];
  deleteStateDetail: ILocationPayload['states']['deleteStateStatus'];
  showDeleteModal: IShowDeleteStateModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeleteStateModal>
  >;
}

const Listing = ({
  stateList,
  statesLoading,
  deleteStateDetail,
  showDeleteModal,
  setShowDeleteModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  //switch for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteStateModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  // selected state
  const selectedStateId = (rows: IStateRow[]) => {
    setShowDeleteModal((prev: IShowDeleteStateModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  // handle delete state
  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((stateDetails: IStateRow) => stateDetails.id)
      .join(',');
    dispatch(deleteStateDetails(selectedIds));
  };

  // handle delete modal open
  const openDeleteModal = (row: IStateRow) => {
    selectedStateId([row]);
    toggleDeleteModal();
  };

  //columns for listing
  const columns: IStatesColumn[] = [
    {
      id: 'state_abbr',
      name: 'Abbrevation',
      selector: (row: IStateRow) => row.state_abbr,
      sortable: true,
    },
    {
      id: 'name',
      name: 'Name',
      selector: (row: IStateRow) => row.state_name,
      sortable: true,
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: IStateRow) => row.actions,
      center: true,
      compact: true,
      cell: (row: IStateRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <Link
            title="Edit"
            className="btn btn-primary btn-sm"
            to={`/states/${row.id}/edit`}
            key="edit"
          >
            <i className="fa fa-pencil"></i>
          </Link>
          <Button
            title="Delete"
            className="btn btn-danger btn-sm"
            key="delete"
            onClick={() => openDeleteModal(row)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
  ];

  // handle delete modal close on success delete
  useEffect(() => {
    if (deleteStateDetail?.success) {
      setShowDeleteModal((prev: IShowDeleteStateModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteStateDetail?.success]);

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={statesLoading}
              keyField="id"
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={stateList.length ?? 0}
              data={stateList ?? []}
              customStyles={dtCustomStyle}
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteStateDetails={deleteStateDetail}
        />
      </div>
    </div>
  );
};

export default Listing;
