import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';

const BasicAnswers = ({ answers }: any) => {
  const personalInformation = answers[pageCodes.personalInfoPage];
  const addressInformation = answers[pageCodes.addressInfoPage];
  const assetValuation = answers[pageCodes.assetInfoPage];
  const getAssetValueText = (assetValue: string) => {
    switch (assetValue) {
      case '<$200K':
        return 'Less than $200K';
      case '>$10M':
        return 'Greater than $10M';
      default:
        return assetValue;
    }
  };

  const generateAddressText = () => {
    if (addressInformation && addressInformation[questionCodes.legalAddress]) {
      const address = addressInformation[questionCodes.legalAddress];
      return `${address[questionInputKeys.ADDRESS1]}${
        address[questionInputKeys.ADDRESS2]
          ? ' ' + address[questionInputKeys.ADDRESS2]
          : ''
      },${address[questionInputKeys.CITY]}, ${
        address[questionInputKeys.STATE]
      } ${address[questionInputKeys.ZIPCODE]}`;
    }
    return 'N/A';
  };

  return (
    <div>
      <div>
        <table className="default-table table table-bordered">
          <tbody>
            <tr>
              <th colSpan={4} className="table-title">
                <div>
                  <h4>Personal Information</h4>
                </div>
              </th>
            </tr>
            <tr>
              <th className="text-left w-20">Will Owner Name</th>
              <td width="30%">
                {`${
                  personalInformation[questionCodes.legalName][
                    questionInputKeys.FIRSTNAME
                  ]
                } 
                ${
                  personalInformation[questionCodes.legalName][
                    questionInputKeys.MIDDLENAME
                  ] ?? ''
                } ${
                  personalInformation[questionCodes.legalName][
                    questionInputKeys.LASTNAME
                  ]
                }`}
              </td>
              <th className="text-left">Phone</th>
              <td>
                {personalInformation[questionCodes.phone] &&
                personalInformation[questionCodes.phone][
                  questionInputKeys.PHONE
                ]
                  ? personalInformation[questionCodes.phone][
                      questionInputKeys.PHONE
                    ]
                  : 'N/A'}
              </td>
            </tr>
            <tr>
              <th className="text-left">Address</th>
              <td
                colSpan={3}
                dangerouslySetInnerHTML={{
                  __html: generateAddressText(),
                }}
              ></td>
            </tr>
            <tr>
              <th className="text-left">Date of Birth</th>
              <td width="30%">
                {`${
                  personalInformation[questionCodes.birthdate][
                    questionInputKeys.BIRTHDATE
                  ] ?? 'N/A'
                }`}
              </td>
              <th className="text-left">Gender</th>
              <td width="30%">
                {personalInformation[questionCodes.gender][
                  questionInputKeys.GENDER
                ] ?? 'N/A'}
                {personalInformation[questionCodes.gender][
                  questionInputKeys.GENDER
                ] === 'other' &&
                  '-' +
                    personalInformation[questionCodes.otherGender][
                      questionInputKeys.OTHERGENDER
                    ]}
              </td>
            </tr>
            {assetValuation && (
              <tr>
                <th className="text-left">Approx. Value of Assets</th>
                <td colSpan={3}>
                  {getAssetValueText(
                    assetValuation[questionCodes.assetsValue][
                      questionInputKeys.ASSETS_VALUE
                    ]
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BasicAnswers;
