import { toastSuccessMessage } from '../../components/common/ToastMessage';
import PageTitle from '../../components/common/PageTitle';
import { useState, useEffect } from 'react';
import { usersSearchValidations, usersSearchField } from '../../constants/User';
import { IShowDeleteUserModal, IUsersPayload } from '../../datatypes/User';
import {
  fetchAdminUsers,
  resetAdminUsersData,
} from '../../redux/slices/userSlice';
import Listing from '../../components/admin-users/Listing';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import UsersSearch from '../../components/admin-users/UsersSearch';
import { ValidateField } from '../../helpers/ValidatorHelper';

const UserListing = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [usersSearch, setUsersSearch] = useState({
    ...usersSearchField,
  });

  const [errors, setErrors] = useState({
    ...usersSearchField,
  });

  const [showAdvancedSearch, setShowAdvancedSearch] = useState({
    show: false,
    filterApplied: false,
  });

  const [showDeleteModal, setShowDeleteModal] = useState<IShowDeleteUserModal>({
    show: false,
    idDetails: [],
  });

  const { adminList, deleteAdmin } = useAppSelector<IUsersPayload['admin']>(
    (state) => state.users.admin
  );

  //function to toggle advanced search
  const toggleAdvancedSearch = () => {
    setShowAdvancedSearch({
      ...showAdvancedSearch,
      show: !showAdvancedSearch.show,
    });
  };

  //function to toggle filter applied
  const toggleFilterApplied = (isApplied: boolean) => {
    setShowAdvancedSearch({
      ...showAdvancedSearch,
      filterApplied: isApplied,
    });
  };

  //function to handle search input
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUsersSearch((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const error =
      value === ''
        ? null
        : ValidateField(name, value, String(usersSearchValidations[name]));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error || '',
    }));
  };

  //function to handle validation on search click
  const validateForm = () => {
    const hasValues = Object.keys(usersSearch).some(
      (key) => usersSearch[key] !== ''
    );
    const hasErrors = Object.keys(errors).some((key) => errors[key] !== '');
    return !hasValues || hasErrors;
  };

  //function to handle search button click
  const onUsersSearch = () => {
    const hasError = validateForm();
    if (!hasError) {
      const payload = {
        ...usersSearch,
      };
      dispatch(fetchAdminUsers({ params: payload }));
      toggleFilterApplied(true);
    }
  };

  //function to handle reset button click
  const resetUsersSearch = () => {
    dispatch(fetchAdminUsers({ params: {} }));
    setUsersSearch({
      ...usersSearchField,
    });
    toggleFilterApplied(false);
  };

  useEffect(() => {
    if (!adminList.loading) {
      dispatch(
        fetchAdminUsers({
          params: {},
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (deleteAdmin.success) {
      toastSuccessMessage('Admin user deleted successfully');
      dispatch(resetAdminUsersData());
      setShowDeleteModal({ show: false, idDetails: [] });
      dispatch(fetchAdminUsers({ params: {} }));
    }
    // eslint-disable-next-line
  }, [deleteAdmin]);

  return (
    <div className="admin-users-templates">
      <PageTitle
        heading="Admin Users"
        buttonName="Add New"
        buttonClick={async () => {
          dispatch(resetAdminUsersData());
          navigate('/admin-users/new');
        }}
      />
      <div className="control-top-buttom mb-2">
        <Button
          variant="primary"
          className="mr-2"
          onClick={() => {
            toggleAdvancedSearch();
          }}
        >
          Advanced Search
        </Button>
      </div>
      <div className="admin-users-content">
        {showAdvancedSearch.show && (
          <UsersSearch
            errors={errors}
            handleInputChange={handleInputChange}
            usersSearch={usersSearch}
            onUserSearch={onUsersSearch}
            resetUserSearch={resetUsersSearch}
            isFilterApplied={showAdvancedSearch.filterApplied}
            isDataLoading={adminList.loading}
          />
        )}
        <Listing
          usersList={adminList}
          setShowDeleteUserModal={setShowDeleteModal}
          showDeleteUserModal={showDeleteModal}
          deleteUsersDetail={deleteAdmin}
        />
      </div>
    </div>
  );
};

export default UserListing;
