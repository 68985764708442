import React from 'react';
import { Form } from 'react-bootstrap';
import ErrorMessage from './ErrorMessage';

interface IProps {
  name: string;
  value: number | undefined;
  type?: string;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  min: number | undefined;
  max: number | undefined;
  controlId?: string;
  className?: string;
  placeholder?: string;
}

const NumberInput = ({
  isRequired = true,
  label = '',
  value = undefined,
  errorMessage = '',
  className = '',
  type = 'number',
  placeholder = '',
  min = undefined,
  max = undefined,
  isDisabled = false,
  name,
  onChange,
  controlId,
}: IProps) => {
  return (
    <Form.Group controlId={controlId}>
      {label && (
        <div className="instruction-title">
          <Form.Label>
            {label}
            {isRequired && <span className="required">*</span>}
          </Form.Label>
        </div>
      )}
      <Form.Control
        className={`${
          errorMessage
            ? 'error-focus-input'
            : className || 'form-field form-control'
        }`}
        placeholder={placeholder}
        name={name}
        value={value}
        disabled={isDisabled}
        type={type}
        min={min ?? undefined}
        max={max ?? undefined}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
        }}
      />
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </Form.Group>
  );
};

export default NumberInput;
