import { startCase } from 'lodash';
import { formattedInputKeys } from 'src/constants/FormattedInputKeys';
import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';
import { useAppSelector } from 'src/redux/hooks';
import { getMinorOrDisabledChildren } from 'src/redux/selectors/answerSelector';

const GuardianAnswers = ({ answers }: any) => {
  const guardianInformation = answers[pageCodes.guardianInfoPage];
  const minorOrDisabledChildren = useAppSelector((state) =>
    getMinorOrDisabledChildren(state)
  );

  const financialGuardians =
    guardianInformation &&
    guardianInformation[questionCodes.financialGuardians];

  return (
    <div>
      <div>
        <table className="default-table table table-bordered">
          <tbody>
            <tr>
              <th colSpan={4} className="table-title">
                <div>
                  <h4>Guardians Information</h4>
                </div>
              </th>
            </tr>
            <tr>
              <th>Primary Guardian</th>
              <th>Alternate Guardian</th>
              <th colSpan={2}>Child(ren)</th>
            </tr>
            {guardianInformation[
              questionCodes.guardianForMinorAndDisabledChildren
            ] &&
              guardianInformation[
                questionCodes.guardianForMinorAndDisabledChildren
              ].map((guardian: any, index: number) => (
                <tr key={index}>
                  <td>
                    <span>
                      {startCase(
                        `${guardian[questionInputKeys.PRIMARY_FIRSTNAME]} ${
                          guardian[questionInputKeys.PRIMARY_LASTNAME]
                        }`
                      )}{' '}
                      (
                      {
                        formattedInputKeys[
                          guardian[questionInputKeys.PRIMARY_RELATIONSHIP]
                        ]
                      }
                      )
                    </span>
                  </td>
                  <td>
                    {guardian[questionInputKeys.ALTERNATE_FIRSTNAME] &&
                    guardian[questionInputKeys.ALTERNATE_LASTNAME] &&
                    guardian[questionInputKeys.ALTERNATE_RELATIONSHIP] ? (
                      <span>
                        {startCase(
                          `${guardian[questionInputKeys.ALTERNATE_FIRSTNAME]} ${
                            guardian[questionInputKeys.ALTERNATE_LASTNAME]
                          }`
                        )}{' '}
                        (
                        {
                          formattedInputKeys[
                            guardian[questionInputKeys.ALTERNATE_RELATIONSHIP]
                          ]
                        }
                        )
                      </span>
                    ) : (
                      'N/A'
                    )}
                  </td>
                  <td colSpan={2}>
                    {minorOrDisabledChildren
                      .filter((child: { id: any }) =>
                        guardian[questionInputKeys.CHILDREN].includes(child.id)
                      )
                      .map((child: any) =>
                        startCase(
                          `${child[questionInputKeys.FIRSTNAME]} ${
                            child[questionInputKeys.LASTNAME]
                          }`
                        )
                      )
                      .join(', ')}
                  </td>
                </tr>
              ))}
            <tr>
              <th>
                Would like to specify Financial Guardian for your minor or
                disabled child(ren)?
              </th>
              <td colSpan={3}>
                {startCase(
                  guardianInformation[questionCodes.requiredFinancialGuardians][
                    questionInputKeys.HAS_FINANCIAL_GUARDIAN
                  ]
                )}
              </td>
            </tr>
            {guardianInformation[questionCodes.requiredFinancialGuardians] &&
              guardianInformation[questionCodes.requiredFinancialGuardians][
                questionInputKeys.HAS_FINANCIAL_GUARDIAN
              ] === 'yes' && (
                <>
                  <tr>
                    <th>Financial Guardian</th>
                    <td>
                      {startCase(
                        `${
                          financialGuardians[
                            questionInputKeys.PRIMARY_FIRSTNAME
                          ]
                        } ${
                          financialGuardians[questionInputKeys.PRIMARY_LASTNAME]
                        }`
                      )}{' '}
                      (
                      {
                        formattedInputKeys[
                          financialGuardians[
                            questionInputKeys.PRIMARY_RELATIONSHIP
                          ]
                        ]
                      }
                      )
                    </td>
                    <th>Alternate Financial Guardian</th>
                    <td>
                      {financialGuardians[
                        questionInputKeys.ALTERNATE_FIRSTNAME
                      ] &&
                      financialGuardians[
                        questionInputKeys.ALTERNATE_LASTNAME
                      ] &&
                      financialGuardians[
                        questionInputKeys.ALTERNATE_RELATIONSHIP
                      ] ? (
                        <span>
                          {startCase(
                            `${
                              financialGuardians[
                                questionInputKeys.ALTERNATE_FIRSTNAME
                              ]
                            } ${
                              financialGuardians[
                                questionInputKeys.ALTERNATE_LASTNAME
                              ]
                            }`
                          )}{' '}
                          (
                          {
                            formattedInputKeys[
                              financialGuardians[
                                questionInputKeys.ALTERNATE_RELATIONSHIP
                              ]
                            ]
                          }
                          )
                        </span>
                      ) : (
                        'N/A'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Would you like your Financial Guardian to receive
                      compensation for the work and services provided?
                    </th>
                    <td
                      colSpan={
                        guardianInformation[
                          questionCodes
                            .shouldFinancialGuardianReceiveCompensation
                        ][
                          questionInputKeys
                            .SHOULD_FINANCIAL_GUARDIAN_RECEIVE_COMPENSATION
                        ] === 'no'
                          ? 3
                          : 1
                      }
                    >
                      {startCase(
                        guardianInformation[
                          questionCodes
                            .shouldFinancialGuardianReceiveCompensation
                        ][
                          questionInputKeys
                            .SHOULD_FINANCIAL_GUARDIAN_RECEIVE_COMPENSATION
                        ]
                      )}
                    </td>
                    {guardianInformation[
                      questionCodes.requiredFinancialGuardians
                    ] &&
                      guardianInformation[
                        questionCodes.requiredFinancialGuardians
                      ][questionInputKeys.HAS_FINANCIAL_GUARDIAN] === 'yes' &&
                      guardianInformation[
                        questionCodes.shouldFinancialGuardianReceiveCompensation
                      ] &&
                      guardianInformation[
                        questionCodes.shouldFinancialGuardianReceiveCompensation
                      ][
                        questionInputKeys
                          .SHOULD_FINANCIAL_GUARDIAN_RECEIVE_COMPENSATION
                      ] === 'yes' && (
                        <>
                          <th>Financial Guardian Compensation</th>
                          <td colSpan={3}>
                            {guardianInformation[
                              questionCodes.financialGuardianCompensation
                            ] &&
                            guardianInformation[
                              questionCodes.financialGuardianCompensation
                            ][questionInputKeys.COMPENSATION_UNIT] === '%'
                              ? `${
                                  guardianInformation[
                                    questionCodes.financialGuardianCompensation
                                  ][questionInputKeys.COMPENSATION_VALUE]
                                }%`
                              : `${new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                }).format(
                                  guardianInformation[
                                    questionCodes.financialGuardianCompensation
                                  ][questionInputKeys.COMPENSATION_VALUE]
                                )}`}
                          </td>
                        </>
                      )}
                  </tr>
                </>
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GuardianAnswers;
