import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Containers
import TheLayout from './containers/TheLayout';

// Pages
import AdminLogin from '../views/auth/AdminLogin';
import Page404 from '../views/errors/Page404';
import Page500 from '../views/errors/Page500';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  document.title = 'Admin Panel - The U.S. Will Registry';

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Routes>
          <Route path="/login" Component={AdminLogin} />
          <Route path="/500" Component={Page500} />
          <Route path="/404" Component={Page404} />
          <Route path="/*" Component={TheLayout} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
};
export default App;
