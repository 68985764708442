import { IPageDetails } from '../datatypes/Page';
import useAxiosService from '../services/axios.service';

const usePageApi = () => {
  const { getUserHttpClient } = useAxiosService();

  //get pages list
  const getPages = async () => {
    return await getUserHttpClient()
      .get('/pages')
      .then((response) => {
        return response;
      });
  };

  //get page by id
  const getPageDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/pages/' + id)
      .then((response) => response);
  };

  //save new page
  const savePageDetails = async (data: IPageDetails) => {
    return await getUserHttpClient()
      .post('/pages', data)
      .then((response) => response);
  };

  //update page
  const updatePageDetails = async (data: IPageDetails, id: string) => {
    return await getUserHttpClient()
      .put('/pages/' + id, data)
      .then((response) => response);
  };

  //delete pages
  const deletePagesData = async (id: string) => {
    return await getUserHttpClient()
      .delete('/pages/' + id)
      .then((response) => response);
  };

  return {
    getPages,
    getPageDetails,
    savePageDetails,
    updatePageDetails,
    deletePagesData,
  };
};

export default usePageApi;
