import { Dtspinner, dtCustomStyle } from '../../helpers/DataTableHelper';
import { Button, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { PageOptions } from '../../constants/Common';
import {
  ISearchPinCodeColumn,
  ISearchPinCodeRecord,
} from '../../datatypes/Invoice';

interface IProps {
  data: ISearchPinCodeRecord[];
  isLoading: boolean;
  onActionClick: (data: ISearchPinCodeRecord) => void;
}

const SearchPinCodeList = ({ data, isLoading, onActionClick }: IProps) => {
  const columns: ISearchPinCodeColumn[] = [
    {
      id: 'invoice_number',
      name: 'Invoice',
      selector: (row: ISearchPinCodeRecord) => row.invoice_number,
      sortable: true,
    },
    {
      id: 'customer_name',
      name: 'Customer Details',
      selector: (row: ISearchPinCodeRecord) => row.customer_name,
      sortable: true,
      compact: true,
      cell: (row: ISearchPinCodeRecord) => (
        <div>
          <span>{row.customer_name}</span>
          <br />
          <a href={`mailto:${row.customer_email}`}>{row.customer_email}</a>
        </div>
      ),
    },
    {
      id: 'search_pin',
      name: 'Search Details',
      selector: (row: ISearchPinCodeRecord) => row.search_pin,
      sortable: true,
      compact: true,
      cell: (row: ISearchPinCodeRecord) => (
        <div>
          <span>Pin : {row.search_pin}</span>
          <br />
          <span>Count : {row.transaction_count}</span>
        </div>
      ),
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: ISearchPinCodeRecord) => row.actions,
      center: true,
      compact: true,
      cell: (row: ISearchPinCodeRecord) => (
        <div>
          <Button
            title="Edit"
            className="btn btn-primary btn-sm ml-2"
            key="delete"
            onClick={() => onActionClick(row)}
          >
            <i className="fa fa-pencil"></i>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="generate-search-pin-code-listing-section">
      <div className="row mt-3">
        <div className="col-12">
          <Card>
            <Card.Body>
              <DataTable
                className="dt-table"
                persistTableHead
                responsive
                progressPending={isLoading}
                paginationRowsPerPageOptions={PageOptions}
                pagination
                paginationPerPage={10}
                striped
                highlightOnHover
                defaultSortFieldId="invoice_number"
                columns={columns}
                paginationTotalRows={data.length}
                data={data}
                customStyles={dtCustomStyle}
                progressComponent={<Dtspinner />}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SearchPinCodeList;
