import { startCase } from 'lodash';
import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';

const CharityAnswers = ({ answers }: any) => {
  const charityInformation = answers[pageCodes.charitiesPage];
  const charities = charityInformation[questionCodes.charityPortion];

  return (
    <div>
      <div>
        <table className="default-table table table-bordered">
          <tbody>
            <tr>
              <th colSpan={4} className="table-title">
                <div>
                  <h4>Create a Legacy</h4>
                </div>
              </th>
            </tr>
            {charityInformation[questionCodes.wantToDonateInCharities][
              questionInputKeys.WANT_TO_DONATE
            ] === 'no' && (
              <tr>
                <td>
                  As of this date, no charitable gifts have been recorded from
                  this user.
                </td>
              </tr>
            )}
            {charityInformation[questionCodes.wantToDonateInCharities][
              questionInputKeys.WANT_TO_DONATE
            ] === 'yes' &&
              charities &&
              charities.length > 0 && (
                <>
                  <tr>
                    <th>
                      Would like us to notify your charity of choice that you
                      have left a bequest for them?
                    </th>
                    <td>
                      {startCase(
                        charityInformation[questionCodes.informCharity][
                          questionInputKeys.INFORM_CHARITY
                        ]
                      )}
                    </td>
                    <th>
                      Would you like more information on how you can get
                      involved in helping support any of the organizations you
                      have chosen?
                    </th>
                    <td>
                      {startCase(
                        charityInformation[questionCodes.needMoreCharityInfo][
                          questionInputKeys.NEED_MORE_CHARITY_INFO
                        ]
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Organization Name</th>
                    <th className="w-20">EIN</th>
                    <th>Location</th>
                    <th>Portion</th>
                  </tr>
                  {charities.map((charity: any, index: number) => (
                    <tr key={index}>
                      <td>{charity.charity_name}</td>
                      <td>{charity.ein_number ?? 'N/A'}</td>
                      <td>
                        {charity.city && charity.state
                          ? charity.city + ', ' + charity.state
                          : 'N/A'}
                      </td>
                      <td>
                        {charity.unit === '$'
                          ? `${new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(charity.portion)}`
                          : `${Number(charity.portion).toFixed(2)}%`}
                      </td>
                    </tr>
                  ))}
                </>
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CharityAnswers;
