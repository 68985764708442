import TextInput from '../../components/common/TextInput';
import FowButton from '../../components/common/FowButton';
import React, { useState } from 'react';
// import CheckBoxInput from '../../components/common/CheckBoxInput';
// import NumberInput from '../../components/common/NumberInput';
import {
  findSearchPinCodeFieldValidations,
  initialFindSearchPinCodeField,
} from '../../constants/Invoice';
import useInvoiceApi from '../../api/invoice.api';
import { ValidateField } from '../../helpers/ValidatorHelper';
import { ISearchPinCodeRecord } from '../../datatypes/Invoice';
import SearchPinCodeList from '../../components/invoices/SearchPinCodeList';
import SearchPinCodeDetails from '../../components/invoices/SearchPinCodeDetails';

interface IDetailsModal {
  show: boolean;
  data: ISearchPinCodeRecord;
}

const DEFAULT_DETAILS_MODAL: IDetailsModal = {
  show: false,
  data: {
    customer_email: '',
    customer_name: '',
    id: 0,
    invoice_number: '',
    mws_id: 0,
    search_pin: 0,
    transaction_count: 0,
    actions: [],
  },
};

const GenerateSearchPinCode = () => {
  const [findSearchPinCodeData, setFindSearchPinCodeData] = useState({
    ...initialFindSearchPinCodeField,
  });
  const [findSearchPinCodeErrors, setFindSearchPinCodeErrors] = useState({
    ...initialFindSearchPinCodeField,
    transaction_count: '',
  });

  const [isFindingSearchPinCodeLoading, setIsFindingSearchPinCodeLoading] =
    useState<boolean>(false);

  const [searchPinCodeRecords, setSearchPinCodeRecords] = useState<
    ISearchPinCodeRecord[]
  >([]);

  // const { generateSearchPinCode , findSearchPinCode } = useInvoicesApi();
  const { findSearchPinCode } = useInvoiceApi();

  // states related to listing and show details modal
  const [detailsModal, setDetailsModal] = useState<IDetailsModal>(
    DEFAULT_DETAILS_MODAL
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value: string | boolean | number = e.target.value;

    setFindSearchPinCodeData((prevPinData) => ({
      ...prevPinData,
      [name]: value,
    }));

    if (name === 'first_name' || name === 'last_name') {
      const error = ValidateField(
        'first_name',
        name === 'first_name' ? value : findSearchPinCodeData.first_name,
        findSearchPinCodeFieldValidations?.first_name
      );

      const error2 = ValidateField(
        'last_name',
        name === 'last_name' ? value : findSearchPinCodeData.last_name,
        findSearchPinCodeFieldValidations?.last_name
      );

      setFindSearchPinCodeErrors((prevErrors) => ({
        ...prevErrors,
        first_name: error || '',
        last_name: error2 || '',
      }));
    } else {
      const error =
        value === ''
          ? null
          : ValidateField(
              e.target.name,
              value,
              // @ts-ignore
              findSearchPinCodeFieldValidations?.[name]
            );
      setFindSearchPinCodeErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error || '',
      }));
    }
  };

  const validateFindSearchPinCodeForm = () => {
    const generatePinFormKeys = Object.keys(findSearchPinCodeData);

    // Check if there are values in any of the main fields other than 'transaction_count' and 'update_search_count'
    const hasValuesInGeneratePin = generatePinFormKeys
      .filter(
        (key) => key !== 'transaction_count' && key !== 'update_search_count'
      )
      .some(
        (key) =>
          findSearchPinCodeData[key as keyof typeof findSearchPinCodeData] !==
          ''
      );

    if (!hasValuesInGeneratePin) {
      return true; // If no values, indicate error (true)
    } else {
      // Check if update_search_count is enabled, then validate transaction_count
      let searchCountValid = true;

      // Validate individual fields
      const transactionIdValid =
        findSearchPinCodeErrors.transaction_id === '' &&
        findSearchPinCodeData.transaction_id !== '';
      const emailIdValid =
        findSearchPinCodeErrors.email === '' &&
        findSearchPinCodeData.email !== '';
      const firstNameValid =
        findSearchPinCodeErrors.first_name === '' &&
        findSearchPinCodeData.first_name !== '';
      const lastNameValid =
        findSearchPinCodeErrors.last_name === '' &&
        findSearchPinCodeData.last_name !== '';

      // Calculate the number of fields without validation errors
      const validFields = [
        transactionIdValid,
        emailIdValid,
        firstNameValid && lastNameValid,
      ].filter((v) => v).length;

      const firstLastValidArray = [firstNameValid, lastNameValid];

      if (
        firstLastValidArray.includes(false) &&
        firstLastValidArray.includes(true)
      ) {
        setFindSearchPinCodeErrors((prevErrors) => ({
          ...prevErrors,
          first_name: 'Both First and Last Name are required.',
          last_name: 'Both First and Last Name are required.',
        }));
      }

      // Return true (error) if there's exactly one invalid filled field and all others are blank
      if (validFields === 0) {
        return true;
      }

      // If all validation passes, the form is considered without error, thus return false
      return (
        !(
          transactionIdValid ||
          emailIdValid ||
          (firstNameValid && lastNameValid)
        ) || !searchCountValid
      );
    }
  };

  // search for pin details
  const onFindSearchPinCode = async () => {
    const hasError = validateFindSearchPinCodeForm();
    if (!hasError) {
      const payload: {
        [key: string]: string | number;
      } = {};

      if (
        findSearchPinCodeErrors.transaction_id === '' &&
        findSearchPinCodeData.transaction_id !== ''
      ) {
        payload['transaction_id'] = findSearchPinCodeData.transaction_id;
      }
      if (
        findSearchPinCodeErrors.email === '' &&
        findSearchPinCodeData.email !== ''
      ) {
        payload['email'] = findSearchPinCodeData.email;
      }
      if (
        findSearchPinCodeErrors.first_name === '' &&
        findSearchPinCodeData.first_name !== '' &&
        findSearchPinCodeErrors.last_name === '' &&
        findSearchPinCodeData.last_name !== ''
      ) {
        payload['first_name'] = findSearchPinCodeData.first_name;
        payload['last_name'] = findSearchPinCodeData.last_name;
      }

      setIsFindingSearchPinCodeLoading(true);

      await findSearchPinCode(payload)
        .then((response) => {
          setSearchPinCodeRecords(response.data);
        })
        .catch(() => {})
        .finally(() => {
          setIsFindingSearchPinCodeLoading(false);
        });
    }
  };

  const resetFindSearchPinCode = () => {
    setFindSearchPinCodeData({ ...initialFindSearchPinCodeField });
    setFindSearchPinCodeErrors({
      ...initialFindSearchPinCodeField,
      transaction_count: '',
    });
  };

  //functions related to listing

  const handleUpdateDetailsClick = (row: ISearchPinCodeRecord) => {
    setDetailsModal({ show: true, data: row });
  };

  const handleCancelClose = () => {
    setDetailsModal(DEFAULT_DETAILS_MODAL);
  };

  const handleOnUpdateClose = () => {
    setDetailsModal(DEFAULT_DETAILS_MODAL);
    onFindSearchPinCode();
  };

  return (
    <>
      <div className="generate-search-pin-code-search-section">
        <div className="invoice-find-search-pin-form">
          <div className="row" style={{ alignItems: 'center' }}>
            <div className="col-lg-6">
              <TextInput
                name="first_name"
                placeholder="First Name"
                value={findSearchPinCodeData.first_name}
                onChange={(e) => handleInputChange(e)}
                label="First Name"
                errorMessage={findSearchPinCodeErrors.first_name}
                isRequired={false}
              />
            </div>
            <div className="col-lg-6">
              <TextInput
                name="last_name"
                placeholder="Last Name"
                value={findSearchPinCodeData.last_name}
                onChange={(e) => handleInputChange(e)}
                label="Last Name"
                errorMessage={findSearchPinCodeErrors.last_name}
                isRequired={false}
              />
            </div>
            <div className="col-lg-12" style={{ textAlign: 'center' }}>
              <div className="or">
                <span>OR</span>
              </div>
            </div>
            <div className="col-lg-6 horizontal-border-right">
              <TextInput
                name="transaction_id"
                placeholder="Transaction ID"
                value={findSearchPinCodeData.transaction_id}
                onChange={(e) => handleInputChange(e)}
                label="Transaction ID"
                errorMessage={findSearchPinCodeErrors.transaction_id}
                isRequired={false}
              />
            </div>
            <div className="col-lg-6">
              <TextInput
                name="email"
                placeholder="Email"
                value={findSearchPinCodeData.email}
                onChange={(e) => handleInputChange(e)}
                label="Email"
                errorMessage={findSearchPinCodeErrors.email}
                isRequired={false}
              />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-12">
            <div className="d-flex gap-3 justify-content-between w-100">
              <FowButton
                variant="outline-secondary"
                onClick={resetFindSearchPinCode}
                isDisabled={isFindingSearchPinCodeLoading}
              >
                <i className="fa fa-undo mr-2"></i> Reset
              </FowButton>

              <FowButton
                variant="success"
                onClick={onFindSearchPinCode}
                isLoading={isFindingSearchPinCodeLoading}
                isDisabled={isFindingSearchPinCodeLoading}
              >
                Search Pin-code Details
              </FowButton>
            </div>
          </div>
        </div>
      </div>
      {searchPinCodeRecords.length !== 0 && (
        <SearchPinCodeList
          data={searchPinCodeRecords}
          isLoading={isFindingSearchPinCodeLoading}
          onActionClick={handleUpdateDetailsClick}
        />
      )}
      {detailsModal.show && (
        <SearchPinCodeDetails
          show={detailsModal.show}
          data={detailsModal.data}
          handleCancelClose={handleCancelClose}
          handleSaveClose={handleOnUpdateClose}
        />
      )}
    </>
  );
};

export default GenerateSearchPinCode;
