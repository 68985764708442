import { startCase } from 'lodash';
import { formattedInputKeys } from 'src/constants/FormattedInputKeys';
import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';

const SpecificBequestsAnswers = ({ answers }: any) => {
  const specificBequestsInformation = answers[pageCodes.specificBequestsPage];
  const specificBequests =
    specificBequestsInformation &&
    specificBequestsInformation[questionCodes.specificBequests];

  return (
    <div>
      <div>
        <table className="default-table table table-bordered">
          <tbody>
            <tr>
              <th colSpan={3} className="table-title">
                <div>
                  <h4>Specific Bequests</h4>
                </div>
              </th>
            </tr>
            <tr>
              <th className="text-left w-30">
                Do you want to make any specific bequests?
              </th>
              <td colSpan={2}>
                {startCase(
                  specificBequestsInformation[
                    questionCodes.hasSpecificBequests
                  ][questionInputKeys.HAS_SPECIFIC_BEQUESTS]
                )}
              </td>
            </tr>
            {specificBequestsInformation[questionCodes.hasSpecificBequests][
              questionInputKeys.HAS_SPECIFIC_BEQUESTS
            ] &&
              specificBequestsInformation[questionCodes.hasSpecificBequests][
                questionInputKeys.HAS_SPECIFIC_BEQUESTS
              ] === 'yes' && (
                <>
                  <tr>
                    <th>Gift(s)</th>
                    <th>Primary Beneficiary</th>
                    <th>Alt. Beneficiary</th>
                  </tr>
                  {specificBequests &&
                    specificBequests.length > 0 &&
                    specificBequests.map((bequest: any, index: number) => (
                      <tr key={index}>
                        <td>{startCase(bequest.gift)}</td>
                        <td>
                          <span>
                            {bequest[questionInputKeys.PRIMARY_FIRSTNAME] &&
                              bequest[questionInputKeys.PRIMARY_LASTNAME] &&
                              bequest[questionInputKeys.PRIMARY_RELATIONSHIP] &&
                              startCase(
                                `${
                                  bequest[questionInputKeys.PRIMARY_FIRSTNAME]
                                } ${
                                  bequest[questionInputKeys.PRIMARY_LASTNAME]
                                }`
                              )}
                            {bequest[
                              questionInputKeys.PRIMARY_RELATIONSHIP
                            ] && (
                              <>
                                {' '}
                                (
                                {
                                  formattedInputKeys[
                                    bequest[
                                      questionInputKeys.PRIMARY_RELATIONSHIP
                                    ]
                                  ]
                                }
                                )
                              </>
                            )}
                          </span>
                        </td>
                        <td>
                          {bequest[questionInputKeys.ALTERNATE_FIRSTNAME] &&
                            bequest[questionInputKeys.ALTERNATE_LASTNAME] &&
                            bequest[questionInputKeys.ALTERNATE_RELATIONSHIP] &&
                            startCase(
                              `${
                                bequest[questionInputKeys.ALTERNATE_FIRSTNAME]
                              } ${
                                bequest[questionInputKeys.ALTERNATE_LASTNAME]
                              }`
                            )}
                          {bequest[questionInputKeys.ALTERNATE_RELATIONSHIP] ? (
                            <>
                              {' '}
                              (
                              {
                                formattedInputKeys[
                                  bequest[
                                    questionInputKeys.ALTERNATE_RELATIONSHIP
                                  ]
                                ]
                              }
                              )
                            </>
                          ) : (
                            'N/A'
                          )}
                        </td>
                      </tr>
                    ))}
                </>
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SpecificBequestsAnswers;
