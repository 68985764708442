import moment from 'moment';

const replaceUnderscoreAndCapitalize = (name: string) => {
  const rName = name.replace('_', ' ');
  const capitalizedFirstWord = rName.charAt(0).toUpperCase() + rName.slice(1);
  return capitalizedFirstWord;
};

export const ValidateField = (
  fieldName: string,
  fieldValue: any,
  options: string
) => {
  if (options && options !== '') {
    const zipCodeValidation = /^\d{5}(-\d{4})?$/;
    const phoneNumberValidation = /^\+?1?\d{10}$/;
    const nameValidation = /^[a-zA-Z '-]+$/;
    const emailValidation =
      /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,})$/;
    const optionsArr = options.split('|');
    const willRegCodeValidation = /^(\d{0,12})$/i;
    for (var i = 0; i < optionsArr.length; i++) {
      const option = optionsArr[i].split(':');
      switch (option[0]) {
        case 'nameValidate':
          if (
            !nameValidation.test(fieldValue.toString().trim()) &&
            fieldValue.toString().trim() !== ''
          ) {
            return `Please enter valid ${replaceUnderscoreAndCapitalize(
              fieldName
            )}.`;
          } else break;
        case 'required':
          if (fieldValue == null || fieldValue.toString().trim() === '') {
            return `${replaceUnderscoreAndCapitalize(fieldName)} is required`;
          } else break;
        case 'email':
          if (!emailValidation.test(fieldValue.toString().trim())) {
            return `${replaceUnderscoreAndCapitalize(fieldName)} is not valid`;
          } else break;
        case 'willRegCode':
          if (!willRegCodeValidation.test(fieldValue.toString().trim())) {
            return `${replaceUnderscoreAndCapitalize(fieldName)} is not valid`;
          } else break;

        case 'password':
          if (
            fieldValue.toString().length < 6 ||
            fieldValue.toString().length > 16
          ) {
            return `${replaceUnderscoreAndCapitalize(
              fieldName
            )} must have at least 6 characters and maximum 16 characters long.`;
          } else continue;
        case 'string':
          if (/[^a-zA-Z -]/.test(fieldValue)) {
            return 'Invalid characters';
          } else break;
        case 'min':
          if (fieldValue?.toString()?.trim().length < option[1]) {
            return `${replaceUnderscoreAndCapitalize(
              fieldName
            )} needs to be at least ${option[1]} characters`;
          } else break;
        case 'max':
          if (fieldValue?.toString()?.trim()?.length > option[1]) {
            return `${replaceUnderscoreAndCapitalize(
              fieldName
            )} needs to be at max ${option[1]} characters`;
          } else break;
        case 'zipcode':
          if (
            !zipCodeValidation.test(fieldValue.toString().trim()) &&
            fieldValue.toString().trim() !== ''
          ) {
            return `${replaceUnderscoreAndCapitalize(fieldName)} is invalid`;
          } else break;
        case 'mobile':
          if (
            !phoneNumberValidation.test(fieldValue.toString().trim()) &&
            fieldValue.toString().trim() !== ''
          ) {
            return `${replaceUnderscoreAndCapitalize(
              fieldName
            )} number is invalid`;
          } else break;
        case 'json':
          try {
            JSON.parse(fieldValue);
          } catch (e) {
            return `${replaceUnderscoreAndCapitalize(
              fieldName
            )} is invalid json`;
          }
          break;
        case 'date':
          if (
            (typeof fieldValue === 'string' &&
              !moment(fieldValue.toString(), option[0]).isValid()) ||
            !moment(fieldValue?.value1?.toString(), option[1]).isValid()
          ) {
            return `Please enter valid date for ${replaceUnderscoreAndCapitalize(
              fieldName
            )}.`;
          } else continue;
        case 'birthdate':
          if (
            moment(fieldValue.toString()).isValid() &&
            new Date() < new Date(fieldValue.toString())
          ) {
            return `${replaceUnderscoreAndCapitalize(
              fieldName
            )} must be less than today's date.`;
          } else continue;
        case 'minYears':
          if (
            moment(fieldValue.toString()).isValid() &&
            moment().diff(
              moment(fieldValue.toString(), 'YYYY-MM-DD'),
              'years'
            ) < Number(option[1])
          ) {
            return `${replaceUnderscoreAndCapitalize(
              fieldName
            )} must be greater than ${option[1]} years.`;
          } else continue;
        case 'maxYears':
          if (
            moment(fieldValue.toString()).isValid() &&
            moment().diff(
              moment(fieldValue.toString(), 'YYYY-MM-DD'),
              'years'
            ) >= Number(option[1])
          ) {
            return `${replaceUnderscoreAndCapitalize(
              fieldName
            )} must be less than or equal to ${option[1]} years.`;
          } else continue;
        case 'deathDayCompare':
          if (
            moment(fieldValue.toString()).isValid() &&
            moment(fieldValue.toString(), 'YYYY-MM-DD').isBefore(
              moment(option[1].toString(), 'YYYY-MM-DD')
            )
          ) {
            return `${replaceUnderscoreAndCapitalize(
              fieldName
            )} must be greater than or equal to Birth Date.`;
          } else continue;
        case 'compareDateLess':
          if (
            moment(fieldValue?.value1, 'mm/dd/yyyy').isAfter(
              moment(fieldValue?.value2, 'mm/dd/yyyy')
            )
          ) {
            return `Date must be Less than Created To.`;
          } else continue;
        case 'compareDateMore':
          if (
            moment(fieldValue?.value1, 'mm/dd/yyyy').isBefore(
              moment(fieldValue?.value2, 'mm/dd/yyyy')
            )
          ) {
            return `Date must be Greater than Created From.`;
          } else continue;
        case 'compareDateInputLess':
          if (moment(fieldValue?.value1).isAfter(moment(fieldValue?.value2))) {
            return `Date must be Less than Created To.`;
          } else continue;
        case 'compareDateInputMore':
          if (moment(fieldValue?.value1).isBefore(moment(fieldValue?.value2))) {
            return `Date must be Greater than Created From.`;
          } else continue;
        case 'expiryDate': {
          if (
            typeof fieldValue === 'string' &&
            moment(fieldValue, 'MM/YYYY').isBefore(moment(), 'month')
          ) {
            return `${replaceUnderscoreAndCapitalize(
              fieldName
            )} is not valid Date.`;
          } else continue;
        }
        case 'maxAge':
          if (
            typeof fieldValue === 'string' &&
            moment(fieldValue) &&
            moment().diff(moment(fieldValue, 'YYYY-MM-DD'), 'years') >
              Number(option[1])
          ) {
            return `Age must be less than or equal to ${option[1]} years.`;
          } else continue;
        case 'minNumber':
          if (Number(fieldValue) < Number(option[1])) {
            return `${replaceUnderscoreAndCapitalize(
              fieldName
            )} needs to be at least ${option[1]}.`;
          } else break;
        case 'maxNumber':
          if (Number(fieldValue) > Number(option[1])) {
            return `${replaceUnderscoreAndCapitalize(
              fieldName
            )} needs to be at most ${option[1]}.`;
          } else break;
        default:
          return null;
      }
    }
  } else {
    return null;
  }
};
