import { Dtspinner, dtCustomStyle } from '../../helpers/DataTableHelper';
import { useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useAppDispatch } from '../../redux/hooks';

import {
  IShowDeleteTerminologyModal,
  ITerminologyColumn,
  ITerminologyRow,
  ITerminologyPayload,
} from '../../datatypes/Terminology';
import { deleteTerminology } from '../../redux/slices/terminologySlice';
import DeleteModal from './DeleteModal';
import { PageOptions } from '../../constants/Common';
import { Link } from 'react-router-dom';

//interface for component
interface IProps {
  terminologyList: ITerminologyPayload['terminologyList'];
  deleteTerminologyDetail: ITerminologyPayload['deleteTerminologyStatus'];
  showDeleteTerminologyModal: IShowDeleteTerminologyModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeleteTerminologyModal>
  >;
}

const Listing = ({
  terminologyList,
  deleteTerminologyDetail,
  showDeleteTerminologyModal,
  setShowDeleteModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  // switch function for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteTerminologyModal) => ({
      ...prev,
      show: !showDeleteTerminologyModal.show,
      ...(showDeleteTerminologyModal.show ? { idDetails: [] } : {}),
    }));
  };

  const selectedTerminologyId = (rows: ITerminologyRow[]) => {
    setShowDeleteModal((prev: IShowDeleteTerminologyModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  // function to handle delete
  const handleDelete = () => {
    const selectedIds = showDeleteTerminologyModal.idDetails
      .map((terminologyDetail: ITerminologyRow) => terminologyDetail.id)
      .join(',');
    dispatch(deleteTerminology(selectedIds));
  };

  // function to handle open delete modal
  const openDeleteModal = (row: ITerminologyRow) => {
    selectedTerminologyId([row]);
    toggleDeleteModal();
  };

  useEffect(() => {
    if (deleteTerminologyDetail.success) {
      setShowDeleteModal((prev: IShowDeleteTerminologyModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteTerminologyDetail]);

  const columns: ITerminologyColumn[] = [
    {
      name: 'Term',
      selector: (row: ITerminologyRow) => row.term,
      sortable: true,
      width: '9.5rem',
    },
    {
      name: 'Alternate Term',
      selector: (row: ITerminologyRow) => row.alternate_term,
      sortable: true,
      width: '9.5rem',
    },
    {
      name: 'Description',
      selector: (row: ITerminologyRow) => row.description,
      cell: (row: ITerminologyRow) => (
        <div dangerouslySetInnerHTML={{ __html: row.description }}></div>
      ),
      sortable: true,
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: ITerminologyRow) => row.actions,
      center: true,
      compact: true,
      width: '9.5rem',
      cell: (row: ITerminologyRow) => (
        <div>
          <Link
            title="Edit"
            className="btn btn-primary btn-sm"
            to={`/terminologies/${row.id}/edit`}
            key="edit"
          >
            <i className="fa fa-pencil"></i>
          </Link>
          <Link
            title="View"
            className="btn btn-secondary btn-sm ml-2"
            to={`/terminologies/${row.id}/view`}
            key="view"
          >
            <i className="fa fa-eye"></i>
          </Link>
          <Button
            title="Delete"
            className="btn btn-danger btn-sm ml-2"
            key="delete"
            onClick={() => openDeleteModal(row)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Row className="mt-3">
      <Col lg={12}>
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={terminologyList.loading}
              keyField="id"
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={terminologyList.data?.total}
              data={terminologyList.data?.data}
              customStyles={dtCustomStyle}
              progressComponent={<Dtspinner />}
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteTerminologyModal.show}
          selectedIdDetails={showDeleteTerminologyModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteTerminologyDetail={deleteTerminologyDetail}
        />
      </Col>
    </Row>
  );
};

export default Listing;
