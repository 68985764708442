import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Card } from 'react-bootstrap';
import { dtCustomStyle, Dtspinner } from '../../helpers/DataTableHelper';
import { PageOptions } from '../../constants/Common';
import { Link } from 'react-router-dom';

const CountyListing = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);

  const formatColumns = () => {
    setColumns([
      { name: 'State', selector: 'county_state' },
      { name: 'County Name', selector: 'county_name' },
      { name: 'Actions', selector: 'actions' },
    ]);
  };

  const handleDelete = (_e: any) => {
    console.log('test');
  };
  const formatRows = (data: any) => {
    const rows = data.map((c: any) => {
      return {
        county_state: c.county_state,
        county_name: c.county_name,
        actions: [
          <Link
            className="btn btn-sm btn-secondary"
            to={`/counties/${c.id}/edit`}
            key="edit"
          >
            <i className="fa fa-pencil"></i>{' '}
          </Link>,
          <button
            type="button"
            className="btn btn-sm btn-danger"
            key="delete"
            onClick={handleDelete}
            data-id={c.id}
          >
            <i data-id={c.id} className="fa fa-trash"></i>{' '}
          </button>,
        ],
      };
    });
    setRows(rows);
  };

  const fetchConties = async () => {
    setLoading(true);
    formatColumns();
    formatRows([]);
  };

  useEffect(() => {
    fetchConties();
    // eslint-disable-next-line
  }, []);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h2>County Listing</h2>
        <Link to="/counties/new" className="button-link">
          <i className="fa fa-plus"></i> NEW
        </Link>
      </Card.Header>
      <Card.Body>
        <DataTable
          className="dt-table"
          persistTableHead={true}
          responsive={true}
          progressPending={loading}
          progressComponent={<Dtspinner />}
          pagination={true}
          paginationPerPage={50}
          paginationRowsPerPageOptions={PageOptions}
          striped={true}
          highlightOnHover={true}
          columns={columns}
          data={rows}
          customStyles={dtCustomStyle}
        />
      </Card.Body>
    </Card>
  );
};

export default CountyListing;
