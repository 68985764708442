import TheSidebar from './TheSidebar';
import TheHeader from './TheHeader';
import TheContent from './TheContent';
import TheFooter from './TheFooter';
import withAuth from '../../hoc/withAuth';

const TheLayout = () => {
  return (
    <div className="fow-app">
      <TheSidebar />
      <div className="main-wrapper">
        <TheHeader />
        <div className="main-content">
          <TheContent />
        </div>
        <TheFooter />
      </div>
    </div>
  );
};

export default withAuth(TheLayout);
