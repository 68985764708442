import { Dtspinner, dtCustomStyle } from '../../helpers/DataTableHelper';
import { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useAppDispatch } from '../../redux/hooks';
import DeleteModal from './DeleteModal';
import { PageOptions } from '../../constants/Common';
import {
  IWillCreatorColumn,
  IWillCreatorPagination,
  IWillCreatorPayload,
  IWillCreatorRow,
  IShowDeleteWillCreatorModal,
} from '../../datatypes/WillCreator';
import { deleteWillCreatorData } from '../../redux/slices/willCreatorSlice';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ISortParams } from 'src/datatypes/CommonTypes';
import _startCase from 'lodash/startCase';

interface IProps {
  willCreators: IWillCreatorPayload['willCreators'];
  deleteWillStatusDetail: IWillCreatorPayload['deleteWillCreatorStatus'];
  showDeleteModal: IShowDeleteWillCreatorModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeleteWillCreatorModal>
  >;
  setPagination: React.Dispatch<React.SetStateAction<IWillCreatorPagination>>;
  setSort: React.Dispatch<React.SetStateAction<ISortParams>>;
}

const Listing = ({
  willCreators,
  deleteWillStatusDetail,
  showDeleteModal,
  setShowDeleteModal,
  setPagination,
  setSort,
}: IProps) => {
  const dispatch = useAppDispatch();

  //siwtch for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteWillCreatorModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  // selected will
  const selectedWillStatusId = (rows: IWillCreatorRow[]) => {
    setShowDeleteModal((prev: IShowDeleteWillCreatorModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  // handle delete will
  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((willDetails: IWillCreatorRow) => willDetails.id)
      .join(',');
    dispatch(deleteWillCreatorData(selectedIds));
  };

  // handle delete modal open
  const openDeleteModal = (row: IWillCreatorRow) => {
    selectedWillStatusId([row]);
    toggleDeleteModal();
  };

  const renderCheckIcon = (value: string | undefined) => {
    if (value === undefined) return null; // Return null if value is not set
    return <i className={`fa fa-${value === 'yes' ? 'check' : 'times'}`}></i>;
  };

  //columns for listing
  const columns: IWillCreatorColumn[] = [
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: IWillCreatorRow) => row.actions,
      center: true,
      compact: true,
      width: '8.5rem',
      cell: (row: IWillCreatorRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <Link
            title="View"
            className="btn btn-secondary btn-sm"
            to={`/will-creators/${row?.id}`}
            key="view"
          >
            <i className="fa fa-eye"></i>
          </Link>
          {row?.status === 'in_progress' && (
            <Link
              to={`/will-creators/${row?.id}/preview-answers`}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-address-card-o"></i>
            </Link>
          )}
          <Button
            title="Delete"
            className="btn btn-danger btn-sm"
            key="delete"
            onClick={() => openDeleteModal(row)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
    {
      id: 'user_name',
      name: 'Full Name',
      selector: (row: IWillCreatorRow) => row.first_name,
      sortField: 'first_name',
      sortable: true,
      maxWidth: '44rem',
      cell: (row: IWillCreatorRow) => {
        return _startCase(
          `${row.first_name} ${row.middle_name} ${row.last_name}`
        );
      },
    },
    {
      id: 'details',
      name: 'Contact Details',
      selector: (row: IWillCreatorRow) => row.details,
      sortField: 'email',
      sortable: true,
      maxWidth: '44rem',
      cell: (row: IWillCreatorRow) => {
        return (
          <div>
            {row['email']}
            <br />
            {row['phone']}
          </div>
        );
      },
    },
    {
      id: 'last_answered_page',
      name: 'Last Answered Page',
      selector: (row: IWillCreatorRow) => row.last_answered_page,
      sortField: 'last_answered_page',
      sortable: true,
      cell: (row: IWillCreatorRow) =>
        row['status'] === 'in_progress' ? row['last_answered_page'] : '',
    },
    {
      id: 'assets_value',
      name: 'Assets Valuation',
      selector: (row: IWillCreatorRow) => row.assets_value,
      maxWidth: '8rem',
      sortable: false,
      center: true,
      cell: (row: IWillCreatorRow) => row.assets_value,
    },
    {
      id: 'has_children',
      name: 'Has Children?',
      selector: (row: IWillCreatorRow) => row.has_children,
      maxWidth: '8rem',
      sortable: false,
      center: true,
      cell: (row: IWillCreatorRow) => renderCheckIcon(row.has_children),
    },
    {
      id: 'left_for_charity',
      name: 'Has Left for charity?',
      selector: (row: IWillCreatorRow) => row.want_to_donate,
      maxWidth: '8rem',
      sortable: false,
      center: true,
      cell: (row: IWillCreatorRow) => renderCheckIcon(row.want_to_donate),
    },
    {
      id: 'notify_charity',
      name: 'Notify Charity?',
      selector: (row: IWillCreatorRow) => row.notify_charity,
      maxWidth: '8rem',
      sortable: false,
      center: true,
      cell: (row: IWillCreatorRow) => renderCheckIcon(row.notify_charity),
    },
    {
      id: 'required_funeral_info',
      name: 'Req. Fun Info?',
      selector: (row: IWillCreatorRow) => row.required_funeral_info,
      maxWidth: '8rem',
      sortable: false,
      center: true,
      cell: (row: IWillCreatorRow) => renderCheckIcon(row.notify_charity),
    },
    {
      id: 'status',
      name: 'Will Status',
      selector: (row: IWillCreatorRow) => row.status,
      sortField: 'status',
      sortable: true,
      center: true,
      cell: (row: IWillCreatorRow) => {
        switch (row?.status) {
          case 'finished':
            return <span className="badge badge-success">Completed</span>;
          case 'in_progress':
            if (row.document_file) {
              return <span className="badge badge-info">Updating</span>;
            } else {
              return <span className="badge badge-secondary">In Progress</span>;
            }
          default:
            return <span className="badge badge-warning">Not Started</span>;
        }
      },
    },
    {
      name: 'Last Updated At',
      selector: (row: IWillCreatorRow) => row['updated_at'],
      sortField: 'updated_at',
      sortable: true,
      cell: (row: IWillCreatorRow) => (
        <span className="white-space-pre">
          {moment
            .utc(moment(row['updated_at']))
            .local()
            ?.format('MMM DD, YYYY[\r\n]h:mm a')}
        </span>
      ),
      width: '9.5rem',
      right: true,
    },
  ];

  // handle delete modal close on success delete
  useEffect(() => {
    if (deleteWillStatusDetail?.success) {
      setShowDeleteModal((prev: IShowDeleteWillCreatorModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteWillStatusDetail?.success]);

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={willCreators.loading}
              keyField="id"
              progressComponent={<Dtspinner />}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              paginationServer
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={willCreators.data?.total}
              data={willCreators.data?.data}
              customStyles={dtCustomStyle}
              onChangePage={(page: number) =>
                setPagination((prev: IWillCreatorPagination) => ({
                  ...prev,
                  page: page,
                }))
              }
              onChangeRowsPerPage={(new_per_page: number) =>
                setPagination((prev: IWillCreatorPagination) => ({
                  ...prev,
                  per_page: new_per_page,
                }))
              }
              onSort={(column, direction) => {
                setSort({ orderBy: column.sortField, order: direction });
              }}
              sortServer
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteWillStatusDetail={deleteWillStatusDetail}
        />
      </div>
    </div>
  );
};

export default Listing;
