import useAxiosService from '../services/axios.service';

const useLivingWillFormApi = () => {
  const { getUserHttpClient } = useAxiosService();

  // get living will form list
  const getLivingWillForms = async () => {
    return await getUserHttpClient()
      .get('/living-will-forms')
      .then((response) => {
        return response;
      });
  };

  // get living will form by id
  const getLivingWillFormDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/living-will-forms/' + id)
      .then((response) => {
        return response;
      });
  };

  // save new living will form
  const saveLivingWillForm = async (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return await getUserHttpClient()
      .post('/living-will-forms', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to FormData
        },
      })
      .then((response) => response);
  };

  // update old living will form
  const updateLivingWillForm = async (data: any, id: string) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return await getUserHttpClient()
      .post(`/living-will-forms/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to FormData
        },
      })
      .then((response) => response);
  };

  // delete living will form
  const deleteLivingWillForm = async (id: string) => {
    return await getUserHttpClient()
      .delete(`/living-will-forms/${id}`)
      .then((response) => response);
  };

  return {
    getLivingWillForms,
    getLivingWillFormDetails,
    saveLivingWillForm,
    updateLivingWillForm,
    deleteLivingWillForm,
  };
};

export default useLivingWillFormApi;
