import { startCase } from 'lodash';
import { formattedInputKeys } from 'src/constants/FormattedInputKeys';
import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';

const PetsAnswers = ({ answers }: any) => {
  const petsInformation = answers[pageCodes.petsInfoPage];
  const pets = answers[pageCodes.petsInfoPage][questionCodes.pets];
  const getPetsNames = (pet: any) =>
    `${startCase(pet[questionInputKeys.PET_NAME_DESC])} (${startCase(
      pet[questionInputKeys.PET_TYPE]
    )})`;

  return (
    <div>
      <div>
        <table className="default-table table table-bordered">
          <tbody>
            <tr>
              <th colSpan={4} className="table-title">
                <div>
                  <h4>Pets Information</h4>
                </div>
              </th>
            </tr>
            <tr>
              <th className="text-left w-20">Do you have any pet(s)?</th>
              <td>
                {startCase(
                  petsInformation[questionCodes.hasPets][
                    questionInputKeys.HAS_PETS
                  ]
                )}
              </td>
              {petsInformation[questionCodes.hasPets][
                questionInputKeys.HAS_PETS
              ] === 'yes' && (
                <>
                  <th className="text-left">Pet(s)</th>
                  <td>
                    {pets.length > 0 && pets.map(getPetsNames).join(', ')}
                  </td>
                </>
              )}
            </tr>
            {petsInformation[questionCodes.hasPets][
              questionInputKeys.HAS_PETS
            ] === 'yes' &&
              petsInformation[questionCodes.petsCareTakers] && (
                <>
                  <tr>
                    <th className="align-top">Primary Caretaker</th>
                    <th className="align-top">Alternate Caretaker</th>
                    <th className="align-top">Pet(s)</th>
                    <th className="align-top">Allocated Amount</th>
                  </tr>
                  {petsInformation[questionCodes.petsCareTakers].map(
                    (careTaker: any, index: number) => (
                      <tr key={index} className="mb-3">
                        <td>
                          <span>
                            {startCase(
                              `${
                                careTaker[questionInputKeys.PRIMARY_FIRSTNAME]
                              } ${
                                careTaker[questionInputKeys.PRIMARY_LASTNAME]
                              }`
                            )}{' '}
                            (
                            {
                              formattedInputKeys[
                                careTaker[
                                  questionInputKeys.PRIMARY_RELATIONSHIP
                                ]
                              ]
                            }
                            )
                          </span>
                        </td>
                        <td>
                          {careTaker[questionInputKeys.ALTERNATE_FIRSTNAME] &&
                            careTaker[questionInputKeys.ALTERNATE_LASTNAME] &&
                            careTaker[
                              questionInputKeys.ALTERNATE_RELATIONSHIP
                            ] && (
                              <span>
                                {startCase(
                                  `${
                                    careTaker[
                                      questionInputKeys.ALTERNATE_FIRSTNAME
                                    ]
                                  } ${
                                    careTaker[
                                      questionInputKeys.ALTERNATE_LASTNAME
                                    ]
                                  }`
                                )}{' '}
                                (
                                {
                                  formattedInputKeys[
                                    careTaker[
                                      questionInputKeys.ALTERNATE_RELATIONSHIP
                                    ]
                                  ]
                                }
                                )
                              </span>
                            )}
                        </td>
                        <td>
                          {pets
                            .filter((pet: { id: any }) =>
                              careTaker[questionInputKeys.PETS].includes(pet.id)
                            )
                            .map(getPetsNames)
                            .join(', ')}
                        </td>
                        <td>
                          {careTaker.allocated_amount
                            ? new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(careTaker.allocated_amount)
                            : 'N/A'}
                        </td>
                      </tr>
                    )
                  )}
                </>
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PetsAnswers;
