import { startCase } from 'lodash';
import useAnswerService from '../../../src/services/answer.service';
import { optionKeys } from 'src/constants/OptionKeys';
import { questionInputKeys } from 'src/constants/QuestionInputKeys';
import { pageCodes, questionCodes } from 'src/constants/QuestionnaireCodes';
import { formattedInputKeys } from 'src/constants/FormattedInputKeys';

const ResiduaryPropertyAnswers = ({ answers }: any) => {
  const { getSpousePartner } = useAnswerService();

  const legacyGift = answers[pageCodes.charitiesPage];
  const residuaryProperty = answers[pageCodes.residuaryEstatePage];
  const primaryBeneficiary =
    residuaryProperty &&
    residuaryProperty[questionCodes.residuaryPropertyPB] &&
    residuaryProperty[questionCodes.residuaryPropertyPB][
      questionInputKeys.RESIDUARY_PB
    ]
      ? residuaryProperty[questionCodes.residuaryPropertyPB][
          questionInputKeys.RESIDUARY_PB
        ]
      : '';
  const spousePartner = getSpousePartner(answers);
  let totalDistribution = 0;
  let beneficiaryList: any = [];
  if (
    legacyGift[questionCodes.wantToDonateInCharities][
      questionInputKeys.WANT_TO_DONATE
    ] === 'yes'
  ) {
    legacyGift[questionCodes.charityPortion].forEach((charity: any) => {
      if (charity.unit === '%') {
        totalDistribution += Number(charity.portion);
      }
      beneficiaryList.push({
        type: optionKeys.CHARITY,
        portion: Number(charity.portion),
        unit: charity.unit,
        name: charity.charity_name,
        isPrimary: true,
      });
    });
  }
  const generateCharityCustomBeneficiaries = (beneficiaries: any) => {
    return beneficiaries.map((beneficiary: any) => ({
      type: beneficiary.beneficiary_type,
      portion: beneficiary.portion,
      ...(beneficiary.beneficiary_type === optionKeys.CHARITY
        ? { name: beneficiary.charity_name }
        : {
            name: startCase(
              `${beneficiary.person_firstname} ${beneficiary.person_lastname}`
            ),
            relationship: formattedInputKeys[beneficiary.relationship],
          }),
    }));
  };

  const getSecondaryBeneficiaries = (beneficiaries: any) => {
    return beneficiaries.map((beneficiary: any) => {
      const secondaryBeneficiary =
        residuaryProperty[
          questionCodes
            .residuaryPropertyTBWhenPBIsCustomPlanAndSBIsAlternateBeneficiary
        ] &&
        residuaryProperty[
          questionCodes
            .residuaryPropertyTBWhenPBIsCustomPlanAndSBIsAlternateBeneficiary
        ][beneficiary.id] &&
        residuaryProperty[
          questionCodes
            .residuaryPropertyTBWhenPBIsCustomPlanAndSBIsAlternateBeneficiary
        ][beneficiary.id][
          questionInputKeys.RESIDUARY_WHEN_PB_CUSTOM_SB_ALTERNATE
        ]
          ? residuaryProperty[
              questionCodes
                .residuaryPropertyTBWhenPBIsCustomPlanAndSBIsAlternateBeneficiary
            ][beneficiary.id][
              questionInputKeys.RESIDUARY_WHEN_PB_CUSTOM_SB_ALTERNATE
            ]
          : undefined;
      const secondaryBeneficiaries =
        optionKeys.ALTERNATE === secondaryBeneficiary
          ? generateCharityCustomBeneficiaries(
              residuaryProperty[
                questionCodes
                  .residuaryPropertyTBWhenPBIsCustomPlanAndSBIsAlternateBeneficiaryTBIsCustomPlan
              ][beneficiary.id]
            )
          : optionKeys.CHARITY === secondaryBeneficiary
          ? generateCharityCustomBeneficiaries(
              residuaryProperty[
                questionCodes
                  .residuaryPropertyTBWhenSBCharityAndPBIsChildrenOrSpouseChildrenOrPartnerChildren
              ][beneficiary.id]
            )
          : {
              type: secondaryBeneficiary,
              portion: 100,
              name: '',
            };
      return {
        type: beneficiary.beneficiary_type,
        portion: beneficiary.portion,
        isSecondary: true,
        secondaryBeneficiaries,
        ...(beneficiary.beneficiary_type === optionKeys.CHARITY
          ? { name: beneficiary.charity_name }
          : {
              name: startCase(
                `${beneficiary.person_firstname} ${beneficiary.person_lastname}`
              ),
              relationship: formattedInputKeys[beneficiary.relationship],
            }),
      };
    });
  };

  if (primaryBeneficiary === optionKeys.SPOUSE) {
    let secondaryBeneficiaries: any = null;
    let tertiaryBeneficiaries: any = [];
    const secondoryOption =
      residuaryProperty[questionCodes.residuaryPropertySBWhenPBIsSpouse] &&
      residuaryProperty[questionCodes.residuaryPropertySBWhenPBIsSpouse][
        questionInputKeys.RESIDUARY_WHEN_PB_SPOUSE
      ]
        ? residuaryProperty[questionCodes.residuaryPropertySBWhenPBIsSpouse][
            questionInputKeys.RESIDUARY_WHEN_PB_SPOUSE
          ]
        : '';
    if (secondoryOption) {
      if (secondoryOption === optionKeys.CHILDREN) {
        if (
          !residuaryProperty[
            questionCodes.residuaryPropertyTBWhenPBIsSpouseAndSBIsChildren
          ]
        ) {
          tertiaryBeneficiaries = [];
        } else {
          tertiaryBeneficiaries = generateCharityCustomBeneficiaries(
            residuaryProperty[
              questionCodes.residuaryPropertyTBWhenPBIsSpouseAndSBIsChildren
            ] &&
              residuaryProperty[
                questionCodes.residuaryPropertyTBWhenPBIsSpouseAndSBIsChildren
              ][questionInputKeys.RESIDUARY_WHEN_PB_SPOUSE_SB_CHILDREN] ===
                optionKeys.CUSTOMIZE_PLAN
              ? residuaryProperty[
                  questionCodes
                    .residuaryPropertyQBWhenPBIsSpouseAndSBIsChildrenAndTBIsCustomPlan
                ]
              : residuaryProperty[
                  questionCodes
                    .residuaryPropertyQBWhenPBIsSpouseAndSBIsChildrenAndTBIsCharity
                ]
          );
        }
        secondaryBeneficiaries = {
          type: optionKeys.CHILDREN,
          portion: 100,
          name: '',
          tertiaryBeneficiaries,
        };
      } else {
        secondaryBeneficiaries = generateCharityCustomBeneficiaries(
          secondoryOption === optionKeys.CHARITY
            ? residuaryProperty[
                questionCodes.residuaryPropertyTBWhenPBIsSpouseAndSBIsCharity
              ]
            : residuaryProperty[
                questionCodes.residuaryPropertyTBWhenPBIsSpouseAndSBIsCustomPlan
              ]
        );
      }
    }
    beneficiaryList.push({
      type: optionKeys.SPOUSE,
      portion: 100 - Number(totalDistribution),
      name: spousePartner?.name,
      isSecondary: true,
      relationship: 'Spouse',
      secondaryBeneficiaries,
    });
  } else if (primaryBeneficiary === optionKeys.PARTNER) {
    let secondaryBeneficiaries: any = null;
    let tertiaryBeneficiaries: any = [];
    const secondoryOption =
      residuaryProperty[questionCodes.residuaryPropertySBWhenPBIsPartner] &&
      residuaryProperty[questionCodes.residuaryPropertySBWhenPBIsPartner][
        questionInputKeys.RESIDUARY_WHEN_PB_PARTNER
      ]
        ? residuaryProperty[questionCodes.residuaryPropertySBWhenPBIsPartner][
            questionInputKeys.RESIDUARY_WHEN_PB_PARTNER
          ]
        : '';
    if (secondoryOption && secondoryOption === optionKeys.CHILDREN) {
      if (
        !residuaryProperty[
          questionCodes.residuaryPropertyTBWhenPBIsPartnerAndSBIsChildren
        ]
      ) {
        tertiaryBeneficiaries = [];
      } else {
        tertiaryBeneficiaries = generateCharityCustomBeneficiaries(
          residuaryProperty[
            questionCodes.residuaryPropertyTBWhenPBIsPartnerAndSBIsChildren
          ][questionInputKeys.RESIDUARY_WHEN_PB_PARTNER_SB_CHILDREN] ===
            optionKeys.CUSTOMIZE_PLAN
            ? residuaryProperty[
                questionCodes
                  .residuaryPropertyQBWhenPBIsPartnerAndSBIsChildrenAndTBIsCustomPlan
              ]
            : residuaryProperty[
                questionCodes
                  .residuaryPropertyQBWhenPBIsPartnerAndSBIsChildrenAndTBIsCharity
              ]
        );
      }
      secondaryBeneficiaries = {
        type: optionKeys.CHILDREN,
        portion: 100,
        name: '',
        tertiaryBeneficiaries,
      };
    } else if (secondoryOption) {
      secondaryBeneficiaries = generateCharityCustomBeneficiaries(
        secondoryOption === optionKeys.CHARITY
          ? residuaryProperty[
              questionCodes.residuaryPropertyTBWhenPBIsPartnerAndSBIsCharity
            ]
          : residuaryProperty[
              questionCodes.residuaryPropertyTBWhenPBIsPartnerAndSBIsCustomPlan
            ]
      );
    }
    beneficiaryList.push({
      type: optionKeys.PARTNER,
      portion: 100 - Number(totalDistribution),
      name: spousePartner?.name,
      isSecondary: true,
      secondaryBeneficiaries,
    });
  } else if (
    [
      optionKeys.CHILDREN,
      optionKeys.SPOUSE_CHILDREN,
      optionKeys.PARTNER_CHILDREN,
      optionKeys.CUSTOMIZE_PLAN,
    ].includes(primaryBeneficiary)
  ) {
    const beneficiaries =
      primaryBeneficiary === optionKeys.CUSTOMIZE_PLAN
        ? residuaryProperty[questionCodes.residuaryPropertySBWhenPBIsCustomPlan]
        : residuaryProperty[
            questionCodes
              .residuaryPropertySBWhenPBIsChildrenOrSpouseChildrenOrPartnerChildren
          ];
    if (primaryBeneficiary === optionKeys.CHILDREN) {
      const childrenNames = beneficiaries.map((child: any) =>
        startCase(`${child.person_firstname} ${child.person_lastname}`)
      );
      beneficiaryList.push({
        type: 'Children',
        portion: 100 - Number(totalDistribution),
        name:
          childrenNames.length === 1
            ? ` my child, ${childrenNames[0]}`
            : ` my children equally`,
        beneficiaries: getSecondaryBeneficiaries(beneficiaries),
      });
    } else if (primaryBeneficiary === optionKeys.SPOUSE_CHILDREN) {
      const spouseName = beneficiaries
        .filter((beneficiary: any) => beneficiary.relationship === 'spouse')
        .map((spouse: any) =>
          startCase(`${spouse.person_firstname} ${spouse.person_lastname}`)
        );
      const childrenNames = beneficiaries
        .filter((beneficiary: any) => beneficiary.relationship === 'child')
        .map((child: any) =>
          startCase(`${child.person_firstname} ${child.person_lastname}`)
        );
      beneficiaryList.push({
        type: 'Spouse-Children',
        portion: 100 - Number(totalDistribution),
        name: ` my spouse, ${
          spouseName[0]
        } and my children, ${childrenNames.join(', ')} equally`,
        beneficiaries: getSecondaryBeneficiaries(beneficiaries),
      });
    } else if (primaryBeneficiary === optionKeys.PARTNER_CHILDREN) {
      const partnerName = beneficiaries
        .filter((beneficiary: any) => beneficiary.relationship === 'partner')
        .map((spouse: any) =>
          startCase(`${spouse.person_firstname} ${spouse.person_lastname}`)
        );
      const childrenNames = beneficiaries
        .filter((beneficiary: any) => beneficiary.relationship === 'child')
        .map((child: any) =>
          startCase(`${child.person_firstname} ${child.person_lastname}`)
        );

      beneficiaryList.push({
        type: 'Partner-Children',
        portion: 100 - Number(totalDistribution),
        name: ` my partner, ${
          partnerName[0]
        } and my children, ${childrenNames.join(', ')} equally`,
        beneficiaries: getSecondaryBeneficiaries(beneficiaries),
      });
    } else {
      beneficiaryList = [
        ...beneficiaryList,
        ...getSecondaryBeneficiaries(beneficiaries),
      ];
    }
  }

  const secondaryBeneficiaries = beneficiaryList
    ?.filter((item: any) => item.isSecondary)
    ?.flatMap((item: any) => item.secondaryBeneficiaries || []);
  return (
    <div>
      <div>
        <table className="default-table table table-bordered">
          <tbody>
            <tr>
              <th colSpan={3} className="table-title">
                <div>
                  <h4>Residuary Property Distribution</h4>
                </div>
              </th>
            </tr>
            {beneficiaryList?.length !== 0 && (
              <>
                <tr>
                  <th>Beneficiary Type</th>
                  <th>Beneficiary</th>
                  <th>Distribution</th>
                </tr>
                {beneficiaryList.map(
                  (beneficiary: any, key: number | string) => {
                    return (
                      <tr key={key}>
                        <td>{startCase(beneficiary.type)}</td>
                        <td>
                          {beneficiary.name}{' '}
                          {beneficiary.relationship &&
                            `(${beneficiary.relationship})`}
                        </td>
                        <td>
                          {' '}
                          {beneficiary.unit === '$'
                            ? `${new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(beneficiary.portion)}`
                            : `${Number(beneficiary.portion).toFixed(2)}%`}
                        </td>
                      </tr>
                    );
                  }
                )}
              </>
            )}
            {secondaryBeneficiaries?.length !== 0 && (
              <>
                <tr>
                  <th>Secondary Beneficiary Type</th>
                  <th>Secondary Beneficiary</th>
                  <th>Distribution</th>
                </tr>
                {secondaryBeneficiaries.map(
                  (secondaryBeneficiary: any, key: number | string) => {
                    return (
                      <tr key={key}>
                        <td>{startCase(secondaryBeneficiary.type)}</td>
                        <td>
                          {secondaryBeneficiary.name}{' '}
                          {secondaryBeneficiary.relationship &&
                            `(${secondaryBeneficiary.relationship})`}
                        </td>
                        <td>
                          {' '}
                          {secondaryBeneficiary.unit === '$'
                            ? `${new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(secondaryBeneficiary.portion)}`
                            : `${Number(secondaryBeneficiary.portion).toFixed(
                                2
                              )}%`}
                        </td>
                      </tr>
                    );
                  }
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ResiduaryPropertyAnswers;
