export const formattedInputKeys: any = {
  marital_status: 'Marital status',
  hasChildren: 'Response',
  hasFutureChildren: 'Response',
  excludeChildren: 'Response',
  excludedChildren: 'child',
  excludeBeneficiary: 'Response',
  hasPets: 'Response',
  assetsValue: 'Response',
  firstname: 'First name',
  first_name: 'First name',
  middle_name: 'Middle name',
  middlename: 'Middle name',
  last_name: 'Last name',
  lastname: 'Last name',
  designatedDiedFirst: 'Response',
  childrenNames: 'Children',
  gift: 'Specific bequest gift(s)',
  probate_asset_type: 'Non-probate asset type',
  account_no: 'Policy/account #',
  amount: 'Approx. amount',
  provider: 'Institution name/Property owner name',
  petType: 'Pet type',
  petNameDesc: 'Pet name/description',
  otherGender: 'Other gender',
  hasFinancialGuardian: 'Response',
  hasNonProbateAssets: 'Response',
  wantToGiveExecutorCompensation: 'Response',
  shouldFinancialGuardianReceiveCompensation: 'Response',
  primary_firstname: 'First name',
  primary_lastname: 'Last name',
  primary_relationship: 'Relationship',
  alternate_firstname: 'First name',
  alternate_lastname: 'Last name',
  alternate_relationship: 'Relationship',
  person_firstname: "Person's First name",
  person_lastname: "Person's Last name",
  needInsuranceInfo: 'Response',
  allocateDigitalAssets: 'Response',
  asset_name: 'Asset name or type',
  hasSpecificBequests: 'Response',
  informCharity: 'Response',
  needMoreCharityInfo: 'Response',
  hasFuneralPlan: 'Response',
  residuaryEstate00: 'Response',
  residuaryEstate10: 'Response',
  residuaryEstate11: 'Response',
  residuaryEstate30: 'Response',
  residuaryEstate41: 'Response',
  cardholder_name: 'Card holder name',
  card_number: 'Card number',
  expiry_date: 'Expiry date',
  subscribe_date: 'Subscribe date',
  cvv: 'CVV',
  email: 'Email address',
  phone_number: 'Phone number',
  address: 'Address',
  address_1: 'Street address',
  address1: 'Street address',
  address2: 'Apt./Unit/Suite number',
  city: 'City',
  zipcode: 'Zipcode',
  state: 'State',
  country: 'Country',
  county: 'County',
  birthdate: 'Birth date',
  deathdate: 'Death date',
  birth_year: 'Birth year',
  death_year: 'Death year',
  pinInfo: 'Pin info',
  pin: 'Pin',
  searching_pin: 'Searching PIN',
  child: 'Child',
  stepchild: 'Step child',
  grandchild: 'Grand child',
  parent: 'Parent',
  stepparent: 'Step parent',
  grandparent: 'Grand parent',
  friend: 'Friend',
  sibling: 'Sibling',
  relative: 'Relative',
  cousin: 'Cousin',
  other: 'Other',
  contentProvision: 'Content provision',
  survivalRequirement: 'Survival requirement',
  spouse: 'Spouse',
  partner: 'Partner',
  'jointly-owned-property': 'Jointly Owned Property',
  'joint-bank-account': 'Joint Bank Account',
  'retirement-account': 'Retirement Account',
  'life-insurance-policy': 'Life Insurance Policy',
  'tod-account': 'Transfer-on-Death (TOD) Account',
  'pod-account': 'Payable-on-Death (POD) Account',
  'revocable-living-trust': 'Revocable Living Trust',
  annuity: 'Annuity',
  'brokerage-account':
    'Brokerage Accounts with Transfer-on-Death (TOD) Designation',
  'hsa-account': 'Health Savings Accounts (HSAs) with Beneficiary Designation',
  '529-college-saving-plan':
    '529 College Savings Plans with Beneficiary Designation',
  obituary: 'Obituary details',
  funeral_home: 'Funeral home',
  funeral_phone: 'Phone number',
  funeral_date: 'Funeral date',
  funeral_time: 'Funeral time',
  funeral_address1: 'Street address',
  funeral_address2: 'Apt./Unit/Suite number',
  funeral_city: 'City',
  funeral_zipcode: 'Zipcode',
  funeral_state_id: 'State',
  funeral_county_id: 'County',
  old_password: 'Old password',
  home: 'Home',
  attorney: 'Attorney',
  private: 'Private party',
  business: 'Financial institution',
  original: 'Original Will',
  duplicate: 'Duplicate Will',
  iCloud: 'iCloud',
  full_name: 'Full name',
  organization_name: 'Organization name',
  organization_site: 'Organization website',
  organization: 'Organization name',
  organization_website: 'Organization website',
  business_email: 'Business email',
  document_type: 'Document type',
  witness1_first_name: 'First name',
  witness1_last_name: 'Last name',
  witness1_document_type: 'Document type',
  witness2_first_name: 'First name',
  witness2_last_name: 'Last name',
  contact_name: 'Contact person name',
  contact_email: 'Contact person email address',
  special_instructions: 'Special instructions',
  ein_number: 'EIN number',
};
