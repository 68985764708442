import { OptionType } from '../datatypes/CommonTypes';
import {
  IQuestionDependency,
  IQuestionDetails,
  IQuestionValidations,
} from '../datatypes/Question';

// initial state for form and error - dependency
export const templateQuestionDependencyDetails: IQuestionDependency = {
  parent_id: null,
  answer: '',
};

// initial state for form - question
export const templateQuestionDetails: IQuestionDetails = {
  question_code: '',
  page_id: '',
  question_text: '',
  description: '',
  explanation: '',
  inputs: '',
  status: 'active',
  dependencyQuestions: [],
};

// initial state for error - question
export const templateErrorQuestionDetails: IQuestionDetails = {
  ...templateQuestionDetails,
  status: '',
};

// validations for question - dependency
export const questionValidations: IQuestionValidations = {
  parent_id: 'required',
  answer: 'required',
  question_code: 'required',
  page_id: 'required',
  question_text: 'required',
  description: '',
  explanation: '',
  inputs: 'required|json',
  status: 'required',
};

export const pageStatusOptions: OptionType<string>[] = [
  {
    label: 'Active',
    value: 'active',
    disabled: false,
    color: 'btn-success',
  },
  {
    label: 'Inactive',
    value: 'inactive',
    disabled: false,
    color: 'btn-secondary',
  },
];
